import {
  addItemQuantity,
  removeItemFromBag,
  removeItemQuantity
} from '../../redux/data/bag/BagActions'
import {
  DecreaseBagItemQuantity,
  DeleteBagItem,
  IncreaseBagItemQuantity
} from '../requests/BagRequests'

export async function removeProductFromBag(productId, dispatch) {
  const {success} = await DeleteBagItem(productId)

  if (success) {
    dispatch(removeItemFromBag(productId))
  }
}

export async function decreaseQuantity(bagItemId, dispatch, languageId) {
  const {success} = await DecreaseBagItemQuantity(bagItemId, languageId)

  if (success) {
    dispatch(removeItemQuantity(bagItemId))
  }
}

export async function increaseQuantity(bagItemId, dispatch, languageId) {
  const {success} = await IncreaseBagItemQuantity(bagItemId, languageId)

  if (success) {
    dispatch(addItemQuantity(bagItemId))
  }
}
