import {createAction} from '@reduxjs/toolkit'

export const updateInfo = createAction('info/ready')

export const setBackendVersion = createAction(
  'backend/setVersion',
  (version) => ({
    payload: version
  })
)
