import styled from 'styled-components'

import {
  PrimaryTextColor,
  PrimaryColor,
  PageBackground
} from '../../styles/_colors'
import {device} from '../../styles/_responsive'
import {
  PanelTopSubTitle,
  BodyText,
  Assistant300,
  AddressSubTitle,
  Assistant600
} from '../../styles/_texts'

export const Container = styled.section`
  background-color: ${PrimaryColor};
`

export const TextHeader = styled.section`
  padding: 80px 0;
`

export const ProductCharacteristics = styled.div`
  text-align: center;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;

  @media ${device.tablet} {
    padding: 0 20px;
  }
`

export const Wrapper = styled.div`
  max-width: 500px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;

  @media ${device.tablet} {
    padding: 0 20px;
  }
`

export const Subtitle = styled.p`
  font-family: ${Assistant300};
  font-style: italic;
  font-size: ${BodyText};
  letter-spacing: 1px;
  margin-top: 20px;
  font-stretch: normal;
`

export const SkyHealthImage = styled.img`
  width: 100%;
  border-width: 0px;
  vertical-align: middle;
`

export const CharacteristicsList = styled.ul`
  font-size: 0;
  list-style-type: none;
`

export const Characteristic = styled.li`
  text-align: left;

  display: inline-block;
  max-width: 33.333%;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 40px;
  vertical-align: top;
`

export const CharFigure = styled.figure`
  text-align: left;
  margin: 0 0 1rem;
`

export const CharTitle = styled.p`
  font-family: ${Assistant600};
  letter-spacing: 1px;
  color: ${PrimaryTextColor};
  text-transform: uppercase;
  text-align: left;
  font-size: ${AddressSubTitle};
  letter-spacing: normal;
  line-height: normal;
  display: block;
  margin-top: 30px;
`

export const CharText = styled(CharTitle)`
  font-family: 'Assistant';
  font-weight: normal;
  font-size: ${PanelTopSubTitle};
  letter-spacing: 0px;
  text-transform: none;
  color: ${PageBackground};
  margin-top: 10px;
`

export const CharImg = styled.img`
  max-width: 75px;
`
