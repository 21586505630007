import React from 'react'

const PrivacyPolicyData = (
  <table>
    <tbody>
      <tr>
        <td>
          <p>
            I authorize the processing of the personal data collected in
            this registration process or data integration with our
            respective business partner, namely the identification data
            that enable the management and delivery of the uniforms (name,
            taxpayer number, mobile phone number, e-mail, uniform delivery
            address, contract term, garment size), for the purpose of
            effecting the registration request on the mySkypro portal and
            thus enable the relationship with SKYPRO.{' '}
          </p>
          <br />
          <b>Last Update:</b> April 21st, 2021
          <h4>Scope of application</h4>
          <p>
            This Privacy Policy regulates the processing of personal data
            that is carried out on the website {0}.
          </p>
          <h4>1. ABOTOA’S (SKYPRO) COMMITMENT </h4>
          <p>
            ABOTOA, S.A. (hereinafter SKYPRO), in the context of its
            duties, processes personal data, ensuring with accuracy,
            efficiency and security the protection of all the data it
            collects and processes on a daily basis.
          </p>
          <p>
            Please read this Privacy Policy, which describes how we collect
            and process your personal data and how you can exercise your
            rights as a holder of personal data collected and processed by
            SKYPRO.
          </p>
          <p>
            The user guarantees to be of legal age and that the data
            communicated is real, accurate, complete and current, being
            responsible for any damage or loss, direct or indirect, that
            may arise as a result of the breach of said obligation.
          </p>
          <h4>
            2. PERSONAL DATA, PERSONAL DATA SUBJECTS AND CATEGORIES OF
            PERSONAL DATA
          </h4>
          <h4>What is personal data?</h4>
          <p>
            Personal data is all information of any nature, collected in
            any type of support, including sound and image, concerning an
            identified or identifiable natural person. Identifiable
            information is considered to be the set of information that can
            lead to the identification of a certain person, namely by
            reference to an identification number or to one or more
            specific elements of his/her physical, physiological, psychic,
            economic, cultural or social identity (such as, for example, an
            identification number, clothing size or location data).
          </p>
          <h4>Responsible for treatment</h4>
          <p>
            The entity responsible for the collection and treatment of the
            users' personal data, namely the categories of data collected,
            their treatment and the purposes for which they are used, is
            Abotoa, S.A., a private limited company by shares registered
            under number 506801144, with its head office at Rua Maestro
            António Pinto Godinho, 179, 3720-360 Vila de Cucujães and
            email: gdpr@wearskypro.com.
          </p>
          <h4>On what basis do we use your personal data?</h4>
          <p>
            All data collected and processed by SKYPRO is based on one of
            the following conditions of lawfulness:
          </p>
          <ul>
            <li>
              Consent: when the collection is preceded by your express,
              specific and informed consent, in writing or via web.
            </li>
            <li>
              The performance of a contract or pre-contractual steps: when
              the processing is necessary for the performance of a contract
              to which you are a party or for pre-contractual steps.
            </li>
            <li>
              The performance of legal obligations: when the processing is
              necessary for the performance of a legal obligation.
            </li>
          </ul>
          <h4>What are the purposes for which we collect your data?</h4>
          <p>
            The personal data collected by SKYPRO is only processed for
            specific, explicit and legitimate purposes. Whenever personal
            data is collected, it is exclusively for the purposes expressly
            identified at the time of collection. We list here the main
            purposes for which SKYPRO collects personal data:
          </p>
          <ul>
            <li>Registration in the mySkypro portal</li>
            <li>Registration in the restricted area </li>
            <li>Processing and delivery of clients’ user uniforms</li>
            <li>Logistics management of clients’ user uniforms</li>
            <li>Contracting of supply and service contracts</li>
            <li>Subscription to newsletters/publications</li>
            <li>Responses to contact requests</li>
          </ul>
          <h4>
            What personal data do we process and how do we collect it?{' '}
          </h4>
          <p>
            SKYPRO only collects data that is adequate, relevant and
            limited to what is necessary in relation to the purposes for
            which they are processed.
          </p>
          <p>
            The collection of your data can be done in writing (notably
            through forms and contracts), from the mySkypro portal, through
            the website or through integration with the ERP systems of
            SKYPRO clients.{' '}
          </p>
          <br />
          <p>
            The user guarantees that the personal data provided to SKYPRO
            are true and becomes responsible for communicating any changes
            in them.
          </p>
          <br />
          <p>
            Access to the website and navigation: with access to the
            website, a set of data is automatically collected from the
            computer, which are temporarily recorded in its own files, and
            are also automatically deleted after a certain period. The
            collection of this data has purely technical objectives, such
            as connection setup, system security, technical network
            administration and website optimization. The personal data
            collected for this purpose are the following:{' '}
          </p>
          <br />
          <p>a) IP address of the requesting processor;</p>
          <p>b) Date and time of access;</p>
          <p>c) Name and URL of the downloaded file;</p>
          <p>d) Volume of data transmitted;</p>
          <p>e) Indication of whether the transfer was successful;</p>
          <p>
            f) Data that identifies the browser software and the operating
            system;
          </p>
          <p>g) Website from which you accessed our website;</p>
          <p>h) Name of the Internet service provider.</p>
          <br />
          <p>
            To carry out the different purposes, we may collect the
            following types of personal data:
          </p>
          <br />
          <p>Customer Management:</p>
          <br />
          <p>
            For registration on the mySkypro portal and subsequent
            processing and information of the status of orders, inquiries
            and possible complaints, the user is asked for the following
            personal data:
          </p>
          <ul>
            <li>
              Identification data (such as name, place of birth,
              identification number or date of birth)
            </li>
            <li>
              Ergonomic data of the uniform users, such as their clothing
              size
            </li>
            <li>
              Data that associates the mySkypro portal user to the legal
              person/Client
            </li>
            <li>
              Contact data (such as mobile phone number, address or e-mail)
            </li>
            <li>
              Data relating to the beginning and term of the employment
              contract or service provision or the temporary or definitive
              nature of the employee’s contract{' '}
            </li>
            <li>
              Banking, financial and transaction data (such as the IBAN or
              taxpayer number)
            </li>
          </ul>
          <p>
            The provision of data is mandatory, without which it is not
            possible to proceed with the conclusion and execution of the
            purchase and sales contract and the distribution of the uniform
            to the user or service provider, and is also necessary for
            processing and reporting the status of orders and communication
            with Customers and for processing requests for information.
          </p>
          <br />
          <p>Contact requests:</p>
          <br />
          <p>
            If you want to contact SKYPRO directly through the website, you
            shall complete the form provided for that purpose, which can be
            found on the menu “contact us”. Some personal information of
            yours will be requested, such as name and email address,
            location and mobile phone number. The collection of this data
            is only intended to make it possible to respond to your contact
            and provide you with the information you need.{' '}
          </p>
          <br />
          <p>Newsletter subscription:</p>
          <br />
          <p>
            SKYPRO provides the subscription of a free newsletters service
            to the users of its website. The purpose of this service is to
            bring to the attention of its subscribers relevant information
            within the areas of interest covered by the services provided
            by SKYPRO. To use this service you will necessarily have to
            provide your email address. You can cancel your subscription to
            the newsletters service at any time, simply by following the
            indications present at the end of each newsletter.
          </p>
          <br />
          <p>
            The legal basis applicable to the collection and use of your
            personal data for the purposes indicated above is your consent.
          </p>
          <h4>3. PERIOD OF CONSERVATION OF PERSONAL DATA </h4>
          <p>
            SKYPRO processes and keeps your data only for the period that
            is necessary to pursue or complete the purposes of the
            processing for which they are intended, in respect of the
            maximum periods necessary to comply with contractual, legal or
            regulatory obligations.{' '}
          </p>
          <p>
            In the absence of a specific legal requirement, the user's
            personal data will be stored as from the collection of his/her
            consent and if, within this period, he/she has not withdrawn
            his/her consent.
          </p>
          <p>
            As a general rule, and where a contract exists that legitimizes
            the processing of your data, SKYPRO will keep such data for as
            long as such contractual relationship continues. Other
            circumstances exist, such as compliance with legal or
            regulatory obligations (for example, for the purposes of
            compliance with tax obligations, personal data relating to
            invoices must be kept for a maximum period of ten years from
            the act), as well as pending legal proceedings, which may
            legitimize that your data be kept for a longer period of time.
          </p>
          <p>
            Upon expiration of the retention period, SKYPRO shall proceed
            to delete such data.
          </p>
          <h4>4. RIGHTS OF DATA SUBJECTS </h4>
          <p>
            Under current legislation, from the moment we collect and
            process your data, there is a set of rights that you can
            exercise with SKYPRO at any time.
          </p>
          <h4>What are your rights?</h4>
          <p>
            Right of access: right allowing you to obtain information
            regarding the processing of your data and its characteristics
            (including the type of data, the purpose of processing, to whom
            your data may be disclosed, retention periods and which data
            you must provide compulsorily or optionally).
          </p>
          <br />
          <p>
            Right of rectification: right allowing you to request the
            rectification of your data, requiring it to be accurate and up
            to date, for example, when you consider that it is incomplete
            or out of date.
          </p>
          <br />
          <p>
            Right to erasure of data or "right to be forgotten": right
            allowing you to request the erasure of your data when you
            consider that there are no valid grounds for keeping the data
            and provided that there is no other valid ground that justifies
            such processing (such as the performance of a contract or
            compliance with a legal or regulatory obligation).{' '}
          </p>
          <br />
          <p>
            Right to limitation: right allowing you to suspend processing
            or to limit processing to certain categories of data or
            purposes.
          </p>
          <br />
          <p>
            Right to portability: right by means of which you may request
            that your data be sent in a digital format and in a commonly
            used format that allows the re-use of such data. Alternatively,
            you may request that your data be transmitted to another entity
            that becomes responsible for the processing of your data.
          </p>
          <br />
          <p>
            Right to object: right that allows you to object to certain
            purposes and as long as there are no legitimate interests
            overriding your interests. An example of this right concerns
            the opposition to direct marketing purposes.
          </p>
          <br />
          <p>
            Right to withdraw consent: if consent is legally required for
            the processing of personal data, the data subject has the right
            to withdraw his/her consent at any time, although this right
            shall not affect the lawfulness of the processing carried out
            on the basis of the consent previously given nor the subsequent
            processing of the same data on another legal basis, such as the
            performance of the contract or the legal obligation to which
            SKYPRO is subject.{' '}
          </p>
          <h4>How can you exercise your rights?</h4>
          <p>
            In accordance with the legal and regulatory requirements
            regarding data protection, SKYPRO ensures that the owners of
            personal data can exercise their rights regarding the way their
            personal data is processed and stored.
          </p>
          <p>
            All the rights described above can be exercised, with the
            limitations provided for by applicable legislation, by means of
            a written request to be submitted using our form provided for
            this purpose, which can be found in the "Account settings"
            menu, or by contacting SKYPRO directly using the email and
            postal addresses identified above.
          </p>
          <p>
            You may also file any complaint to the supervisory authority.{' '}
          </p>
          <p>
            Currently, the authority with control functions for the
            purposes of data protection in the Portuguese territory is the
            National Commission for Data Protection ("CNPD"), to whom you
            may submit any questions or complaints in the event of
            non-compliance with the rules on the protection of personal
            data.
          </p>
          <br />
          <p>The CNPD may be contacted at the following address</p>
          <br />
          <p>Av. D. Carlos I, 134 - 1.º, 1200-651 Lisboa, Portugal</p>
          <p>Tel: +351 213928400 / Fax: +351 213976832</p>
          <p>Email: geral@cnpd.pt</p>
          <h4>5. TRANSMISSION OF DATA </h4>
          <p>With whom do we share your personal data?</p>
          <p>
            Unless otherwise specified, your personal data will not be
            disclosed to third parties without your explicit consent.{' '}
          </p>
          <br />
          <p>
            Your personal data may, however, be disclosed, taking into
            account SKYPRO's attributions and depending on the purpose, to
            the following entities:
          </p>
          <ul>
            <li>
              Professional advisors (including, but not limited to, legal
              and financial advisors), insurance companies, banks and
              auditors;
            </li>
            <li>
              Providers of certain services that assist SKYPRO in carrying
              out its business, such as logistics management services and
              transport of goods and merchandise, apparel and tailor-made
              uniform manufacturing services, IT and systems administration
              services, hosting and cloud storage services, and other
              software used to meet the needs and management of the data
              within the scope of its business, particularly with regard to
              information security and archiving services.
            </li>
          </ul>
          <br />
          <p>
            In addition, your personal data may be disclosed to third
            parties to:
          </p>
          <ul>
            <li>
              Satisfy requirements imposed by law or by competent
              governmental or judicial authorities;{' '}
            </li>
            <li>
              Satisfy requirements necessary to file or support legal
              proceedings or to defend against legal claims;{' '}
            </li>
            <li>
              Your personal data may also be communicated to the police or
              judicial authorities, in accordance with applicable laws and
              regulations and upon a formal request by the authorities, for
              the purpose of preventing fraud against us (anti-fraud
              services).
            </li>
          </ul>
          <p>
            SKYPRO ensures that it only uses service providers that can
            guarantee the execution of technical and organizational
            measures that are necessary and adequate to protect your
            personal data.
          </p>
          <br />
          <p>Transfers of personal data outside the EEA:</p>
          <br />
          <p>
            SKYPRO may, exceptionally, transfer your personal data to third
            countries (outside the EEA - European Economic Area).{' '}
          </p>
          <p>
            In such cases SKYPRO shall ensure that the data transfers are
            carried out in strict compliance with applicable legal
            standards, in particular determining the suitability of such
            country with respect to the protection of personal data and the
            requirements applicable to such transfers.
          </p>
          <h4>6. STORAGE OF YOUR PERSONAL DATA</h4>
          <p>
            The personal data collected and processed by SKYPRO are stored
            on servers with limited access, located in controlled
            facilities.{' '}
          </p>
          <h4>7. SECURITY MEASURES</h4>
          <p>
            To prevent unauthorized disclosure or access and to ensure the
            appropriate use of users' personal data, SKYPRO uses
            appropriate and reasonable physical, technical and
            administrative procedures to safeguard the information it
            collects and processes, in order to protect personal data
            against unauthorized disclosure, loss, misuse, alteration,
            processing or access as well as against any other form of
            unlawful processing. To this end, in addition to the use of
            HTTP protocol over an additional layer of security using the
            SSL/TLS protocol (HTTPS), its system encrypts information to
            ensure that they are not subject to interception when
            transmitted over the network and has implemented authentication
            systems / access control (login and password) and use of
            firewalls.
          </p>
          <h4>8. COOKIES</h4>
          <p>
            This website uses cookies to improve its service. Some cookies
            are essential to ensure the functionality provided, while
            others are intended to improve performance and user experience.
            You should not continue to access our website after the cookie
            alert if you do not agree to their use.
          </p>
          <h4>9. OTHER DATA PROCESSING</h4>
          <p>
            If it becomes necessary to process your data for a new purpose,
            not covered by this Privacy Policy, SKYPRO will send you a
            notification explaining the reason and the conditions of
            processing.
          </p>
          <h4>10. LINKING TO SOCIAL NETWORKS</h4>
          <p>
            We allow you to connect to social networks, but we are not
            responsible for their privacy and security policies. We
            recommend that you check them.
          </p>
          <p>
            These are links to social networks outside SKYPRO that are
            subject to their own Privacy Policies. These networks may
            record information about user activities on the Internet,
            including on our website.
          </p>
          <p>
            We recommend that you review the terms of use and privacy
            policies of these social networks to learn exactly how they use
            your personal data, as well as the procedure for deleting or
            limiting the processing of such data.
          </p>
          <h4>11. LEGISLATION</h4>
          <p>
            In case of controversy or claim of any nature related to this
            website, Portuguese law shall apply, regardless of any
            applicable conflict of laws. All legal actions or proceedings
            arising out of or relating to this Privacy Policy, its
            validity, application, compliance or non-compliance, or the
            subject matter of this Privacy Policy, shall fall under the
            jurisdiction of the Portuguese courts, and the user consents
            and submits to the jurisdiction of these courts in the cases
            indicated.{' '}
          </p>
          <p>
            The processing of personal data is governed by Law 58/2019, of
            August 8th, and the General Data Protection Regulation 2016/679
            (Regulation (EU) 2016/679, of April 27th 2016), or other
            applicable legislation.
          </p>
          <h4>12. UPDATES TO THIS PRIVACY POLICY</h4>
          <p>
            SKYPRO reserves the right at any time to make readjustments or
            changes to this Privacy Policy in accordance with laws,
            regulations and good practices, such changes being duly
            published on its website. The date of the last update to this
            document is indicated in the legend "Last update" in the header
            of this document. Changes to this Privacy Policy will be
            effective upon posting on the website.{' '}
          </p>
          <br />
          <p>
            By consenting to this privacy policy, you are giving permission
            to process your personal data specifically for the purposes
            identified and selected.{' '}
          </p>
        </td>
      </tr>
      <tr>
        <td />
      </tr>
    </tbody>
  </table>
)

export default PrivacyPolicyData
