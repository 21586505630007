import React from 'react'

import {Spin} from 'antd'

import {OverlayContainer, OverlayContent} from './LoadingStyles'

const ComponentLoadingOverlay = ({isLoading, children}) => (
  <OverlayContainer>
    {children}
    {isLoading && (
      <OverlayContent>
        <Spin size='large' />
      </OverlayContent>
    )}
  </OverlayContainer>
)

export default ComponentLoadingOverlay
