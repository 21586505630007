import React, {useState} from 'react'

import {Col, Row} from 'antd'
import {TextField, Checkboxes} from 'mui-rff'
import PropTypes from 'prop-types'
import {Field, Form} from 'react-final-form'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {AuthTokenKey} from '../../infra/config/LocalStorageKeys'
import {SaveAccountSettings} from '../../infra/requests/CheckoutRequests'
import {
  PutUserPrivacyPolicy,
  GetUserInfo
} from '../../infra/requests/UserRequests'
import {userSave, userDelete} from '../../redux/data/user/UserActions'
import AlertService from '../../shared/components/alert/AlertService'
import BaseButton from '../../shared/components/buttons/BaseButton'
import {
  RowDiv,
  Subtitle,
  Title,
  WrapperBtn,
  WrapperNew,
  BirthDiv,
  BirthSelect,
  BirthSelectLast
} from './AccountSettingsStyle'

const newsletter = 'false'
const revoke = 'false'

const AccountSettings = ({dispatch, user, translate}) => {
  const [logoutIsHover, setLogoutIsHover] = useState(false)
  const [saveIsHover, setSaveIsHover] = useState(false)

  const handleLogoutMouseEnter = () => {
    setLogoutIsHover(true)
  }

  const handleLogoutMouseLeave = () => {
    setLogoutIsHover(false)
  }

  const handleSaveMouseEnter = () => {
    setSaveIsHover(true)
  }

  const handleSaveMouseLeave = () => {
    setSaveIsHover(false)
  }

  const logoutBtnStyle = {
    padding: '0 20px',
    width: 'auto',
    minWidth: '40%',
    margin: '0',
    height: 'auto',
    minHeight: '42px',
    cursor: 'pointer',
    transition: 'all 0.5s ease',
    color: logoutIsHover
      ? user.configuration?.layoutColors?.contentButtonText
      : user.configuration?.layoutColors?.contentText,
    border: `1px solid ${user.configuration?.layoutColors?.contentText}`,
    backgroundColor: logoutIsHover
      ? user.configuration?.layoutColors?.contentText
      : 'transparent'
  }

  const saveBtnStyle = {
    padding: '0 20px',
    width: 'auto',
    minWidth: '40%',
    margin: '0',
    height: 'auto',
    minHeight: '42px',
    cursor: 'pointer',
    transition: 'all 0.5s ease',
    color: saveIsHover
      ? user.configuration?.layoutColors?.contentButtonText
      : user.configuration?.layoutColors?.contentText,
    border: `1px solid ${user.configuration?.layoutColors?.contentText}`,
    backgroundColor: saveIsHover
      ? user.configuration?.layoutColors?.contentText
      : 'transparent'
  }

  const fieldStyles = {
    width: '100%',
    height: '52px',
    padding: '5px 15px',
    borderRadius: '5px',
    border: '1px solid hsla(0,0%,71%,0.47)',
    outline: 'none',
    ' -webkit-appearance': 'none',
    appearance: 'none'
  }

  function renderDaysOfMonth() {
    const rows = []

    rows.push(<option key={0}>{translate('DAY')}</option>)

    for (let i = 1; i < 32; i++) {
      rows.push(
        <option key={i} value={i}>
          {i}
        </option>
      )
    }

    return rows
  }

  function renderMonths() {
    const rows = []

    rows.push(<option key={0}>{translate('MONTH')}</option>)
    rows.push(
      <option key={1} value={1}>
        {translate('JANUARY')}
      </option>
    )
    rows.push(
      <option key={2} value={2}>
        {translate('FEBRUARY')}
      </option>
    )
    rows.push(
      <option key={3} value={3}>
        {translate('MARCH')}
      </option>
    )
    rows.push(
      <option key={4} value={4}>
        {translate('APRIL')}
      </option>
    )
    rows.push(
      <option key={5} value={5}>
        {translate('MAY')}
      </option>
    )
    rows.push(
      <option key={6} value={6}>
        {translate('JUNE')}
      </option>
    )
    rows.push(
      <option key={7} value={7}>
        {translate('JULY')}
      </option>
    )
    rows.push(
      <option key={8} value={8}>
        {translate('AUGUST')}
      </option>
    )
    rows.push(
      <option key={9} value={9}>
        {translate('SEPTEMBER')}
      </option>
    )
    rows.push(
      <option key={10} value={10}>
        {translate('OCTOBER')}
      </option>
    )
    rows.push(
      <option key={11} value={11}>
        {translate('NOVEMBER')}
      </option>
    )
    rows.push(
      <option key={12} value={12}>
        {translate('DECEMBER')}
      </option>
    )

    return rows
  }

  function renderYears() {
    const rows = []

    rows.push(<option key={0}>{translate('YEAR')}</option>)

    for (let i = 2005; i > 1899; i--) {
      rows.push(
        <option key={i} value={i}>
          {i}
        </option>
      )
    }

    return rows
  }

  const Logout = () => {
    localStorage.removeItem(AuthTokenKey)
    dispatch(userDelete())
  }

  const onSubmit = async (values) => {
    if (values?.password === '' || values?.password === undefined) {
      AlertService.error(
        translate('ERROR'),
        translate('ERROR_CURRENT_PASSWORD_REQUIRED')
      )
    } else if (values?.newPassword !== values?.confirmNewPassword) {
      AlertService.error(
        translate('ERROR'),
        translate('ERROR_PASSWORD_CONFIRMATION_DOES_NOT_MATCH')
      )
    } else if (values?.newPassword === values?.password) {
      AlertService.error(
        translate('ERROR'),
        translate('ERROR_PASSWORD_EQUAL_PREVIOUS')
      )
    } else {
      const result = await SaveAccountSettings(values)

      if (result.success) {
        if (values.checkRevokeRgpd === true) {
          await PutUserPrivacyPolicy({privacyPolicy: false})
          localStorage.removeItem(AuthTokenKey)
          dispatch(userDelete())
        } else {
          const {data, success} = await GetUserInfo()
          if (success) {
            dispatch(userSave(data))
          }

          AlertService.success(
            translate('SUCCESS'),
            translate('SUCCESS_DATA_SAVED_SUCCESSFULLY')
          )
        }
      } else {
        AlertService.error(
          translate('ERROR'),
          translate('ERROR_DATA_SAVED_FAILED')
        )
      }
    }
  }

  return (
    <div>
      <Row gutter={[0, 0]}>
        <Col xs={1} md={5} lg={7} />
        <Col xs={22} md={14} lg={10}>
          <Title>
            <Translate id='ACCOUNT_SETTINGS' />
          </Title>
          <Subtitle>
            <Translate id='LOGIN' />
          </Subtitle>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              email: user.userName,
              firstName: user.fullName.split(' ', 2)[0],
              lastName: user.fullName.split(' ', 2)[1]
            }}
            render={({handleSubmit, form, submitting}) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                style={{paddingBottom: '80px'}}
              >
                <>
                  <Col item xs={24}>
                    <RowDiv
                      style={{
                        backgroundColor: '#dedede'
                      }}
                    >
                      <TextField
                        label={<Translate id='EMAIL_USERNAME' />}
                        name='email'
                        margin='none'
                        disabled
                      />
                    </RowDiv>
                  </Col>
                  <Col>
                    <RowDiv>
                      <TextField
                        label={<Translate id='CURRENT_PASSWORD' />}
                        name='password'
                        margin='none'
                        type='password'
                      />
                    </RowDiv>
                  </Col>
                  <Col>
                    <RowDiv>
                      <TextField
                        label={<Translate id='NEW_PASSWORD' />}
                        name='newPassword'
                        margin='none'
                        type='password'
                      />
                    </RowDiv>
                  </Col>
                  <Col>
                    <RowDiv>
                      <TextField
                        label={<Translate id='CONFIRM_PASSWORD' />}
                        name='confirmNewPassword'
                        margin='none'
                        type='password'
                      />
                    </RowDiv>
                  </Col>
                  <Col>
                    <Subtitle>
                      <Translate id='PERSONAL_DATA' />
                    </Subtitle>
                    <RowDiv
                      style={{
                        backgroundColor: '#dedede'
                      }}
                    >
                      <TextField
                        label={<Translate id='FIRST_NAME' />}
                        name='firstName'
                        margin='none'
                        disabled
                      />
                    </RowDiv>
                  </Col>
                  <Col>
                    <RowDiv
                      style={{
                        backgroundColor: '#dedede'
                      }}
                    >
                      <TextField
                        label={<Translate id='LAST_NAME' />}
                        name='lastName'
                        margin='none'
                        disabled
                      />
                    </RowDiv>
                  </Col>
                  <Col>
                    <WrapperBtn>
                      <Checkboxes
                        name='checkNewsletter'
                        formControlProps={{margin: 'none'}}
                        data={{
                          label: <Translate id='SIGNUP_NEWSLETTER' />,
                          value: {newsletter}
                        }}
                      />
                    </WrapperBtn>
                  </Col>
                  <Col>
                    <WrapperBtn>
                      <Checkboxes
                        name='checkRevokeRgpd'
                        formControlProps={{margin: 'none'}}
                        data={{
                          label: <Translate id='REVOKE_ACCESS' />,
                          value: {revoke}
                        }}
                      />
                    </WrapperBtn>
                  </Col>
                  <Col>
                    <Subtitle>
                      <Translate id='BIRTH_DATE_OPTIONAL' />
                    </Subtitle>
                  </Col>
                  <Col>
                    <BirthDiv>
                      <BirthSelect>
                        <Field
                          name='birthDay'
                          component='select'
                          defaultValue={user.birthDay}
                          style={fieldStyles}
                        >
                          {renderDaysOfMonth()}
                        </Field>
                      </BirthSelect>
                      <BirthSelect>
                        <Field
                          name='birthMonth'
                          component='select'
                          defaultValue={user.birthMonth}
                          style={fieldStyles}
                        >
                          {renderMonths()}
                        </Field>
                      </BirthSelect>
                      <BirthSelectLast>
                        <Field
                          name='birthYear'
                          component='select'
                          defaultValue={user.birthYear}
                          style={fieldStyles}
                        >
                          {renderYears()}
                        </Field>
                      </BirthSelectLast>
                    </BirthDiv>
                  </Col>
                </>
                <WrapperNew>
                  <BaseButton
                    type='primary'
                    htmlType='submit'
                    disabled={submitting}
                    style={saveBtnStyle}
                    onMouseEnter={handleSaveMouseEnter}
                    onMouseLeave={handleSaveMouseLeave}
                  >
                    <Translate id='SAVE_CHANGES' />
                  </BaseButton>
                  <BaseButton
                    type='primary'
                    disabled={submitting}
                    style={logoutBtnStyle}
                    onMouseEnter={handleLogoutMouseEnter}
                    onMouseLeave={handleLogoutMouseLeave}
                    onClick={() => Logout()}
                  >
                    <Translate id='LOGOUT' />
                  </BaseButton>
                </WrapperNew>
              </form>
            )}
          />
        </Col>
        <Col xs={1} md={5} lg={7} />
      </Row>
    </div>
  )
}

AccountSettings.propTypes = {
  translate: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(AccountSettings))
