// font sizes
// ----------------------------------------- START
export const LinkText = '14px'

export const PrimaryTitle = '45px'
export const MenuLink = '24px'
export const NavText = LinkText
export const PanelTopTitle = '36px'
export const FooterTitle = LinkText
export const FooterSubTitle = LinkText
export const AccountSettingsTitle = '28px'
export const AccountSettingsSubtitle = '17px'
export const LabelText = '16px'
export const AddressTextTitle = '15px'
export const BodyText = '14px'
export const AddressSubTitle = '13px'
export const PanelTopSubTitle = '12px'
export const OverlineText = '11px'
export const BagText = '20px'
export const IconSize = '18px'
export const MediaType = '10px'
export const LblText = '9px'
// ----------------------------------------- END
export const H1Text = '34px'
export const H2Text = '27px'
export const H3Text = '23px'
export const TabsText = '21px'
export const Subtitle1Text = '19px'

export const ButtonText = '16px'
export const Subtitle2Text = '14px'

export const CaptionText = '13px'
export const FooterText = '12px'

// font weight
export const LightFontWeight = '200'
export const BookFontWeight = '300'
export const MediumFontWeight = '500'
export const SemiBoldFontWeight = '600'
export const BoldFontWeight = '700'

// font-family / Weight
export const Campton = 'Campton'
export const Assistant = 'Assistant'
export const Calibri = 'Calibri'

export const Assistant200 = 'Assistant_200'
export const Assistant300 = 'Assistant_300'
export const Assistant500 = 'Assistant_500'
export const Assistant600 = 'Assistant_600'
export const Assistant800 = 'Assistant_800'
export const AssistantBold = 'Assistant Bold'
