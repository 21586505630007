import React from 'react'

import {CloseOutlined} from '@ant-design/icons'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import {
  CloseModal,
  PrivacyLink,
  WarningBarCookie,
  Wrapper
} from './CookiesWarningStyle'

// Later we can use cookies to only show this component the first time entering the store
const WarningCookies = ({setCookiesShow}) => (
  <WarningBarCookie>
    <Wrapper>
      {<Translate id='COOKIES_WARNING' />}{' '}
      <PrivacyLink href='https://wearskypro.com/Corp/PrivacyPolicy.aspx' target='_blank'>
        {<Translate id='COOKIES_WARNING_PRIVACY' />}
      </PrivacyLink>
      . &nbsp;&nbsp;
      <CloseModal onClick={setCookiesShow}>
        <CloseOutlined />
      </CloseModal>
    </Wrapper>
  </WarningBarCookie>
)

WarningCookies.propTypes = {
  setCookiesShow: PropTypes.func.isRequired
}

export default WarningCookies
