import styled from 'styled-components'

import {ReactComponent as Logo} from '../../../assets/my_skypro_portal_white.svg'
import {PageBackground, PrimaryTextColor} from '../../styles/_colors'

export const FooterWrapper = styled.footer``

export const FooterCopyright = styled.div`
  padding: 10px 0px;
  background-color: ${PageBackground};
  display: inline-block;
  text-align: center;
  font-size: 0;
  width: 100%;
  border-top: 1px solid #dcdcdc;
  position: fixed;
  bottom: 0;
`

export const FooterContainer = styled.div`
  display: inline-flex;
  width: 100%;
  padding: 3px 40px;
`

export const AlignLeft = styled.div`
  width: 50%;
  text-align: left;
  margin: auto;
`

export const AlignRight = styled.div`
  width: 50%;
  text-align: right;
  margin: auto;
`

export const SkyproLogo = styled(Logo)`
  max-width: 100px;
  vertical-align: middle;

  .cls-1 {
    fill: ${PrimaryTextColor};
  }
`

export const FooterP = styled.p`
  text-align: right;
  font-family: 'Assistant';
  font-weight: regular;
  font-style: normal;
  margin: 0px;
  font-size: 11px;
  line-height: 18px;
  color: ${PrimaryTextColor};
`
