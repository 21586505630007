import React, {useEffect, useState} from 'react'

import {Col} from 'antd'
import {PropTypes} from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {useHistory} from 'react-router-dom'

import {
  DeleteAddress,
  GetAllAddresses
} from '../../infra/requests/AddressRequests'
import BaseButton from '../../shared/components/buttons/BaseButton'
import BaseLoading from '../../shared/components/loading/BaseLoading'
import PageTopPanel from '../../shared/components/panels/PageTopPanel'
import {BottomPanel, MainContainer, Wrapper} from './AddressesStyle'
import AddressMiddlePanel from './components/AddressMiddlePanel'

const Addresses = ({user, translate}) => {
  const history = useHistory()
  const [address, setAddress] = useState([])
  const [loading, setLoading] = useState(true)
  const [totalItems, setTotalItems] = useState(0)
  const [isHover, setIsHover] = useState(false)

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const btnStyle = {
    height: '42px',
    width: '350px',
    borderRadius: '75px',
    cursor: 'pointer',
    transition: 'all 0.5s ease',
    color: isHover
      ? user.configuration?.layoutColors?.contentButtonText
      : user.configuration?.layoutColors?.contentText,
    border: `1px solid ${user.configuration?.layoutColors?.contentText}`,
    backgroundColor: isHover
      ? user.configuration?.layoutColors?.contentText
      : 'transparent'
  }

  useEffect(() => {
    const Init = async () => {
      const {data, success} = await GetAllAddresses()

      if (success) {
        setAddress(
          data.contactDetail.sort(
            (a, b) =>
              new Date(Date.parse(b.dateCreated)) -
              new Date(Date.parse(a.dateCreated))
          ) || []
        )
        setTotalItems(data.contactDetail.length)
      }
      setLoading(false)
    }

    Init()
  }, [])

  const AddressDelete = async (contactDetailId) => {
    try {
      const result = await DeleteAddress(contactDetailId)

      if (result.success) {
        const {data, success} = await GetAllAddresses()

        if (success) {
          setAddress(data.contactDetail)
          setTotalItems(data.contactDetail.length)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  if (loading) {
    return <BaseLoading />
  }

  return (
    <div>
      <MainContainer>
        <PageTopPanel
          user={user}
          title={<Translate id='ADDRESSES_TITLE' />}
          subtitle={<Translate id='ADDRESSES_SUBTITLE' />}
          type='withoutadditional'
        />
        <BottomPanel>
          {address.map((item) => (
            <AddressMiddlePanel
              user={user}
              key={item.contactDetailId}
              item={item}
              totalItems={totalItems}
              addressDelete={AddressDelete}
            />
          ))}
          <Wrapper>
            <Col xs={1} md={2} />
            <BaseButton
              disabled={false}
              onClick={() => history.push('/addresses/new')}
              type='primary'
              style={btnStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Translate id='ADD_NEW_ADDRESS' />
            </BaseButton>
            <Col xs={1} md={2} />
          </Wrapper>
        </BottomPanel>
      </MainContainer>
    </div>
  )
}

Addresses.propTypes = {
  translate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

export default withLocalize(Addresses)
