import React, {useEffect, useState} from 'react'

import {Checkboxes} from 'mui-rff'
import PropTypes from 'prop-types'
import {Field, Form} from 'react-final-form'
import {Translate, withLocalize} from 'react-localize-redux'
import {useParams, useHistory} from 'react-router-dom'

import {
  CreateNewAddress,
  GetAllAddresses,
  GetFilteredCountries,
  UpdateAddress
} from '../../../../infra/requests/AddressRequests'
import FormValidator from '../../../../infra/services/validations/FormValidator'
import {EditButton} from '../../../styles/BasicStyles'
import AlertService from '../../alert/AlertService'
import CreateAccInput from '../../inputs/CreateAccInput'
import PageTopPanel from '../../panels/PageTopPanel'
import {
  AddressBook,
  AddressForm,
  Content,
  FormRow,
  HalfPage,
  Legend,
  AddressesWrapper,
  RowContainer,
  RowHalf,
  SaveButton,
  AddressInfo,
  AddressName,
  AddressDeliveryName,
  AddressDetails,
  ExistingAddress,
  LabelForm,
  LabelSpace,
  CheckboxRow,
  StyledOption
} from './CreateAddressStyles'

const preferred = 'false'

const validateContact = FormValidator.make({
  name: 'required',
  label: 'required',
  addressLine1: 'required',
  zipCode: 'required',
  city: 'required',
  region: 'required',
  country: 'required',
  telephone: 'required'
})

const CreateAddressForm = ({user, translate}) => {
  const [address, setAddress] = useState([])
  const [countries, setCountries] = useState([])
  const [addressToUpdate, setAddressToUpdate] = useState({})
  const [initialData, setInitialData] = useState({})
  const [addressListSize, setAddressListSize] = useState(0)
  const {id} = useParams()
  const history = useHistory()
  const [closeIsHover, setCloseIsHover] = useState(false)
  const [saveIsHover, setSaveIsHover] = useState(false)

  const handleCloseMouseEnter = () => {
    setCloseIsHover(true)
  }

  const handleCloseMouseLeave = () => {
    setCloseIsHover(false)
  }

  const handleSaveMouseEnter = () => {
    setSaveIsHover(true)
  }

  const handleSaveMouseLeave = () => {
    setSaveIsHover(false)
  }

  const btnCloseStyle = {
    transition: 'all 0.5s ease',
    color: closeIsHover
      ? user.configuration?.layoutColors?.contentButtonText
      : user.configuration?.layoutColors?.contentText,
    border: `1px solid ${user.configuration?.layoutColors?.contentText}`,
    backgroundColor: closeIsHover
      ? user.configuration?.layoutColors?.contentText
      : 'transparent'
  }

  const btnSaveStyle = {
    transition: 'all 0.5s ease',
    color: saveIsHover
      ? user.configuration?.layoutColors?.contentButtonText
      : user.configuration?.layoutColors?.contentText,
    border: `1px solid ${user.configuration?.layoutColors?.contentText}`,
    backgroundColor: saveIsHover
      ? user.configuration?.layoutColors?.contentText
      : 'transparent'
  }

  const btnEditStyle = {
    transition: 'all 0.5s ease',
    color: user.configuration?.layoutColors?.contentText,
    border: `1px solid ${user.configuration?.layoutColors?.contentText}`,
    backgroundColor: 'transparent'
  }

  useEffect(() => {
    async function InitializeData() {
      const {data, success} = await GetAllAddresses()
      const countriesResult = await GetFilteredCountries(
        user.configuration?.b2bclientId
      )

      if (success) {
        setAddress(
          data.contactDetail.sort(
            (a, b) =>
              new Date(Date.parse(b.dateCreated)) -
              new Date(Date.parse(a.dateCreated))
          ) || []
        )
        setAddressListSize(data.contactDetail.length)
        if (id) {
          const tempData = data.contactDetail.find(
            (add) => add.contactDetailId === parseInt(id, 10)
          )
          setInitialData({...tempData, country: tempData.countryId})
          setAddressToUpdate(
            data.contactDetail.find(
              (x) => x.contactDetailId === parseInt(id, 10)
            )
          )
        }
      }
      setCountries(countriesResult.data)
    }
    InitializeData()
  }, [id, user.configuration.b2bclientId])

  const routeChange = () => {
    const path = '/addresses'
    history.push(path)
  }

  const onSubmit = async (values) => {
    if (Object.keys(addressToUpdate).length === 0) {
      try {
        const result = await CreateNewAddress(values)

        if (result.success) {
          const {data, success} = await GetAllAddresses()
          if (success) {
            setAddress(data.contactDetail || [])
            AlertService.success(
              translate('SUCCESS'),
              translate('SUCCESS_ADDRESS_SAVED_SUCCESSFULLY')
            )

            routeChange()
          }
        }
      } catch (e) {
        AlertService.error(
          translate('ERROR'),
          translate('ERROR_DATA_SAVED_FAILED')
        )
        console.error(e)
      }
    } else {
      try {
        const addressId = addressToUpdate.contactDetailId
        const result = await UpdateAddress(addressId, values)

        if (result.success) {
          AlertService.success(
            translate('SUCCESS'),
            translate('SUCCESS_ADDRESS_SAVED_SUCCESSFULLY')
          )
          routeChange()
        }
      } catch (e) {
        AlertService.error(
          translate('ERROR'),
          translate('ERROR_DATA_SAVED_FAILED')
        )
        console.error(e)
      }
    }
  }

  const redirectAndEdit = (contact) => {
    history.push(`/addresses/${contact.contactDetailId}`)
  }

  const fieldStyles = {
    width: '100%',
    height: '42px',
    padding: '0 15px',
    borderRadius: '3px',
    border: '1px solid hsla(0,0%,71%,0.47)',
    outline: 'none',
    fontSize: '14px'
  }

  return (
    <div>
      <PageTopPanel
        user={user}
        title={<Translate id='ADDRESS_BOOK' />}
        subtitle={<Translate id='MANAGE_ADDRESS' />}
        type='withoutadditional'
      />
      <Content>
        <AddressBook>
          <AddressesWrapper>
            <HalfPage>
              <Form
                validate={validateContact}
                onSubmit={onSubmit}
                initialValues={initialData}
                render={({handleSubmit, submitting}) => (
                  <AddressForm onSubmit={handleSubmit}>
                    <RowContainer>
                      <Legend>
                        <span>
                          {Object.keys(addressToUpdate).length === 0
                            ? translate('ADD_ADDRESS')
                            : translate('EDIT_ADDRESS')}
                        </span>
                      </Legend>

                      <FormRow>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='ADDRESS_NAME' />:
                          </LabelSpace>
                        </LabelForm>
                        <Field
                          name='name'
                          component={CreateAccInput}
                          textType='text'
                          placeholder={translate('ADDRESS_NAME')}
                        />
                      </FormRow>

                      <FormRow>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='NAME_FOR_DELIVERY' />:
                          </LabelSpace>
                        </LabelForm>
                        <Field
                          name='label'
                          component={CreateAccInput}
                          textType='text'
                          placeholder={translate('NAME_FOR_DELIVERY')}
                        />
                      </FormRow>

                      <FormRow>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='ADDRESS_LINE_1' />:
                          </LabelSpace>
                        </LabelForm>
                        <Field
                          name='addressLine1'
                          component={CreateAccInput}
                          textType='text'
                          placeholder={translate('ADDRESS_LINE_1')}
                        />
                      </FormRow>

                      <FormRow>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='ADDRESS_LINE_2' />:
                          </LabelSpace>
                        </LabelForm>
                        <Field
                          name='addressLine2'
                          component={CreateAccInput}
                          textType='text'
                          placeholder={translate('ADDRESS_LINE_2')}
                        />
                      </FormRow>

                      <FormRow>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='ADDRESS_LINE_3' />:
                          </LabelSpace>
                        </LabelForm>
                        <Field
                          name='addressLine3'
                          component={CreateAccInput}
                          textType='text'
                          placeholder={translate('ADDRESS_LINE_3')}
                        />
                      </FormRow>

                      <FormRow>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='STREET_NUMBER' />:
                          </LabelSpace>
                        </LabelForm>
                        <Field
                          name='streetNumber'
                          component={CreateAccInput}
                          textType='text'
                          placeholder={translate('STREET_NUMBER')}
                        />
                      </FormRow>
                    </RowContainer>

                    <RowContainer>
                      <RowHalf>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='ZIP_CODE' />:
                          </LabelSpace>
                        </LabelForm>
                        <Field
                          name='zipCode'
                          component={CreateAccInput}
                          textType='text'
                          placeholder={translate('ZIP_CODE')}
                        />
                      </RowHalf>
                      <RowHalf>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='CITY' />:
                          </LabelSpace>
                        </LabelForm>
                        <Field
                          name='city'
                          component={CreateAccInput}
                          textType='text'
                          placeholder={translate('CITY')}
                        />
                      </RowHalf>
                    </RowContainer>

                    <RowContainer>
                      <FormRow>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='REGION' />:
                          </LabelSpace>
                        </LabelForm>
                        <Field
                          name='region'
                          component={CreateAccInput}
                          textType='text'
                          placeholder={translate('REGION')}
                        />
                      </FormRow>

                      <FormRow>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='COUNTRY' />:
                          </LabelSpace>
                        </LabelForm>
                        <Field
                          name='country'
                          component='select'
                          defaultValue={
                            Object.keys(addressToUpdate).length === 0
                              ? countries[0]?.countryId
                              : initialData !== null
                              ? initialData.country
                              : 0
                          }
                          style={fieldStyles}
                        >
                          {countries.map((elem, index) => (
                            <StyledOption
                              key={index}
                              value={elem.countryId}
                            >
                              {translate(elem.name)}
                            </StyledOption>
                          ))}
                        </Field>
                      </FormRow>

                      <FormRow>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='PHONE_NUMBER' />:
                          </LabelSpace>
                        </LabelForm>
                        <Field
                          name='telephone'
                          component={CreateAccInput}
                          textType='text'
                          placeholder={translate('PHONE_NUMBER')}
                        />
                      </FormRow>

                      <FormRow>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='VAT_NUMBER' />:
                          </LabelSpace>
                        </LabelForm>
                        <Field
                          name='vatnumber'
                          component={CreateAccInput}
                          textType='text'
                          placeholder={translate('VAT_NUMBER')}
                        />
                      </FormRow>

                      <CheckboxRow>
                        <Checkboxes
                          name='preferred'
                          formControlProps={{margin: 'none'}}
                          data={{
                            label: <Translate id='PRIMARY_ADDRESS' />,
                            value: {preferred}
                          }}
                        />
                      </CheckboxRow>
                    </RowContainer>

                    <RowContainer style={{display: 'flex'}}>
                      <RowHalf
                        style={{
                          display: 'flex',
                          textAlign: 'center',
                          justifyContent: 'center',
                          marginRight: 0,
                          width: '50%'
                        }}
                      >
                        <EditButton
                          onClick={routeChange}
                          style={btnCloseStyle}
                          onMouseEnter={handleCloseMouseEnter}
                          onMouseLeave={handleCloseMouseLeave}
                        >
                          <Translate id='CLOSE' />
                        </EditButton>
                      </RowHalf>
                      <RowHalf
                        style={{
                          display: 'flex',
                          textAlign: 'center',
                          justifyContent: 'center',
                          marginRight: 0,
                          width: '50%'
                        }}
                      >
                        <SaveButton
                          type='submit'
                          disabled={submitting}
                          style={btnSaveStyle}
                          onMouseEnter={handleSaveMouseEnter}
                          onMouseLeave={handleSaveMouseLeave}
                        >
                          <Translate id='SAVE' />
                        </SaveButton>
                      </RowHalf>
                    </RowContainer>
                  </AddressForm>
                )}
              />
            </HalfPage>
            <HalfPage>
              {addressListSize > 0 &&
                address.map((item, index) => (
                  <ExistingAddress key={index}>
                    <AddressInfo>
                      <AddressName>{item.label}</AddressName>
                      <AddressDeliveryName>
                        {item.name}
                      </AddressDeliveryName>
                      <AddressDetails>
                        {item.addressLine1}
                        <br />
                        {item.zipCode} {item.city}, {item.region}
                        <br />
                        {item.country.name}
                        <br />
                        {item.phoneNumber}
                      </AddressDetails>
                    </AddressInfo>
                    <EditButton
                      onClick={() => {
                        redirectAndEdit(item)
                      }}
                      style={btnEditStyle}
                    >
                      <Translate id='EDIT' />
                    </EditButton>
                  </ExistingAddress>
                ))}
            </HalfPage>
          </AddressesWrapper>
        </AddressBook>
      </Content>
    </div>
  )
}

CreateAddressForm.propTypes = {
  user: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(CreateAddressForm)
