import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {
  GetAvailableSlots,
  GetDefaultSlot,
  GetEmployeeFittings
} from '../../infra/requests/FittingRequests'
import {GetAllProducts} from '../../infra/requests/ProductRequests'
import {GetEmployeeInfo} from '../../infra/requests/UserRequests'
import AlertService from '../../shared/components/alert/AlertService'
import BaseLoading from '../../shared/components/loading/BaseLoading'
import PageTopPanel from '../../shared/components/panels/PageTopPanel'
import FittingProductCard from './components/FittingProductCard'
import {Container} from './components/FittingProductCardStyle'
import {
  Bar,
  BottomPanel,
  CodeID,
  Name,
  ScheduleBtn,
  Symbol,
  Tags,
  TopPanel,
  Values,
  ValuesItems,
  Wrapper,
  WrapperBtn,
  WrapperEmployee,
  WrapperSymbol,
  BarItem,
  WrapperSchedule
} from './FittingStyle'

const Fitting = ({user, translate}) => {
  const history = useHistory()
  const [products, setProducts] = useState()
  const [defaultSlot, setDefaultSlot] = useState()
  const [employeeInfo, setEmployeeInfo] = useState()
  const [fittingDate, setFittingDate] = useState()
  const [lastFittingDate, setLastFittingDate] = useState('')
  const [nextFittingDate, setNextFittingDate] = useState('')
  const [fittings, setFittings] = useState([])
  const [loading, setLoading] = useState(true)
  const [isHover, setIsHover] = useState(false)

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const checkScheduleFitting = async () => {
    const {data, success} = await GetAvailableSlots()

    if (success) {
      if (data.length === 0) {
        AlertService.error(
          translate('ERROR'),
          translate('FITTING_SCHEDULE_NON_EXISTANT')
        )
      } else {
        history.push('/scheduleFitting')
      }
    }
  }

  const handleMeasure = () => {
    const productAvaliableToFitting =
      products[0].isUniqueSize === false &&
      (products[0]?.fitting === null || products[0]?.fitting === undefined)
        ? products[0]
        : products[1].isUniqueSize === false &&
          (products[1]?.fitting === null ||
            products[1]?.fitting === undefined)
        ? products[1]
        : products[2].isUniqueSize === false &&
          (products[2]?.fitting === null ||
            products[2]?.fitting === undefined)
        ? products[2]
        : null

    if (productAvaliableToFitting !== null) {
      history.push(
        `/fitting/appointment/${productAvaliableToFitting?.productId}`
      )
    }
  }

  const btnStyle = {
    transition: 'all 0.5s ease',
    color: isHover
      ? user.configuration?.layoutColors?.contentButtonText
      : user.configuration?.layoutColors?.contentText,
    border: `1px solid ${user.configuration?.layoutColors?.contentText}`,
    backgroundColor: isHover
      ? user.configuration?.layoutColors?.contentText
      : 'transparent'
  }

  useEffect(() => {
    try {
      const init = async () => {
        const {data, success} = await GetDefaultSlot()

        if (success) {
          setDefaultSlot(data)
        }

        const result = await GetEmployeeFittings()

        if (result.success) {
          setFittings(result.data)

          const result2 = await GetAllProducts()

          if (result2.success) {
            setProducts(result2.data.items)
            setLoading(false)
          }
        }
      }

      const GetEmployee = async () => {
        const {data, success} = await GetEmployeeInfo()

        if (success) {
          setEmployeeInfo(data)

          if (data?.lastFittingDate) {
            setLastFittingDate(
              moment(data?.lastFittingDate).format('DD-MM-YYYY')
            )
          }
          if (data?.nextFittingDate) {
            setNextFittingDate(
              moment(data?.nextFittingDate).format('DD-MM-YYYY')
            )
          }
        }
      }

      init()
      GetEmployee()
    } catch (e) {
      console.error(e)
    }
  }, [])

  if (loading) {
    return <BaseLoading />
  }

  return (
    <div>
      <PageTopPanel
        user={user}
        title={<Translate id='FITTING_TITLE' />}
        subtitle={<Translate id='FITTING_SUBTITLE' />}
        type='withoutadditional'
      />

      <TopPanel>
        <Bar>
          <BarItem>
            <WrapperSymbol>
              <Symbol
                style={{
                  backgroundColor:
                    user.configuration?.layoutColors?.contentText
                }}
              >
                {employeeInfo?.firstName[0]}
                {employeeInfo?.lastName[0]}
              </Symbol>
              <WrapperEmployee>
                <Name>
                  {employeeInfo?.firstName} {employeeInfo?.lastName}
                </Name>

                <CodeID>#{employeeInfo?.number}</CodeID>
              </WrapperEmployee>
            </WrapperSymbol>
          </BarItem>
          <BarItem>
            <Wrapper>
              <Tags>
                <Translate id='FUNCTION' />
              </Tags>
              <Values>{employeeInfo?.position?.name}</Values>
            </Wrapper>
          </BarItem>
          <BarItem>
            <Wrapper>
              <Tags style={{minWidth: 101}}>
                <Translate id='OUTFIT_ITEMS' />
              </Tags>
              <ValuesItems
                style={{
                  color: user.configuration?.layoutColors?.contentText
                }}
              >
                {products?.length ?? 0}
              </ValuesItems>
            </Wrapper>
          </BarItem>
          <BarItem>
            <Wrapper>
              {employeeInfo?.nextFittingDate !== '' && (
                <Values>
                  {moment(employeeInfo?.nextFittingDate).format(
                    'DD-MM-YYYY'
                  ) || <Translate id='NA' />}
                </Values>
              )}
            </Wrapper>
          </BarItem>
          <BarItem>
            <WrapperBtn>
              <ScheduleBtn
                onClick={() => checkScheduleFitting()}
                style={btnStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {defaultSlot ? (
                  <Translate id='RESCHEDULE' />
                ) : (
                  <Translate id='SCHEDULE' />
                )}
              </ScheduleBtn>
            </WrapperBtn>
          </BarItem>
        </Bar>
      </TopPanel>

      <BottomPanel>
        <Row gutter={[0, 0]}>
          <Col xs={1} md={1} />
          <Col xs={22} md={22}>
            <Container>
              <Row gutter={[40, 30]}>
                {products.map((item, index) => (
                  <FittingProductCard
                    key={index}
                    product={item}
                    fitting={fittings.find(
                      (x) => x.fitting.productId === item.productId
                    )}
                  />
                ))}
              </Row>
              {user.allowEmployeeSelfFitting === true ? (
                <Row gutter={[0, 0]}>
                  <Col xs={24}>
                    <WrapperSchedule>
                      <ScheduleBtn
                        onClick={() => handleMeasure()}
                        style={btnStyle}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        <Translate id='PROCEED_TO_MEASURES' />
                      </ScheduleBtn>
                    </WrapperSchedule>
                  </Col>
                </Row>
              ) : null}
            </Container>
          </Col>
          <Col xs={1} md={1} />
        </Row>
      </BottomPanel>
    </div>
  )
}

Fitting.propTypes = {
  user: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(Fitting))
