export default {
  OOPS_PAGE_NOT_FOUND: 'Vaya... ¡Página no encontrada!',
  PAGE_NOT_FOUND_DESCRIPTION_1: 'Pedimos disculpas por las molestias.',
  PAGE_NOT_FOUND_DESCRIPTION_2:
    'Al parecer, la página que estás intentando encontrar ha sido borrada o nunca existió.',
  BACK_TO_INITIAL_PAGE: 'Volver a la página inicial',
  PASSWORD_DOESNT_MATCH: 'La contraseña no coincide',
  OUTFIT: 'UNIFORME',
  CREDITS: 'CRÉDITOS',
  FITTING: 'ADECUADO',
  MY_ORDERS: 'PEDIDOS',
  ADDRESSES: 'DIRECCIONES',
  SUPPORT: 'APOYO',
  ACCOUNT_SETTINGS: 'CONFIGURACIONES DE LA CUENTA',
  MY_BAG: 'Mi bolsa',
  OUTFIT_TITLE: 'Artículos en tu atuendo',
  OUTFIT_SUBTITLE:
    'Añade a tu cesta todos los artículos que necesites para completar tu uniforme.',
  CREDIT_TITLE: 'Créditos',
  CREDIT_SUBTITLE: 'Verifique su saldo por artículo',
  ORDERS_TITLE: 'Pedidos',
  ORDERS_SUBTITLE: 'pedidos encontrados',
  SUPPORT_TITLE: 'Preguntas frecuentes y soporte',
  SUPPORT_SUBTITLE: '',
  FITTING_TITLE: 'Adecuado',
  FITTING_SUBTITLE: 'Comprueba las medidas del uniforme',
  ADDRESSES_TITLE: 'Directorio',
  ADDRESSES_SUBTITLE: 'Gestiona tus direcciones',
  PERSONAL_DATA: 'Información personal',
  LOGIN: 'Iniciar sesión',
  SIGNUP_NEWSLETTER: 'Quiero suscribirme al boletín de Skypro',
  NO_DATA_TO_DISPLAY: '¡No hay información para mostrar!',
  REVOKE_ACCESS:
    'Revocar el acceso a mis datos otorgados bajo el consentimiento de la Política de privacidad de SKYPRO (esta operación lo desconectará y evitará el uso del Portal mySkypro)',
  ORDER_ALL_OUTFIT: 'ORDENE TODO EL ATUENDO',
  SAVE: 'SALVAR',
  EDIT: 'EDITAR',
  ADD_NEW_ADDRESS: 'AGREGAR NUEVA DIRECCIÓN',
  CLOSE: 'CERCA',
  PRIMARY_ADDRESS: 'Dirección preferida',
  LOGOUT: 'CERRAR SESIÓN',
  EMAIL_USERNAME: 'Correo electrónico / nombre de usuario',
  USERNAME: 'Nombre de usuario',
  CURRENT_PASSWORD: 'Contraseña actual',
  NEW_PASSWORD: 'Nueva contraseña',
  CONFIRM_PASSWORD: 'Confirmar nueva contraseña',
  ADDRESS_NAME: 'Nombre de la dirección',
  NAME_FOR_DELIVERY: 'Nombre para la entrega',
  ADDRESS_LINE_1: 'Dirección línea 1',
  ADDRESS_LINE_2: 'Dirección línea 2',
  ADDRESS_LINE_3: 'Dirección línea 3',
  STREET_NUMBER: 'Número de calle',
  ZIP_CODE: 'Código postal',
  CITY: 'Ciudad',
  REGION: 'Región',
  COUNTRY: 'País',
  PHONE_NUMBER: 'Número de teléfono',
  VAT_NUMBER: 'Número de valor agregado',
  ADD_NEW_ADDRESS_LOWER: 'Agregar nueva dirección',
  USED_CREDITS: 'CRÉDITOS USADOS',
  ITEMS_IN_OUTFIT: 'Artículos en tu atuendo',
  OTHER_ITEMS_FOR_YOU: 'Otros artículos para ti',
  OTHER_ITEMS_DESCRIPTION:
    'Encontramos otros artículos que pueden complementar tu uniforme.',
  WELCOME_LOGIN: 'Bienvenido a',
  WELCOME_SUB: 'Aumente la eficiencia mientras empodera a su equipo',
  WELCOME_SUB_DEFAULT:
    'Una combinación única de conocimientos y tecnología que crea las mejores soluciones de cuidado de tripulaciones del mundo para su empresa.',
  SIGN_IN: 'Iniciar sesión',
  RECOVER: 'Recuperar',
  EMAIL_LOGIN: 'Correo electrónico',
  PASSWORD_LOGIN: 'Contraseña',
  LOG_IN: 'Acceso',
  REMEMBER_ME: 'Acuérdate de mí',
  RECOVER_PASSWORD: '¿Olvidaste tu contraseña?',
  ACCOUNT: 'Cuenta',
  ORDERS: 'pedidos',
  FITTING_SMALL: 'adecuado',
  HOW_CHANGE_PASSWORD: '¿Como puedo cambiar mi contraseña?',
  HOW_CHANGE_PASSWORD_ANSWER:
    'Haga clic en su nombre en la esquina superior derecha o <a href="/accountsettings">aquí</a> para ir a las definiciones de su cuenta, donde puede cambiar su contraseña para iniciar sesión en su cuenta.',
  HOW_CHECK_CREDITS: '¿Cómo reviso mis créditos?',
  HOW_CHECK_CREDITS_ANSWER:
    'Puedes consultar tus créditos en <a href="/credits">Créditos</a>. Encontrarás un listado con todas las prendas de tu uniforme y sus créditos remanentes y totales, así como su fecha de renovación, si corresponde.',
  HOW_CHANGE_ADDRESSES: '¿Cómo cambio/agrego direcciones y contactos?',
  HOW_CHANGE_ADDRESSES_ANSWER:
    'Vaya a <a href="/addresses">Direcciones</a> para cambiar/agregar direcciones y contactos. Haga clic en "Editar" para cambiar los datos o "Agregar nueva dirección" para incluir una dirección adicional. Recuerda que debes actualizar tus contactos antes de completar un pedido.',
  HOW_CHECK_UNIFORM: '¿Cómo reviso mi uniforme?',
  HOW_CHECK_UNIFORM_ANSWER:
    'Puedes consultar las prendas de tu uniforme en <a href="/outfit">Outfit</a>. Sólo se muestran los artículos que componen tu uniforme.',
  HOW_CHANGE_SIZE: '¿Cómo cambio la talla de mi uniforme?',
  HOW_CHANGE_SIZE_ANSWER:
    'En <a href="/measures">Measures</a>, programe una nueva sesión de adaptación haciendo clic en "Reprogramar" y eligiendo una fecha, hora y lugar.',
  HOW_SCHEDULE_FITTING: '¿Cómo programo una prueba?',
  HOW_SCHEDULE_FITTING_ANSWER:
    'Vaya a <a href="/measures">Medidas</a>, haga clic en "Programar" o "Reprogramar" y elija una fecha, hora y lugar.',
  HOW_CHECK_STATUS: '¿Cómo verifico el estado de mi pedido?',
  HOW_CHECK_STATUS_ANSWER:
    'Vaya a <a href="/myorders">Mis pedidos</a>, busque su número de pedido y haga clic en el icono "+" para consultar más detalles sobre su pedido.',
  RECEIVED_INCOMPLETE: 'Recibí un pedido incompleto.',
  RECEIVED_INCOMPLETE_ANSWER:
    'Es posible que su pedido haya sido enviado en paquetes. El pedido se completará tan pronto como haya stock disponible. Después de la expedición recibirás un correo electrónico que incluirá el(los) artículo(s) con el número de seguimiento de cada entrega.',
  RECEIVED_INCORRECT: 'Recibí artículos incorrectos.',
  RECEIVED_INCORRECT_ANSWER:
    'Recibiste los artículos que seleccionaste y firmaste en el momento en que se realizó la prueba. Si hay un artículo incorrecto causado por nuestros servicios de logística, comuníquese con cs@wearskypro.com. \n\n Nota: su empresa no acepta devoluciones de artículos de tallas que usted seleccionó.\n Nota: el envío de artículos con nuevas tallas por motivos de cambio tiene costos de envío que usted deberá pagar.',
  PAYMENT_METHODS: 'Métodos de pago.',
  PAYMENT_METHODS_ANSWER:
    'Aceptamos los siguientes tipos de pagos:\n VISA\n Cajero Automático\n Paypal\n MasterCard\n American Express\n\n Le aseguramos que su pago se puede realizar con seguridad de acuerdo con los estándares internacionales de seguridad de pagos en línea. \n Su número de tarjeta de crédito se inserta en una página segura, alojada por nuestro banco y, por lo tanto, externa a nuestro sitio. Ningún número de tarjeta de crédito se registra ni es visible en nuestro sitio durante el proceso de pago. No tenemos acceso a su información de pago.\n Este sitio web utiliza la tecnología Secure Sockets Layer (SSL) para brindar la experiencia de compra más segura posible. La tecnología SSL permite el cifrado (codificación) de información confidencial durante sus transacciones en línea. Todos los formularios de nuestro sitio están protegidos con la misma tecnología SSL, para que su información personal permanezca segura.',
  REFUND_PERIOD: 'Periodo de devolución.',
  REFUND_PERIOD_ANSWER:
    'Los reembolsos se realizan utilizando el mismo método de pago utilizado para procesar el pedido, en un período de 5 a 10 días hábiles, después de la recepción del(los) artículo(s) devuelto(s).\n\n Si paga con una tarjeta de regalo o tarjeta de pago ( virtual o física), el reembolso se acreditará en la misma tarjeta. Si al momento del reembolso ya no tiene la tarjeta utilizada en el pago, le sugerimos que se comunique con nuestro Departamento de Atención al Cliente en cs@wearskypro.com.',
  INCIDENT_UNIFORM:
    'Mientras trabajaba tuve un incidente con mi uniforme. ¿Puedo cambiarlo?',
  INCIDENT_UNIFORM_ANSWER:
    'Vaya a <a href="/orders">Pedidos</a>, "incidentes uniformes" y explique la situación. En caso de que se acepte su queja, se enviará una notificación indicando que hay un nuevo artículo disponible para ser enviado para reemplazar el dañado.\n En caso de que no se acepte su queja, se enviará una notificación indicando el motivo.',
  GENERAL: 'General',
  PAYMENTS: 'Pagos',
  RETURNS_EXCHANGES: 'Devoluciones / Cambios',
  HELPFUL: 'Útil',
  NOT_HELPFUL: 'No es útil',
  EXTRA_HELP: '¿Necesitas ayuda adicional?',
  DETAILS_HEADER: 'Detalles',
  COMPOSITION: 'Composición',
  COMPOSITION_ANSWER: '50% algodón, 47% poliamida, 3% elastano',
  WEIGHT: 'Peso',
  WEIGHT_ANSWER: '335grs',
  WIDTH: 'Ancho',
  WIDTH_ANSWER: '160CM',
  TEXTILE: 'Textil',
  TWILL: 'Tela asargada',
  TECH_SUBTITLE:
    'Protección y seguridad total para tu intensa vida laboral.',
  ABSOLUTE_COMFORT: 'Comodidad Absoluta',
  ABSOLUTE_COMFORT_TEXT:
    'Las plantillas Skypro utilizan un material de estructura microcelular especial con características sorprendentes. No pierden amortiguación, son transpirables, suaves y resistentes, absorben el impacto del suelo protegiendo así la columna y las articulaciones y proporcionan una mayor flexibilidad para un confort absoluto.',
  EASY_CLEAN: 'Fácil limpieza',
  EASY_CLEAN_TEXT:
    'Al mejorar las características repelentes de la superficie de nuestras telas, utilizando tecnología de punta cuidadosamente seleccionada, se permite una fácil eliminación de las manchas. Con este acabado se mejoran las propiedades de secado del tejido y garantizamos una prenda mucho más duradera.',
  ECOLOGIC: 'Ecológico',
  ECOLOGIC_TEXT:
    'Garantizar un mínimo de residuos, envases y huella ambiental en línea con las recomendaciones de la ONU, priorizando el reciclaje y la reutilización de envases, y promoviendo el uso de materias primas sostenibles, renovables y ecológicas.',
  RELATED_TITLE: 'Otros artículos para ti',
  COLORS_TITLE: 'Colores disponibles',
  IMAGE: 'Imagen',
  TECHNOLOGY: 'Tecnología',
  RELATED: 'Relacionado',
  CHECKOUT: 'Checkout',
  CONFIRM_DETAILS: 'Confirma tus datos a continuación',
  CONTACT: 'Contacto',
  SEND_TO: 'Enviar a',
  PAYMENT_METHOD: 'Método de pago',
  SECURE_ENCRYPTED: 'Todas las transacciones son seguras y encriptadas.',
  CHANGE: 'Cambiar',
  CREDIT_CARD: 'Tarjeta de crédito',
  CREDIT_CARD_TEXT:
    'Cuando hagas clic en Completar pedido, serás redirigido a la página de pago.',
  ATM: 'Multibanco/ATM referencia',
  ATM_TEXT: 'Recibirás un correo electrónico con los datos de pago.',
  BILLING_DATA: 'Información de facturación',
  INVOICE_TO: 'facturar a',
  COMPLETE_ORDER: 'Orden completa',
  REFUND_POLICY: 'Politica de reembolso',
  PRIVACY_POLICY: 'Política de privacidad',
  TERMS_SERVICE: 'Términos de servicio',
  VOUCHER_CODE: 'Código de cupón',
  APPLY: 'Aplicar',
  SUBTOTAL: 'Total parcial',
  TRANSPORTATION: 'Envío',
  TOTAL: 'Total',
  NO_ITEMS: 'No tienes artículos en tu bolsa de compras.',
  SCROLL_DOWN: 'Desplácese hacia abajo',
  COLOR: 'COLOR',
  STATUS: 'Estado',
  VALUE_TAG: 'Cerrada',
  STATUS_TAG: 'Se renueva:',
  ORDER_BTN: 'Pedir uniforme completo',
  FUNCTION: 'Función:',
  OUTFIT_ITEMS: 'Artículos en traje:',
  NEXT_FITTING: 'Siguiente Montaje:',
  RESCHEDULE: 'Reprogramar',
  SCHEDULE: 'Programar',
  ABOUT: 'Acerca de Skypro',
  PROHEALTH: 'Tecnología ProSalud',
  CUSTOMER: 'Servicio al cliente',
  FOLLOW: 'Conectar y seguir',
  NEWSLETTER: 'Boletin informativo',
  BLOG: 'Blog',
  COPYRIGHT: '©${date} SKYPRO. Reservados todos los derechos.',
  ORDER: 'Pedido',
  DATE: 'Fecha',
  DELIVERY: 'Entrefa',
  NO_ORDERS: 'No se encontraron pedidos',
  DELIVERY_ADDRESS: 'Dirección de entrega',
  PROBLEM_CONTACT: '¿Problema? Contáctenos',
  ORDER_SUMMARY: 'Resumen de Pedido',
  ITEMS: 'Artículos',
  POSTAGE_PACKING: 'Franqueo & Embalaje',
  ORDER_TOTAL: 'Total del pedido',
  DATE_SUBMISSION: 'Fecha de presentación',
  ESTIMATED_DELIVERY: 'Entrega Estimada',
  PAYMENT_STATE: 'Estado de pago',
  GET_REFERENCE: 'Proceder al pago',
  QUANTITY: 'Cantidad',
  ADD_ADDRESS: 'Agregar nueva dirección',
  EDIT_ADDRESS: 'Editar dirección',
  SUBMIT: 'Salvar',
  ADDRESS_BOOK: 'Directorio',
  MANAGE_ADDRESS: 'Administra tus direcciones',
  CITY_REQUIRED: 'La ciudad es un campo obligatorio.',
  SCHEDULE_FITTING_TITLE: 'Ajuste de horario',
  SCHEDULE_FITTING_SUBTITLE: 'Elige dónde quieres que te midan',
  HELLO: 'Hola',
  CREDITS_LEFT: 'CRÉDITOS RESTANTES',
  OF: 'DE',
  RENEWS_ON: 'SE RENUEVA',
  DURATION: 'DURACIÓN',
  BACK_TO_CALENDAR: 'Volver al calendario',
  COMPLETE_APPOINTMENT: 'Cita Completa',
  EMPLOYEE: 'Empleado',
  ITEMS_OUTFIT: 'Artículos en traje',
  LAST_FITTING: 'Última adaptación',
  PREVIOUS: 'Previa',
  NEXT: 'Próxima',
  CHOOSE_SIZE: 'Elige el tamaño o escanea automáticamente',
  DECLARE_MEASURES:
    'Por la presente declaro que las medidas son correctas',
  INSTRUCTIONS:
    'Siga las instrucciones del <a href="../../files/fitting_guide.mp4" target="_blank" rel="noopener noreferrer">Videotutorial</a> y luego consulte el <a href= "../../files/fitting_sizes_table.pdf" target="_blank" rel="noopener noreferrer">Tabla de referencia</a>',
  UNIQUE_SIZE: 'TAMAÑO ÚNICO',
  SIZE: 'TAMAÑO: ',
  REF_FITTING: 'Ref. Montaje',
  TELEPHONE: 'Teléfono',
  HOUR: 'Hora',
  SELECT: 'Seleccionar',
  CHECKOUT_NOW: 'Ordenar ahora',
  CLEAR_BAG: 'Bolsa transparente',
  YOU_WILL_LIKE: 'Te gustará',
  MONTHS: 'meses',
  ADD_BAG: 'agregar a la bolsa',
  SELECT_QUESTION_TYPE:
    'Si lo prefieres, selecciona el tipo de pregunta y consulta nuestras FAQ:',
  FITTING_LOWERCASE: 'adecuado',
  NOT_FITTED: 'NO ADECUADO',
  FITTED: 'ADECUADO',
  NA: 'NA',
  SAVE_CHANGES: 'GUARDAR CAMBIOS',
  BIRTH_DATE_OPTIONAL: 'Fecha de nacimiento (opcional)',
  FIRST_NAME: 'Primer nombre',
  LAST_NAME: 'Apellido',
  SORT_BY: 'Ordenar Por',
  CART_CREDIT: 'crédito',
  CART_CREDITS: 'créditos',
  CART_ADD_ADDRESS: 'añadir dirección',
  CART_SHIPMENT_TEXT_1: 'Máximo de',
  CART_SHIPMENT_TEXT_2: 'envíos por año.',
  CART_SHIPMENT_TEXT_3: 'envíos aún disponibles.',
  PAYMENT_RECEIVED: 'Recibido',
  PAYMENT_PENDING: 'Pendiente',
  DOCUMENTS: 'Documentos',
  NOTES: 'Notas',
  TRACKING: 'Rastreo',
  PARTIALLY: 'Parcialmente',
  COOKIES_WARNING:
    'Utilizamos cookies para mejorar su experiencia de usuario en nuestro sitio. Si continúa navegando, asumiremos que acepta nuestra',
  COOKIES_WARNING_PRIVACY: 'políticas de privacidad',
  SINGLE_SIZE: 'TAMAÑO ÚNICO',
  CUSTOMER_SUPPORT_EMAIL: 'Correo electrónico de atención al cliente:',
  CUSTOMER_SUPPORT_PHONE:
    'Para atención directa al cliente, utilice el número de teléfono que se encuentra en su área geográfica.',
  CUSTOMER_SUPPORT_EUROPE: 'Portugal y países europeos',
  CUSTOMER_SUPPORT_USA: 'EE.UU',
  CUSTOMER_SUPPORT_UNITED_KINGDOM: 'Reino Unido',
  CUSTOMER_SUPPORT_CANADA: 'Canadá',
  CUSTOMER_SUPPORT_OBS_1: 'Costo de llamada a',
  CUSTOMER_SUPPORT_0BS_2: 'nacional red fija',
  NAME: 'Nombre',
  SUBJECT_PROBLEM_EMAIL: 'Tengo un problema con mi Pedido',
  ORDER_FOUND: ' pedido encontrado',
  ORDERS_FOUND: ' pedidos encontrados',
  PRODUCT: 'Producto',
  CHOOSE_YOUR_SIZE: 'ELIGE TU TALLA',
  RECOVER_SUCCESS:
    'Se envió una nueva contraseña al correo electrónico del usuario',
  SIGN_UP: '¿Nuevo en el portal?',
  SIGN_UP_LINK: 'INSCRIBIRSE',
  RETURN_TO_LOGIN_PAGE: 'Volver a iniciar sesión',
  ONBOARDING: 'Onboarding',
  ONBOARDING_CONTENT_TITLE: 'Bienvenido al portal de uniformes',
  ONBOARDING_CONTENT_SUBTITLE:
    'Por favor complete la información requerida a continuación:',
  ACCEPT: 'Aceptar',
  GENDER: 'Género',
  DISTRICT: 'Distrito',
  ADDRESS: 'Dirección',
  DAY: 'Día',
  MONTH: 'Mes',
  YEAR: 'Año',
  JANUARY: 'Enero',
  FEBRUARY: 'Febrero',
  MARCH: 'Marzo',
  APRIL: 'Abril',
  MAY: 'Puede',
  JUNE: 'Junio',
  JULY: 'Julio',
  AUGUST: 'Agosto',
  SEPTEMBER: 'Septiembre',
  OCTOBER: 'Octubre',
  NOVEMBER: 'Noviembre',
  DECEMBER: 'Diciembre',
  ERROR: 'Error',
  ERROR_CURRENT_PASSWORD_REQUIRED:
    'La contraseña actual es un campo obligatorio',
  ERROR_PASSWORD_CONFIRMATION_DOES_NOT_MATCH:
    'La confirmación de contraseña no coincide con la contraseña',
  ERROR_PASSWORD_EQUAL_PREVIOUS:
    'La contraseña actual es igual a la contraseña anterior. Utilice uno diferente',
  ERROR_DATA_SAVED_FAILED:
    'No se pueden cambiar los datos, inténtelo de nuevo',
  SUCCESS: 'Éxito',
  SUCCESS_DATA_SAVED_SUCCESSFULLY: 'Datos actualizados exitosamente',
  AFGHANISTAN: 'Afganistán',
  ALAND_ISLANDS: 'Islas Aland',
  ALBANIA: 'Albania',
  ALGERIA: 'Argelia',
  AMERICAN_SAMOA: 'Samoa Americana',
  ANDORRA: 'Andorra',
  ANGOLA: 'Angola',
  ANGUILLA: 'Anguila',
  ANTARCTICA: 'Antártida',
  ANTIGUA_AND_BARBUDA: 'Antígua y Barbuda',
  ARGENTINA: 'Argentina',
  ARMENIA: 'Armenia',
  ARUBA: 'Aruba',
  AUSTRALIA: 'Australia',
  AUSTRIA: 'Austria',
  AZERBAIJAN: 'Azerbaiyán',
  BAHAMAS: 'Bahamas',
  BAHRAIN: 'Bahréin',
  BANGLADESH: 'Bangladesh',
  BARBADOS: 'Barbados',
  BELARUS: 'Bielorrusia',
  BELGIUM: 'Bélgica',
  BELIZE: 'Belice',
  BENIN: 'Benín',
  BERMUDA: 'Bermudas',
  BHUTAN: 'Bután',
  BOLIVIA: 'Bolivia',
  BOSNIA_AND_HERZEGOVINA: 'Bosnia y Herzegovina',
  BOTSWANA: 'Botsuana',
  BOUVET_ISLAND: 'Isla Bouvet',
  BRAZIL: 'Brasil',
  BRITISH_INDIAN_OCEAN_TERRITORY: 'Territorio Británico do Oceano Índico',
  BRITISH_VIRGIN_ISLANDS: 'Islas Vírgenes Británicas',
  BRUNEI_DARUSSALAM: 'Brunei Darussalam',
  BULGARIA: 'Bulgaria',
  BURKINA_FASO: 'Burkina Faso',
  BURUNDI: 'Burundi',
  CAMBODIA: 'Camboya',
  CAMEROON: 'Camerún',
  CANADA: 'Canadá',
  CANARY_ISLANDS: 'Islas Canarias',
  CAPE_VERDE: 'Cabo Verde',
  CAYMAN_ISLANDS: 'Islas Caimán',
  CENTRAL_AFRICAN_REPUBLIC: 'República Centroafricana',
  CHAD: 'Chad',
  CHANNEL_ISLANDS: 'Islas del Canal',
  CHILE: 'Chile',
  CHINA: 'China',
  CHRISTMAS_ISLAND: 'Isla do Navidad',
  COCOS_KEELING_ISLANDS: 'Islas Cocos (Keeling)',
  COLOMBIA: 'Colombia',
  COMOROS: 'Comoras',
  CONGO_KINSHASA: 'Congo (Kinshasa)',
  CONGO_BRAZZAVILLE: 'Congo (Brazzaville)',
  COOK_ISLANDS: 'Islas Cook',
  COSTA_RICA: 'Costa Rica',
  CROATIA: 'Croacia',
  CUBA: 'Cuba',
  CYPRUS: 'Chipre',
  CZECHIA: 'Checo',
  DENMARK: 'Dinamarca',
  DJIBOUTI: 'Yibuti',
  DOMINICA: 'Dominicana',
  DOMINICAN_REPUBLIC: 'República Dominicana',
  ECUADOR: 'Ecuador',
  EGYPT: 'Egipto',
  EL_SALVADOR: 'El Salvador',
  EQUATORIAL_GUINEA: 'Guinea Ecuatorial',
  ERITREA: 'Eritrea',
  ESTONIA: 'Estonia',
  ETHIOPIA: 'Etiopía',
  FALKLAND_ISLANDS_MALVINAS: 'Islas Malvinas (Falkland)',
  FAROE_ISLANDS: 'Islas Faroe',
  FIJI: 'Fiyi',
  FINLAND: 'Finlandia',
  FRANCE: 'Francia',
  FRENCH_GUIANA: 'Guiana Francesa',
  FRENCH_POLYNESIA: 'Polinesio Francés',
  FRENCH_SOUTHERN_TERRITORIES: 'Territorios Franceses del Sur',
  GABON: 'Gabón',
  GAMBIA: 'Gambia',
  GEORGIA: 'Georgia',
  GERMANY: 'Alemanha',
  GHANA: 'Ghana',
  GIBRALTAR: 'Gibraltar',
  GREECE: 'Grecia',
  GREENLAND: 'Groenlandia',
  GRENADA: 'Granada',
  GUADELOUPE: 'Guadalupe',
  GUAM: 'Guam',
  GUATEMALA: 'Guatemala',
  GUERNSEY: 'Guernsey',
  GUINEA: 'Guinea',
  GUINEA_BISSAU: 'Guinea-Bisáu',
  GUYANA: 'Guayana',
  HAITI: 'Haití',
  HEARD_AND_MCDONALD_ISLANDS: 'Islas Heard e Mcdonald',
  HONDURAS: 'Honduras',
  HONG_KONG: 'Hong Kong',
  HUNGARY: 'Hungría',
  ICELAND: 'Islandia',
  INDIA: 'India',
  INDONESIA: 'Indonesia',
  IRAN_ISLAMIC_REPUBLIC_OF: 'Irán (República Islámica del Irán)',
  IRAQ: 'Irak',
  IRELAND: 'Irlanda',
  ISLE_OF_MAN: 'Isla del hombre',
  ISRAEL: 'Israel',
  ITALY: 'Italia',
  IVORY_COAST: 'Costa de Marfil',
  JAMAICA: 'Jamaica',
  JAPAN: 'Japón',
  JERSEY: 'Jersey',
  JORDAN: 'Jordán',
  KAZAKHSTAN: 'Kazajstán',
  KENYA: 'Kenia',
  KIRIBATI: 'Kiribati',
  KOREA_NORTH: 'Corea del Norte',
  KOREA_SOUTH: 'Corea del Sul',
  KOSOVO: 'Kosovo',
  KUWAIT: 'Kuwait',
  KYRGYZSTAN: 'Kirguistán',
  LAO_PDR: 'República Democrática Popular Lao',
  LATVIA: 'Letonia',
  LEBANON: 'Líbano',
  LESOTHO: 'Lesoto',
  LIBERIA: 'Liberia',
  LIBYA: 'Libia',
  LIECHTENSTEIN: 'Liechtenstein',
  LITHUANIA: 'Lituania',
  LUXEMBOURG: 'Luxemburgo',
  MACAO: 'Macao',
  MADAGASCAR: 'Madagascar',
  MALAWI: 'Malaui',
  MALAYSIA: 'Malasia',
  MALDIVES: 'Maldivas',
  MALI: 'Malí',
  MALTA: 'Malta',
  MARSHALL_ISLANDS: 'Islas Marshall',
  MARTINIQUE: 'Martinica',
  MAURITANIA: 'Mauritania',
  MAURITIUS: 'Mauricio',
  MAYOTTE: 'Mayotte',
  MEXICO: 'México',
  MICRONESIA_FEDERATED_STATES_OF: 'Micronesia, Estados Federados de',
  MOLDOVA: 'Moldavia',
  MONACO: 'Mónaco',
  MONGOLIA: 'Mongolia',
  MONTENEGRO: 'Montenegro',
  MONTSERRAT: 'Montserrat',
  MOROCCO: 'Marruecos',
  MOZAMBIQUE: 'Mozambique',
  MYANMAR: 'Birmania',
  NAMIBIA: 'Namibia',
  NAURU: 'Nauru',
  NEPAL: 'Nepal',
  NETHERLANDS: 'Países Bajos',
  NETHERLANDS_ANTILLES: 'Antillas Holandesas',
  NEW_CALEDONIA: 'Nova Caledonia',
  NEW_ZEALAND: 'Nova Zelandia',
  NICARAGUA: 'Nicaragua',
  NIGER: 'Níger',
  NIGERIA: 'Nigéria',
  NIUE: 'Niue',
  NORFOLK_ISLAND: 'Isla Norfolk',
  NORTH_MACEDONIA: 'Macedonia del Norte',
  NORTHERN_MARIANA_ISLANDS: 'Islas Marianas del Norte',
  NORWAY: 'Noruega',
  OMAN: 'Omán',
  PAKISTAN: 'Pakistán',
  PALAU: 'Palaos',
  PALESTINIAN_TERRITORY: 'Territorio Palestino',
  PANAMA: 'Panamá',
  PAPUA_NEW_GUINEA: 'Papúa Nueva Guinea',
  PARAGUAY: 'Paraguay',
  PERU: 'Perú',
  PHILIPPINES: 'Filipinas',
  PITCAIRN: 'Islas Picarnia',
  POLAND: 'Polonia',
  PORTUGAL: 'Portugal',
  PUERTO_RICO: 'Puerto Rico',
  QATAR: 'Katar',
  REUNION_ISLAND: 'Isla reunión',
  ROMANIA: 'Rumania',
  RUSSIAN_FEDERATION: 'Federaçión Rusa',
  RWANDA: 'Ruanda',
  SAINT_BARTHOLOMEW: 'San Bartolomé',
  SAINT_HELENA: 'Santa elena',
  SAINT_KITTS_AND_NEVIS: 'San Cristóbal y Nieves',
  SAINT_LUCIA: 'Santa Lucía',
  SAINT_PIERRE_AND_MIQUELON: 'San Pedro y Miquelón',
  SAINT_VINCENT_AND_GRENADINES: 'San Vicente y las Granadinas',
  SAINT_MARTIN_FRENCH_PART: 'Saint-Martin (parte francesa)',
  SAMOA: 'Samoa',
  SAN_MARINO: 'San Marino',
  SAO_TOME_AND_PRINCIPE: 'San Tome y Principe',
  SAUDI_ARABIA: 'Arabia Saudita',
  SENEGAL: 'Senegal',
  SERBIA: 'Serbia',
  SEYCHELLES: 'Seychelles',
  SIERRA_LEONE: 'Sierra Leona',
  SINGAPORE: 'Singapur',
  SLOVAKIA: 'Eslovaquia',
  SLOVENIA: 'Eslovenia',
  SOLOMON_ISLANDS: 'Islas Salomón',
  SOMALIA: 'Somalia',
  SOUTH_AFRICA: 'Sudáfrica',
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS:
    'Georgia del Sur y Islas Sandwich del Sur',
  SOUTH_SUDAN: 'Sudán del Sur',
  SPAIN: 'España',
  SRI_LANKA: 'Sri Lanka',
  SUDAN: 'Sudán',
  SURINAME: 'Surinam',
  SVALBARD_AND_JAN_MAYEN_ISLANDS: 'Islas Svalbard y Jan Mayen',
  SWAZILAND: 'Suazilandia',
  SWEDEN: 'Suecia',
  SWITZERLAND: 'Suiza',
  SYRIAN_ARAB_REPUBLIC_SYRIA: 'República Árabe Siria (Siria)',
  TAIWAN_REPUBLIC_OF_CHINA: 'Taiwán, República de China',
  TAJIKISTAN: 'Tayikistán',
  TANZANIA_UNITED_REPUBLIC_OF: 'Tanzania, República Unida de',
  THAILAND: 'Tailandia',
  TIMOR_LESTE: 'Timor Oriental',
  TOGO: 'Togo',
  TOKELAU: 'Tokelau',
  TONGA: 'Tonga',
  TRINIDAD_AND_TOBAGO: 'Trinidad y Tobago',
  TUNISIA: 'Túnez',
  TURKEY: 'Turquía',
  TURKMENISTAN: 'Turkmenistán',
  TURKS_AND_CAICOS_ISLANDS: 'Islas Turcas y Caicos',
  TUVALU: 'Tuvalu',
  UGANDA: 'Uganda',
  UKRAINE: 'Ucrania',
  UNITED_ARAB_EMIRATES: 'Emiratos Árabes Unidos',
  UNITED_KINGDOM: 'Reino Unido',
  UNITED_STATES_OF_AMERICA: 'Estados Unidos de América',
  URUGUAY: 'Uruguay',
  US_MINOR_OUTLYING_ISLANDS: 'Islas Menores alejadas de EE. UU.',
  UZBEKISTAN: 'Uzbekistán',
  VANUATU: 'Vanuatu',
  VATICAN_CITY: 'Cuidad del Vaticano',
  VENEZUELA_BOLIVARIAN_REPUBLIC: 'Venezuela (República Bolivariana)',
  VIETNAM: 'Vietnam',
  VIRGIN_ISLANDS_US: 'Islas Vírgenes, Estados Unidos',
  WALLIS_AND_FUTUNA_ISLANDS: 'Islas Wallis y Futuna',
  WESTERN_SAHARA: 'Sahara Occidental',
  YEMEN: 'Yemen',
  ZAMBIA: 'Zambia',
  ZIMBABWE: 'Zimbabue',
  SUCCESS_ADDRESS_SAVED_SUCCESSFULLY: 'Dirección guardada con éxito',
  ORDER_REQUEST_WAS_COMPLETED: 'Su pedido se ha completado con éxito',
  ORDER_REQUEST_WAS_RECEIVED: 'Se recibió su solicitud de pedido',
  ORDER_NUMBER: 'Número de orden',
  ORDER_COMPLETED_DETAILS_1:
    'Un correo electrónico con los detalles de su pedido ha sido enviado a la dirección de correo electrónico que usted ha proporcionado.',
  ORDER_COMPLETED_DETAILS_2: 'Por favor, guárdelo para sus archivos.',
  ORDER_COMPLETED_DETAILS_3:
    'Puede visitar la página Mis Ordenes en cualquier momento para comprobar el estado de su pedido.',
  ORDER_RECEIVED_DETAILS_1:
    'Los detalles del pedido han sido enviados a la dirección de correo electrónico proporcionada.',
  ORDER_RECEIVED_DETAILS_2:
    'Ahora, tenga la amabilidad de esperar la aprobación de su empresa.',
  ORDER_RECEIVED_DETAILS_3:
    'Puede consultar el progreso en cualquier momento visitando la sección "Mis pedidos".',
  CONTINUE_SHOPPING: 'Continuar comprando',
  BUTTON_MY_ORDERS: 'Mis ordenes',
  THANK_YOU: 'Gracias',
  FITTING_SCHEDULE_NON_EXISTANT:
    'Lamentablemente, no hay espacios disponibles para programar su sesión de adaptación. Por favor, póngase en contacto con su gerente.',
  PROCEED_TO_MEASURES: 'Proceda a las medidas',
  LAYOUT_VIEW: 'Vista de diseño',
  IMAGE_VIEW: 'Vista de imagen',
  CHECK_TERMS_SERVICE: 'Debes declarar que las medidas son correctas',
  REJECT: 'Rechazar',
  PRIVACY_POLICY_CHECK:
    'Declaro que he leído y acepto la Política de Privacidad de SKYPRO',
  PRIVACY_POLICY_CHECK_UNVALID:
    'Debe aceptar la Política de Privacidad de SKYPRO para poder utilizar este portal'
}
