import client from '../config/AxiosConfig'

export const GetUserInfo = async () => client.get('/EmployeeUser/info')

export const GetRenewalDate = async () =>
  client.get('/EmployeeUser/credits/status')

export const GetEmployeeInfo = async () =>
  client.get('/EmployeeUser/employee/info')

export const GetUserConfig = async (data) =>
  client.post(`/EmployeeUser/configuration?name=${data}`)

export const PutUserPrivacyPolicy = async (data) =>
  client.put('EmployeeUser/configuration/rgpd', data)
