import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons'
import {Row, Col} from 'antd'
import styled from 'styled-components'

import {
  PageBackground,
  PrimaryTextColor
} from '../../../styles/_colors'
import {device} from '../../../styles/_responsive'
import {MediumFontWeight, SemiBoldFontWeight} from '../../../styles/_texts'

export const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: transparent;
  border-spacing: 0;
`

export const TableBody = styled.tbody``

export const TableRow = styled.tr``

export const TableData = styled.td``

export const Wrapper = styled.div``

export const CustomRow = styled(Row)`
  font-size: 13px;
  margin-right: -15px;
  margin-left: -15px;
  &:before {
    display: table;
    content: ' ';
  }
  &:after {
    clear: both;
    display: table;
    content: ' ';
  }
`

export const CustomCol = styled(Col)`
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  &:nth-of-type(2) {
    @media ${device.laptop} {
      margin: 20px 0px !important;
    }
  }
`

export const InputContainer = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
`

export const EmailIcon = styled.img`
  width: 21px;
  bottom: 13px;
  position: absolute;
  left: 25px;
  vertical-align: middle;
  border: 0;
`

export const LockIcon = styled.img`
  width: 16px;
  bottom: 10px;
  position: absolute;
  left: 25px;
  vertical-align: middle;
  border: 0;
`

export const IconContainer = styled.span`
  position: absolute;
  right: 20px;
  top: 13px;
  color: gray;
`

export const LoginButton = styled.button`
  border: none;
  width: 100%;
  min-width: 100px;
  display: inline-block;
  border-radius: 30px;
  font-family: 'Assistant';
  font-weight: ${SemiBoldFontWeight};
  text-align: center;
  text-transform: uppercase;
  padding: 10px 40px;
  font-size: 13px;
  font-stretch: normal;
  color: ${PageBackground};
  letter-spacing: 0.8px;
  user-select: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const EyeInvisible = styled(EyeInvisibleOutlined)`
  cursor: pointer;
  font-weight: ${MediumFontWeight};
`

export const EyeVisible = styled(EyeOutlined)`
  cursor: pointer;
  font-weight: ${MediumFontWeight};
`

export const RowCheckbox = styled(Row)`
  font-size: 13px;
  padding-left: 20px;
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: -15px;
  margin-left: -15px;
`

export const CheckboxCol = styled(Col)`
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  @media (min-width: 992px) {
    width: 33.33333333%;
    float: left;
  }
`

export const RememberMeCol = styled(Col)`
  font-size: 13px;
  padding-left: 20px;
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: -15px;
  margin-left: -15px;
  margin: auto;
`

export const RecoverSmall = styled.small`
  font-size: 85%;
`

export const RecoverPassword = styled.a`
  color: ${PrimaryTextColor};
  font-family: 'Assistant';
  font-weight: regular;
  font-size: 14px;
  text-decoration: none;
  padding-right: 14px;
  float: right;
  padding-top: 4px;
  text-decoration: underline;

  &:hover {
    color: ${PrimaryTextColor};
  }

  @media ${device.laptopS} {
    right: 0;
  }
`
