import React, {useState} from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import {CreateAccountInput} from './InputStyles'
import {MaterialLabel} from './MaterialStyles'

const CreateAccInput = ({
  textType,
  placeholder,
  readOnly,
  disabled,
  input,
  meta: {invalid, submitFailed},
  label,
  tag,
  onClick,
  requiredError,
  translate
}) => {
  const [filled, setFilled] = useState(input && input.value !== '')
  const showError = invalid && submitFailed

  return (
    <>
      <MaterialLabel
        tag={tag}
        filled={filled}
        showError={showError || requiredError}
      >
        {label}
      </MaterialLabel>
      <CreateAccountInput
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        type={textType}
        value={input.value}
        onChange={input.onChange}
        showError={showError || requiredError}
        onBlur={() => setFilled(input && input.value !== '')}
        onFocus={() => setFilled(true)}
        tag={tag}
        onClick={onClick}
      />
    </>
  )
}
CreateAccInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  textType: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  tag: PropTypes.string,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  requiredError: PropTypes.bool
}

CreateAccInput.defaultProps = {
  placeholder: '',
  label: '',
  tag: '',
  onClick: () => {},
  readOnly: false,
  requiredError: false
}

export default withLocalize(CreateAccInput)
