import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {device} from '../../styles/_responsive'

export const HeaderLinks = styled.ul`
  width: calc(100% - 215px);
  position: relative;
  margin: 0 auto;
  text-align: left;

  @media ${device.laptopSemi} {
    display: none;
  }
`

export const ListOption = styled.li`
  display: inline-block;
  width: auto;
`

export const HeaderLink = styled(Link)`
  line-height: normal;
  white-space: nowrap;
  display: block;
  padding: 10px 15px;
  font-size: 13px;
`

export const LangLink = styled(HeaderLink)`
  padding-left: 0;
  padding-right: 0;
  margin: 0 16px 0 0;
  text-transform: uppercase;
`

export const HeaderLang = styled.form`
  flex: 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const LangLinks = styled(HeaderLinks)`
  flex: 3;
  justify-content: flex-end;
  margin-right: 16px;
  padding: 0;
`
