import React, {useRef, useState, useEffect} from 'react'

import {Carousel} from 'primereact/carousel'
import {PropTypes} from 'prop-types'
import {Translate} from 'react-localize-redux'

import ArrowLeft from '../../../assets/arrow_left.svg'
import ArrowRight from '../../../assets/arrow_right.svg'
import LocaleString from '../../../infra/constants/LocaleString'
import {
  EachCenterDiv,
  FittingCenter,
  FittingCenterWrapper,
  SelectButton,
  SliderDiv,
  TimeSlotDiv,
  HourWrapper,
  FittingHeader
} from '../../../pages/fitting/FittingStyle'
import {
  SliderBox,
  ArrowsImage,
  FittingDate,
  FittingContainer,
  FittingWrapper
} from './BaseSliderStyles'

const FittingSlider = ({
  user,
  selected,
  setSelected,
  slides,
  slidesOrg,
  hours,
  setHours,
  selectedDay,
  setSelectedDay,
  countryList
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const carousel = useRef()

  function next() {
    carousel.next()
  }

  function previous() {
    carousel.prev()
  }

  const changeSelected = (ind) => {
    setSelected(ind)
  }

  const changeSelectedDay = (ind) => {
    setSelectedDay(ind)
    setHours(false)
  }

  const calculateSlide = () => {
    const ind = slides.findIndex((x) => x.fittingScheduleId === selected)

    if (ind - 3 < 0) {
      return 0
    }

    return ind - 3
  }

  const getDateDay = (number) => {
    switch (number) {
      case 0:
        return 'Sun'
      case 1:
        return 'Mon'
      case 2:
        return 'Tue'
      case 3:
        return 'Wed'
      case 4:
        return 'Thu'
      case 5:
        return 'Fri'
      case 6:
        return 'Sat'
      default:
        return ''
    }
  }

  const getMonthName = (month) => {
    const date = new Date()
    date.setMonth(month)
    const monthName = date
      .toLocaleString(LocaleString[user.languageId - 1].value, {
        month: 'short'
      })
      .replace('.', '')
    return monthName
  }

  const formatDate = (obj) => {
    const date = new Date(obj?.slot)
    const month = date.getMonth()
    const monthName = getMonthName(month)
    const dayOfWeek = getDateDay(date.getDay())
    const dayOfMonth = date.getDate()

    const formattedDate = {
      dayOfWeek,
      dayOfMonth,
      monthName
    }

    return formattedDate
  }

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 4,
      numScroll: 1
    },
    {
      breakpoint: '1199px',
      numVisible: 4,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '678px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1
    }
  ]

  const productTemplate = (item) => (
    <FittingWrapper
      $selectedDay={item.find(
        (res) => res.slot.split('T')[0] === selectedDay
      )}
      key={item.id}
      onClick={() => changeSelectedDay(item[0].slot.split('T')[0])}
    >
      <FittingDate style={{width: 'unset'}}>
        {formatDate(item[0]).dayOfWeek} {formatDate(item[0]).dayOfMonth}{' '}
        {formatDate(item[0]).monthName}
      </FittingDate>
      <FittingDate />
    </FittingWrapper>
  )

  return (
    <>
      <SliderDiv>
        <FittingContainer>
          <Carousel
            nextIcon={() => (
              <ArrowsImage
                src={ArrowRight}
                alt={<Translate id='PREV_IMG' />}
                $left
              />
            )}
            prevIcon={() => (
              <ArrowsImage
                src={ArrowLeft}
                alt={<Translate id='PREV_IMG' />}
                $right
              />
            )}
            value={slidesOrg}
            numVisible={4}
            numScroll={1}
            responsiveOptions={responsiveOptions}
            itemTemplate={productTemplate}
            showIndicators={false}
          />
        </FittingContainer>
      </SliderDiv>
      <FittingCenter>
        <FittingCenterWrapper>
          {selectedDay && (
            <>
              <FittingHeader>
                <EachCenterDiv>
                  {
                    slides.find(
                      (x) => x.slot.split('T')[0] === selectedDay
                    )?.contactDetail?.name
                  }
                </EachCenterDiv>
                <EachCenterDiv>
                  {
                    slides.find(
                      (x) => x.slot.split('T')[0] === selectedDay
                    )?.contactDetail?.addressLine1
                  }
                  <br />
                  {
                    slides.find(
                      (x) => x.slot.split('T')[0] === selectedDay
                    )?.contactDetail?.streetNumber
                  }
                  <br />
                  {
                    slides.find(
                      (x) => x.slot.split('T')[0] === selectedDay
                    )?.contactDetail?.zipCode
                  }{' '}
                  {
                    slides.find(
                      (x) => x.slot.split('T')[0] === selectedDay
                    )?.contactDetail?.region
                  }
                  ,{' '}
                  {
                    countryList.find(
                      (x) =>
                        x.CountryId ===
                        slides.find(
                          (y) => y?.fittingScheduleId === selected
                        )?.contactDetail?.countryId
                    )?.Name
                  }
                </EachCenterDiv>
                <EachCenterDiv>
                  {
                    slides.find(
                      (x) => x.slot.split('T')[0] === selectedDay
                    )?.contactDetail?.telephone
                  }
                </EachCenterDiv>
                <EachCenterDiv>
                  <SelectButton onClick={() => setHours(true)}>
                    Select
                  </SelectButton>
                </EachCenterDiv>
              </FittingHeader>
              {hours && (
                <HourWrapper hours={hours}>
                  {slidesOrg.map((org) =>
                    org
                      .filter((x) => x.slot.split('T')[0] === selectedDay)
                      .map((item, index) => (
                        <TimeSlotDiv
                          $selected={item.fittingScheduleId === selected}
                          key={index}
                          onClick={() =>
                            changeSelected(item.fittingScheduleId)
                          }
                        >
                          {item.slot.split('T')[1]}
                        </TimeSlotDiv>
                      ))
                  )}
                </HourWrapper>
              )}
            </>
          )}
        </FittingCenterWrapper>
      </FittingCenter>
    </>
  )
}

FittingSlider.propTypes = {
  slides: PropTypes.array,
  selected: PropTypes.number,
  setSelected: PropTypes.func.isRequired
}

FittingSlider.defaultProps = {
  slides: [],
  selected: undefined
}

export default FittingSlider
