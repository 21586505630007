import React from 'react'

import {PropTypes} from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {Link} from 'react-router-dom'

import {FittingPending, FittingPendingContainer} from './ProductFormStyles'

const FittingPendingMessage = ({translate}) => {
  const managerLink = (
    <Link to='/support'>{translate('CONTACT_AIRLINE_MANAGER')}</Link>
  )
  const scheduleLink = (
    <Link to='/scheduleFitting'>{translate('HERE')}</Link>
  )

  return (
    <FittingPendingContainer>
      <FittingPending>
        {translate('FITTING_PENDING_MESSAGE', {
          managerLink,
          scheduleLink
        })}
      </FittingPending>
    </FittingPendingContainer>
  )
}

FittingPendingMessage.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(FittingPendingMessage)
