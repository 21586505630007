import client from '../config/AxiosConfig'

export const GetAllAddresses = async () =>
  client.get('/EmployeeContact/addresses')

export const GetAllCheckoutAddresses = async () =>
  client.get('/EmployeeContact/checkout')

export const CreateNewAddress = async (data) =>
  client.post('/EmployeeContact/addresses', data)

export const GetAllCountries = async () =>
  client.get('/EmployeeContact/getAllCountries')

export const UpdateAddress = async (id, data) =>
  client.put(`/EmployeeContact/addresses/${id}`, data)

export const DeleteAddress = async (id) =>
  client.delete(`/EmployeeContact/addresses/${id}`)

export const GetFilteredCountries = async (id) =>
  client.get(`/EmployeeContact/getFilteredCountries/${id}`)