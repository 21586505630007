import {Col} from 'antd'
import styled from 'styled-components'

import {
  PrimaryTextColor,
  TertiaryColor
} from '../../styles/_colors'
import {device} from '../../styles/_responsive'
import {
  AccountSettingsSubtitle,
  Assistant500,
  AddressSubTitle
} from '../../styles/_texts'

export const Container = styled.section`
  padding-left: 8.85%;
  background-color: ${TertiaryColor};
  width: 66.66%;
  padding-right: 5%;
  padding-top: 80px;
  padding-bottom: 60px;
  @media ${device.laptopL} {
    width: 60%;
  }

  @media ${device.tablet} {
    padding-top: 40px;
    padding-bottom: 30px;
  }
`

export const Wrapper = styled.div`
  text-align: left;
  font-size: 0;
  padding: 0;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;

  @media ${device.tablet} {
    padding: 0 20px;
  }
`

export const DetailsHeader = styled.h1`
  font-family: ${Assistant500};
  font-weight: bold;
  letter-spacing: 0px;
  font-size: ${AccountSettingsSubtitle};
  margin-bottom: 36px;
  text-transform: uppercase;
`

export const DetailsFirstList = styled.ul`
  display: inline-block;
  max-width: 445px;
  vertical-align: top;
  margin-right: 11%;
  padding-inline-start: 0;

  @media ${device.laptopL} {
    max-width: 350px;
    margin-right: 3%;
  }

  &:last-child {
    margin-right: 0;
  }
`

export const Detail = styled(Col)`
  margin: 0 !important;
  padding: 0 !important;
`

export const DetailTitle = styled.p`
  font-family: 'Campton';
  font-style: normal;
  letter-spacing: 0px;
  font-size: ${AddressSubTitle};
  color: ${PrimaryTextColor};
  line-height: 2.07;
  text-transform: uppercase;
  margin: 0px;
`

export const DetailSpan = styled.span`
  font-family: 'Assistant';
  font-style: normal;
  letter-spacing: 0px;
  font-size: ${AddressSubTitle};
  color: ${PrimaryTextColor};
  line-height: 2.07;
  text-transform: uppercase;
  margin-left: 5px;
`
