import {Row} from 'antd'
import styled from 'styled-components'

import {
  Grey,
  Lightgrey,
  PrimaryColor,
  PrimaryTextColor,
  TertiaryColor
} from '../../../styles/_colors'
import {device} from '../../../styles/_responsive'
import {
  AddressTextTitle,
  Assistant500,
  PanelTopSubTitle,
  PanelTopTitle
} from '../../../styles/_texts'
import {EditButton} from '../../../styles/BasicStyles'

export const Content = styled.div``

export const AddressBook = styled.section`
  width: 100%;
  text-align: center;
  font-size: 0;
  height: 100%;
  min-height: 750px;
  display: block;
`

export const AddressesWrapper = styled.div`
  max-width: 100%;
  display: table;
  padding: 0;
  min-height: 750px;
  width: 100%;
  margin: 0 auto;

  @media ${device.tablet} {
    text-align: center;
    display: inline-block;
  }
`

export const HalfPage = styled.aside`
  display: table-cell;
  width: 50%;
  height: 100%;
  position: relative;
  vertical-align: top;

  &:first-of-type {
    width: 55%;
    padding: 40px 0 40px;
    background-color: ${TertiaryColor};

    @media ${device.laptop} {
      padding: 40px 15px;
    }
    @media ${device.laptop} {
      width: 100%;
      display: inline-block;
      position: relative;
    }
    @media ${device.tabletS} {
      padding: 24px 15px 34px;
    }
  }
  &:nth-of-type(2) {
    background-color: ${Lightgrey};
    padding: 40px 15px 40px;
    width: 45%;

    @media ${device.laptop} {
      padding: 35px 15px;
      width: 100%;
      display: inline-block;
    }
    @media ${device.laptop} {
      padding: 40px 0%;
    }
  }
`

export const AddressForm = styled.form`
  position: relative;
  max-width: 525px;
  text-align: center;
  display: inline-block;
  width: 100%;
  font-size: 0;
`

export const RowContainer = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    width: calc(100% - 20px);
  }
`

export const Legend = styled.legend`
  @media ${device.tablet} {
    min-width: 50%;
    margin: 20px 0;
  }

  font-family: ${Assistant500};
  font-size: 20px;
  letter-spacing: normal;
  text-align: center;
  color: ${PrimaryTextColor};
  margin-bottom: 0;
  min-width: 50%;
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  line-height: inherit;
  white-space: normal;
`

export const FormRow = styled(Row)`
  display: inline-block;
  text-align: left;
  position: relative;
  width: 100%;
  font-size: 0;
  vertical-align: top;
  margin-top: 1rem;
  margin-right: -15px;
  margin-left: -15px;
`

export const RowSpan = styled.span``

export const ErrorSpan = styled.span`
  color: ${PrimaryColor};
  display: none;
  font-family: ${Assistant500};
  font-size: ${PanelTopSubTitle};
  line-height: 18px;
  position: absolute;
  bottom: -19px;
  right: 7px;
`

export const RowHalf = styled.div`
  width: calc(50% - 10px);
  display: inline-block;
  position: relative;
  text-align: left;
  font-size: 0;
  vertical-align: top;
  margin-top: 1rem;

  &:first-of-type {
    margin-right: 20px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`

export const SaveButton = styled(EditButton)`
  cursor: pointer;
`

export const ExistingAddress = styled.article`
  height: auto;
  width: 100%;
  background-color: ${TertiaryColor};
  padding: 28px 8.8%;
  font-family: 'Assistant';
  color: ${PrimaryTextColor};
  font-size: ${AddressTextTitle};
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;

  @media ${device.mobileM} {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  @media ${device.tabletS} {
    padding: 28px 15px;
  }
`

export const AddressInfo = styled.div`
  max-width: 55%;
  display: inline-block;
`

export const AddressName = styled.h2`
  font-weight: bold;
  line-height: 30px;
  font-size: ${AddressTextTitle};
  margin-bottom: 1.5rem;
  margin-top: 0;
`

export const AddressDeliveryName = styled.span`
  display: block;
  position: relative;
  line-height: 20px;
  margin-bottom: 10px;
  font-size: ${AddressTextTitle};
`

export const AddressDetails = styled.p`
  display: block;
  position: relative;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: ${AddressTextTitle};
`

export const SectionHeading = styled.section`
  margin-bottom: 0;
  margin-top: 0px;
  height: 169px;
  padding: 115px 15px 40px;
  min-height: 100px;
  width: 100%;
  display: table;
  position: relative;
  z-index: 1;
`

export const Article = styled.article`
  position: relative;
  padding: 15px 0;
  color: ${Grey};
  display: table-cell;
  vertical-align: middle;
`

export const ArticleTitle = styled.p`
  font-family: 'Campton';
  font-weight: bold;
  font-size: ${PanelTopTitle};
  line-height: 36px;
  display: block;
  margin-bottom: 10px;
`

export const Wrapper = styled.div`
  @media ${device.tablet} {
    padding: 0 20px;
  }

  position: relative;
  max-width: 100%;
  padding: 0 50px;
  width: 100%;
  margin: 0 auto;
`

export const ArticleSpan = styled.span`
  font-family: 'Assistant';
  font-weight: normal;
  text-transform: inherit;
  font-size: ${PanelTopSubTitle};
  letter-spacing: 0;
  display: block;
`

export const RowDiv = styled.div`
  width: 100%;
  padding: 5px 25px;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-top: 1rem;
`

export const LabelForm = styled.div`
  margin: 10px 0 5px;
`

export const LabelSpace = styled.div`
  font-size: 14px;
`

export const CheckboxRow = styled.div`
  margin-top: 1.5rem;
`

export const StyledOption = styled.option`
  font-size: 14px;
`
