import React from 'react'

import {PlusOutlined} from '@ant-design/icons'
import PropTypes from 'prop-types'

import {SizesSelectInput, StyledOption} from './InputStyles'

const SizesSelect = ({
  options,
  dataKey,
  placeholder,
  allowClear,
  defaultVl,
  input,
  ...rest
}) => (
  <>
    <SizesSelectInput
      {...input}
      {...rest}
      defaultValue={defaultVl}
      placeholder={placeholder}
      allowClear={allowClear}
      showArrow
      showSearch={false}
      suffixIcon={<PlusOutlined />}
    >
      {options.map((elem, index) => (
        <StyledOption
          key={dataKey ? elem[dataKey] : index}
          disabled={elem.disabled}
          value={elem.sizeId}
        >
          {elem.nameEu}
        </StyledOption>
      ))}
    </SizesSelectInput>
  </>
)

SizesSelect.propTypes = {
  options: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  placeholder: PropTypes.string,
  allowClear: PropTypes.bool,
  defaultVl: PropTypes.string,
  input: PropTypes.object
}

SizesSelect.defaultProps = {
  allowClear: false,
  dataKey: '',
  placeholder: '',
  defaultVl: '',
  input: {}
}

export default SizesSelect
