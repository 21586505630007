import styled from 'styled-components'

import {
  PageBackground,
  SecondaryColor,
  Grey
} from '../../shared/styles/_colors'
import {device} from '../../shared/styles/_responsive'
import {
  AccountSettingsSubtitle,
  AccountSettingsTitle,
  Campton
} from '../../shared/styles/_texts'

export const BalloonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 8rem;
  padding-left: 20px;
  padding-right: 20px;
`

export const BalloonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-width: 250px;
  height: 222px;
  text-align: center;
  border-radius: 47px;
`

export const BalloonSpan = styled.span`
  text-transform: uppercase;
  font-family: ${Campton};
  font-size: 50px;
  line-height: 0.8;
`

export const BalloonTip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -24.5px;
  padding: 5px;
  background-color: #f8f8f8;
`

export const BalloonTipLeft = styled.div`
  width: 15px;
  height: 50px;
  text-align: center;
  border-radius: 6px;
  transform: rotate(-45deg);
`

export const BalloonTipRight = styled.div`
  width: 15px;
  height: 50px;
  text-align: center;
  border-radius: 6px;
  transform: rotate(45deg);
  margin-left: 10px;
`

export const Title = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${PageBackground};
  font-family: ${Campton};
  font-size: ${AccountSettingsTitle};
  text-transform: uppercase;
  margin-top: 4rem;
  margin-bottom: 2rem;
`

export const Subtitle = styled.div`
  text-align: center;
  background-color: ${PageBackground};
  color: ${SecondaryColor};
  font-size: ${AccountSettingsSubtitle};
  font-weight: bold;
  margin-top: 1.5rem;

  &::placeholder {
    color: ${Grey};
  }
`

export const OrderNumber = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${PageBackground};
  font-family: ${Campton};
  font-size: ${AccountSettingsTitle};
  margin-top: 1rem;
  margin-bottom: 2rem;
`

export const Description = styled.div`
  text-align: center;
  background-color: ${PageBackground};
  color: ${SecondaryColor};
  font-size: ${AccountSettingsSubtitle};
  margin-top: 1.5rem;

  &::placeholder {
    color: ${Grey};
  }

  @media ${device.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const WrapperNew = styled.div`
  width: 100%;
  height: auto;
  margin-top: 3rem;
  margin-bottom: 56px;
  display: flex;
  justify-content: center;
  background-color: inherit;
  gap: 8px;

  @media ${device.tablet} {
    flex-flow: column;
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const SpaceButon = styled.div`
  width: 10px;

  @media ${device.tablet} {
    width: 0;
  }
`
