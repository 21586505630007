import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {ReactComponent as SkyproLogo} from '../../../assets/my_skypro_portal_white.svg'
import {SecondaryColor} from '../../styles/_colors'
import {device} from '../../styles/_responsive'
import {BodyText} from '../../styles/_texts'

export const HeaderContainer = styled.header`
  padding: 25px 0;
  position: fixed;
  width: 100%;
  top: 0;
  height: 72px;
  z-index: 1000;
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: 'Campton';
  font-size: ${BodyText};
  padding: 0 50px;

  @media ${device.laptop} {
    padding: 0 20px;
    height: 100%;
  }

  @media ${device.tablet} {
    padding: 0 20px;
  }
`

export const HamburgerMenu = styled.div`
  /*color: ${SecondaryColor};*/
  width: 30px;
  height: 30px;
  position: relative;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  cursor: pointer;

  &:after {
    transform: none;
  }

  @media ${device.minLaptopSemi} {
    display: none;
  }
`

export const LogoContainerLink = styled(Link)`
  width: auto;
  cursor: pointer;

  @media ${device.laptop} {
    margin-bottom: 0;
    top: 0;
  }

  @media ${device.tablet} {
    margin-bottom: 0;
    top: 0;
  }
`

export const LogoImg = styled(SkyproLogo)`
  width: 85%;
  float: left;
`
