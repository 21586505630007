function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

function isTokenExpired(token) {
  const decodedToken = parseJwt(token)
  if (!decodedToken) return true

  const currentTime = Math.floor(Date.now() / 1000)
  return decodedToken.exp < currentTime
}

export default isTokenExpired
