import {Carousel} from 'antd'
import styled from 'styled-components'

import {Grey, PrimaryColor, PageBackground} from '../../styles/_colors'
import {Assistant, AssistantBold} from '../../styles/_texts'

export const SliderContainer = styled.div`
  border: 1px solid ${Grey};
  border-radius: 25px;
  width: 100%;
  display: inline-block;
  padding: 0 50px;
  position: absolute;
`

export const FittingContainer = styled(SliderContainer)`
  display: block;
  max-width: 900px;
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0 8px;
  border: 1px solid hsla(0, 0%, 71%, 0.47);
  border-radius: 25px;
  margin-bottom: 37px;
  .slick-slide,
  .slick-active {
    max-width: 194px;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
  }

  .slick-slide,
  .slick-current {
    div {
      height: 100% !important;
    }
  }

  .slick-list,
  .slick-track {
    height: 50px;
  }
`

export const SliderBox = styled(Carousel)``

export const Measure = styled.div`
  cursor: pointer;
  text-align: center;
  display: inline-block;
  border-radius: 25px;
  background-color: ${({$selected}) => ($selected ? '#000000' : null)};
  color: ${({$selected}) => ($selected ? PageBackground : Grey)};
  width: 100px;
  float: left;
  height: 100%;
  padding: 10px 0;
`

export const FittingDate = styled.span`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${Assistant};
  font-size: 15px;
  z-index: 99999999;

  &:first-of-type {
    font-family: ${AssistantBold};
  }
`

export const FittingWrapper = styled.div`
  cursor: pointer;
  text-align: center;
  background-color: ${({$selectedDay}) =>
    $selectedDay ? PrimaryColor : 'transparent'};
  color: ${({$selectedDay}) => ($selectedDay ? PageBackground : Grey)};
  border-radius: 25px;
  position: relative;
  z-index: 9999999;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100% !important;
`

export const ArrowsImage = styled.img`
  cursor: pointer;
`
