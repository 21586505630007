import React from 'react'

import PropTypes from 'prop-types'
import {Field, Form} from 'react-final-form'
import {Translate, withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router'

import FormValidator from '../../../../infra/services/validations/FormValidator'
import LoginTextInput from '../../inputs/LoginInput'
import {
  Container,
  TableBody,
  TableRow,
  TableData,
  Wrapper,
  CustomRow,
  CustomCol,
  InputContainer,
  LoginButton,
  LabelForm,
  LabelSpace
} from './OnboardingFormStyles'

const validateForm = FormValidator.make({
  firstName: 'required',
  lastName: 'required',
  address: 'required',
  number: 'required',
  postalCode: 'required',
  city: 'required',
  region: 'required',
  password: 'required',
  passwordConfirm: 'required'
})

const OnboardingForm = ({
  translate,
  configuration,
  loginResponse
}) => {
  const fieldStyles = {width: '100%', height: '42px', padding: '0 10px', borderRadius: '30px', border: '1px solid hsla(0,0%,71%,0.47)', outline: 'none'}

  return (
    <Container>
      <TableBody>
        <TableRow>
          <TableData>
            <Form
              onSubmit={loginResponse}
              initialValues={{ clientId: configuration?.b2bclientId }}
              validate={validateForm}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                  <Wrapper>
                    <CustomRow>
                      <CustomCol lg={9} span={24}>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='FIRST_NAME' />:
                          </LabelSpace>
                        </LabelForm>
                      </CustomCol>
                      <CustomCol lg={12} span={24}>
                        <InputContainer>
                          <Field component={LoginTextInput} name='firstName' textType='input' />
                        </InputContainer>
                      </CustomCol>
                      <CustomCol lg={3} span={24} />
                    </CustomRow>

                    <CustomRow>
                      <CustomCol lg={9} span={24}>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='LAST_NAME' />:
                          </LabelSpace>
                        </LabelForm>
                      </CustomCol>
                      <CustomCol lg={12} span={24}>
                        <InputContainer>
                          <Field component={LoginTextInput} name='lastName' textType='text' />
                        </InputContainer>
                      </CustomCol>
                      <CustomCol lg={3} span={24} />
                    </CustomRow>

                    <CustomRow>
                      <CustomCol lg={9} span={24}>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='FUNCTION' />
                          </LabelSpace>
                        </LabelForm>
                      </CustomCol>
                      <CustomCol lg={12} span={24}>
                        <InputContainer>
                          <Field name="workgroup" component="select" defaultValue={configuration?.workgroups[0].id} style={fieldStyles}>
                            {configuration?.workgroups.map((elem, index) => (
                              <option key={index} value={elem.id}>{elem.title}</option>
                            ))}
                          </Field>
                        </InputContainer>
                      </CustomCol>
                      <CustomCol lg={3} span={24} />
                    </CustomRow>

                    <CustomRow>
                      <CustomCol lg={9} span={24}>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='GENDER' />:
                          </LabelSpace>
                        </LabelForm>
                      </CustomCol>
                      <CustomCol lg={12} span={24}>
                        <InputContainer>
                          <Field name="gender" component="select" defaultValue={configuration?.genders[0].id} style={fieldStyles}>
                            {configuration?.genders.map((elem, index) => (
                              <option key={index} value={elem.id}>{elem.title}</option>
                            ))}
                          </Field>
                        </InputContainer>
                      </CustomCol>
                      <CustomCol lg={3} span={24} />
                    </CustomRow>

                    <CustomRow>
                      <CustomCol lg={9} span={24}>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='DISTRICT' />:
                          </LabelSpace>
                        </LabelForm>
                      </CustomCol>
                      <CustomCol lg={12} span={24}>
                        <InputContainer>
                          <Field name="district" component="select" defaultValue={configuration?.districts[0].code} style={fieldStyles}>
                            {configuration?.districts.map((elem, index) => (
                              <option key={index} value={elem.code}>{elem.title}</option>
                            ))}
                          </Field>
                        </InputContainer>
                      </CustomCol>
                      <CustomCol lg={3} span={24} />
                    </CustomRow>

                    <CustomRow>
                      <CustomCol lg={9} span={24}>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='ADDRESS' />:
                          </LabelSpace>
                        </LabelForm>
                      </CustomCol>
                      <CustomCol lg={12} span={24}>
                        <InputContainer>
                          <Field component={LoginTextInput} name='address' textType='text' />
                        </InputContainer>
                      </CustomCol>
                      <CustomCol lg={3} span={24} />
                    </CustomRow>

                    <CustomRow>
                      <CustomCol lg={9} span={24}>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='STREET_NUMBER' />:
                          </LabelSpace>
                        </LabelForm>
                      </CustomCol>
                      <CustomCol lg={12} span={24}>
                        <InputContainer>
                          <Field component={LoginTextInput} name='number' textType='number' />
                        </InputContainer>
                      </CustomCol>
                      <CustomCol lg={3} span={24} />
                    </CustomRow>

                    <CustomRow>
                      <CustomCol lg={9} span={24}>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='ZIP_CODE' />:
                          </LabelSpace>
                        </LabelForm>
                      </CustomCol>
                      <CustomCol lg={12} span={24}>
                        <InputContainer>
                          <Field component={LoginTextInput} name='postalCode' textType='text' />
                        </InputContainer>
                      </CustomCol>
                      <CustomCol lg={3} span={24} />
                    </CustomRow>

                    <CustomRow>
                      <CustomCol lg={9} span={24}>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='CITY' />:
                          </LabelSpace>
                        </LabelForm>
                      </CustomCol>
                      <CustomCol lg={12} span={24}>
                        <InputContainer>
                          <Field component={LoginTextInput} name='city' textType='text' />
                        </InputContainer>
                      </CustomCol>
                      <CustomCol lg={3} span={24} />
                    </CustomRow>

                    <CustomRow>
                      <CustomCol lg={9} span={24}>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='REGION' />:
                          </LabelSpace>
                        </LabelForm>
                      </CustomCol>
                      <CustomCol lg={12} span={24}>
                        <InputContainer>
                          <Field component={LoginTextInput} name='region' textType='text' />
                        </InputContainer>
                      </CustomCol>
                      <CustomCol lg={3} span={24} />
                    </CustomRow>

                    <CustomRow>
                      <CustomCol lg={9} span={24}>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='COUNTRY' />:
                          </LabelSpace>
                        </LabelForm>
                      </CustomCol>
                      <CustomCol lg={12} span={24}>
                        <InputContainer>
                          <Field name="country" component="select" defaultValue={configuration?.countries[0].id} style={fieldStyles}>
                            {configuration?.countries.map((elem, index) => (
                              <option key={index} value={elem.id}>{elem.title}</option>
                            ))}
                          </Field>
                        </InputContainer>
                      </CustomCol>
                      <CustomCol lg={3} span={24} />
                    </CustomRow>

                    <CustomRow>
                      <CustomCol lg={9} span={24}>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='NEW_PASSWORD' />:
                          </LabelSpace>
                        </LabelForm>
                      </CustomCol>
                      <CustomCol lg={12} span={24}>
                        <InputContainer>
                          <Field component={LoginTextInput} name='password' textType='password' />
                        </InputContainer>
                      </CustomCol>
                      <CustomCol lg={3} span={24} />
                    </CustomRow>

                    <CustomRow>
                      <CustomCol lg={9} span={24}>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='CONFIRM_PASSWORD' />:
                          </LabelSpace>
                        </LabelForm>
                      </CustomCol>
                      <CustomCol lg={12} span={24}>
                        <InputContainer>
                          <Field component={LoginTextInput} name='passwordConfirm' textType='password' />
                        </InputContainer>
                      </CustomCol>
                      <CustomCol lg={3} span={24} />
                    </CustomRow>
                    
                    <CustomRow>
                      <CustomCol lg={3} span={24} />
                      <CustomCol lg={18} span={24}>
                        <LoginButton type='submit' disabled={submitting} style={{backgroundColor: configuration?.layoutColors?.loginButtonBackground, color: configuration?.layoutColors?.loginButtonText, marginTop: '30px'}}>
                          <Translate id='ACCEPT' />
                        </LoginButton>
                      </CustomCol>
                      <CustomCol lg={3} span={24} />
                    </CustomRow>
                  </Wrapper>
                </form>
              )}
            />
          </TableData>
        </TableRow>
      </TableBody>
    </Container>
  )
}

OnboardingForm.propTypes = {
  translate: PropTypes.func.isRequired,
  configuration: PropTypes.object.isRequired,
  loginResponse: PropTypes.func.isRequired
}

export default withRouter(withLocalize(OnboardingForm))
