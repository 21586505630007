import React from 'react'

import PropTypes from 'prop-types'
import {Field, Form} from 'react-final-form'
import {Translate, withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router'

import EmailImage from '../../../../assets/email_input.png'
import FormValidator from '../../../../infra/services/validations/FormValidator'
import LoginTextInput from '../../inputs/LoginInput'
import {
  Container,
  TableBody,
  TableRow,
  TableData,
  Wrapper,
  CustomRow,
  CustomCol,
  InputContainer,
  EmailIcon,
  LoginButton
} from './RecoveryFormStyles'

const validateLogin = FormValidator.make({
  email: 'required|email'
})

const RecoveryForm = ({
  translate,
  configuration,
  setDisplay,
  setClicked,
  isClicked,
  loginResponse
}) => {
  return (
    <Container>
      <TableBody>
        <TableRow>
          <TableData>
            <Form
              onSubmit={loginResponse}
              validate={validateLogin}
              render={({handleSubmit, submitting}) => (
                <form onSubmit={handleSubmit}>
                  <Wrapper>
                    <CustomRow>
                      <CustomCol sm={24} md={configuration?.functionalities?.enabledOnboarding === true ? 24 : 8} span={24} />
                      <CustomCol sm={24} md={configuration?.functionalities?.enabledOnboarding === true ? 24 : 8} span={24}>
                        <InputContainer>
                          <Field
                            component={LoginTextInput}
                            name='email'
                            textType='email'
                            label={translate('EMAIL_LOGIN')}
                          />
                          <EmailIcon src={EmailImage} />
                        </InputContainer>
                      </CustomCol>
                      <CustomCol sm={24} md={configuration?.functionalities?.enabledOnboarding === true ? 24 : 8} span={24} />
                    </CustomRow>
                  </Wrapper>

                  <Wrapper>
                    <CustomRow>
                      <CustomCol sm={24} md={configuration?.functionalities?.enabledOnboarding === true ? 24 : 8} span={24} />
                      <CustomCol sm={24} md={configuration?.functionalities?.enabledOnboarding === true ? 24 : 8} span={24}>
                        <LoginButton type='submit' disabled={submitting}>
                          <Translate id='RECOVER' />
                        </LoginButton>
                      </CustomCol>
                      <CustomCol sm={24} md={8} span={24} />
                    </CustomRow>
                  </Wrapper>
                </form>
              )}
            />
          </TableData>
        </TableRow>
      </TableBody>
    </Container>
  )
}

RecoveryForm.propTypes = {
  translate: PropTypes.func.isRequired,
  configuration: PropTypes.object.isRequired,
  setClicked: PropTypes.func.isRequired,
  setDisplay: PropTypes.func.isRequired,
  isClicked: PropTypes.bool.isRequired,
  loginResponse: PropTypes.func.isRequired
}

export default withRouter(withLocalize(RecoveryForm))
