import React, {useState} from 'react'

import {Tooltip} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import {ImageBag} from './ButtonsStyles'

const QuickAddToBagButton = ({
  translate,
  handleFastAddClick,
  bagItem,
  user,
  unisize
}) => {
  const [isHover, setIsHover] = useState(false)

  const fittingPending =
    !user.allowOrdersOutsideFitting && !user.fittingDone && !unisize

  const buttonHoverColor = fittingPending
    ? '#dedede'
    : user.configuration?.layoutColors?.contentText

  const btnStyle = {
    transition: 'all 0.5s ease',
    border: bagItem
      ? `1px solid ${user.configuration?.layoutColors?.contentText}`
      : isHover
      ? `1px solid ${buttonHoverColor}`
      : '1px solid #dedede',
    backgroundColor: bagItem
      ? user.configuration?.layoutColors?.contentText
      : isHover
      ? buttonHoverColor
      : '#dedede',
    cursor: fittingPending ? 'not-allowed' : 'pointer'
  }

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const handleClick = () => {
    if (!fittingPending) handleFastAddClick()
  }

  return (
    <Tooltip
      title={
        fittingPending
          ? translate('FITTING_PENDING')
          : translate('ADD_BAG')
      }
      mouseEnterDelay={0.3}
      placement='left'
    >
      <ImageBag
        alt='BAG LOGO'
        onClick={handleClick}
        style={btnStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </Tooltip>
  )
}

QuickAddToBagButton.propTypes = {
  translate: PropTypes.func.isRequired,
  handleFastAddClick: PropTypes.func.isRequired,
  bagItem: PropTypes.object,
  user: PropTypes.object.isRequired
}

QuickAddToBagButton.defaultProps = {
  bagItem: undefined
}

export default withLocalize(QuickAddToBagButton)
