import styled, {createGlobalStyle} from 'styled-components'

import {
  Grey,
  PrimaryColor,
  PrimaryTextColor,
  TertiaryColor
} from './_colors'
import {
  Campton,
  AddressSubTitle,
  Assistant300,
  BodyText,
  BoldFontWeight,
  BookFontWeight,
  H1Text,
  LabelText,
  SemiBoldFontWeight,
  Subtitle2Text,
  TabsText,
  OverlineText
} from './_texts'

export const BasicStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    height: auto !important;
    width: auto !important;

  }
  html {
    scroll-behavior: smooth;
 
  } 

  .anticon-loading{
    display: none;
  }

  .burger-menu {
    display: inline-block;
    cursor: pointer;
    z-index: 1;
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }

  .p-carousel-prev {
    padding-right: 20px;
  }

  .p-carousel-next {
    padding-left: 20px;
  }
`

export const PageContainer = styled.div`
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const PageLoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BaseTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: ${H1Text};
`

export const Margin = styled.div`
  height: ${({size}) => size || 0}px;
`

export const ButtonPill = styled.button`
  display: inline-block;
  font-size: ${AddressSubTitle};
  text-transform: uppercase;
  border-radius: 2em;
  padding: 0.8rem 2rem;
  min-width: 11.625rem;
  max-width: 11.625rem;
  letter-spacing: 0.8px;
  transition: all 0.25s ease-in-out;
  color: ${TertiaryColor};
  text-align: center;
  font-weight: normal;
  vertical-align: middle;
  user-select: none;
  line-height: 1.5;
  font-family: 'Assistant';
  border: 1px solid ${PrimaryColor};
`

export const EditButton = styled(ButtonPill)`
  margin-top: 0;
  background-color: transparent;
  color: ${PrimaryColor};
  border-color: ${PrimaryColor};
  max-width: 100%;
  min-width: auto;
  width: auto;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${PrimaryColor};
    color: ${TertiaryColor};
  }
`

const setStyleManagement = ({
  $top,
  $bottom,
  $left,
  $right,
  $color,
  $opacity
}) => {
  let applyValues = ''
  if ($top) {
    applyValues = applyValues.concat(`margin-top: ${$top}px;`)
  }
  if ($bottom) {
    applyValues = applyValues.concat(`margin-bottom: ${$bottom}px;`)
  }
  if ($left) {
    applyValues = applyValues.concat(`margin-left: ${$left}px;`)
  }
  if ($right) {
    applyValues = applyValues.concat(`margin-right: ${$right}px;`)
  }
  if ($color) {
    applyValues = applyValues.concat(`color: ${$color};`)
  }
  if ($opacity) {
    applyValues = applyValues.concat(`opacity: ${$opacity};`)
  }
  return applyValues
}

export const PageForm = styled.form`
  margin: ${({$margin}) => $margin || '0'};
  width: 100%;
  display: inline-block;
`

export const TitleH3 = styled.h3`
  font-family: 'Campton';
  color: ${PrimaryTextColor};
  font-size: ${TabsText};
  font-weight: ${BoldFontWeight};
  opacity: ${({$op}) => ($op ? 0.5 : 1)};
  margin: 0px;
  ${(props) => setStyleManagement(props)};
`

export const BodyH3 = styled.div`
  color: ${({$color}) => $color || Grey};
  font-size: ${({$size}) => $size || OverlineText};
  font-family: ${Campton};
  font-weight: ${BookFontWeight};
  opacity: ${({$op}) => ($op ? 0.5 : 1)};
  margin: 0px;
  ${(props) => setStyleManagement(props)};
  text-transform: uppercase;
`

export const BodyH5 = styled.div`
  color: ${({$op}) => $op || PrimaryTextColor};
  font-size: ${({$size}) => $size || BodyText};
  font-weight: ${Assistant300};
  margin: 0px;
  ${(props) => setStyleManagement(props)};
`

export const ContentContainer = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  /* padding: 30px 0px 75px 0px; */
  top: 73px;
`

export const TitleH5 = styled.h5`
  font-family: 'Campton';
  color: ${PrimaryTextColor};
  font-size: ${LabelText};
  font-weight: ${SemiBoldFontWeight};
  margin: 0px;
  opacity: ${({$op}) => ($op ? 0.5 : 1)};
  ${(props) => setStyleManagement(props)};
`

export const TitleH6 = styled.h6`
  font-family: 'Campton';
  color: ${PrimaryTextColor};
  font-size: ${Subtitle2Text};
  font-weight: ${SemiBoldFontWeight};
  margin: 0px;
  ${(props) => setStyleManagement(props)};
`
