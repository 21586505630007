import React from 'react'

import {PropTypes} from 'prop-types'
import {Translate} from 'react-localize-redux'

import PageNotFound from '../../assets/page_not_found.svg'
import BaseButton from '../../shared/components/buttons/BaseButton'
import {Margin} from '../../shared/styles/BasicStyles'
import {
  StyledNotFoundContainer,
  StyledTitle,
  StyledDescription,
  StyledNotFoundLogo,
  StyledNotFoundGroup,
  MarginBackNotFound
} from './NotFoundStyles'

const NotFound = ({history}) => (
  <StyledNotFoundContainer>
    <StyledNotFoundGroup>
      <StyledNotFoundLogo src={PageNotFound} />
      <Margin size={30} />
      <StyledTitle>
        <Translate id='OOPS_PAGE_NOT_FOUND' />
      </StyledTitle>
      <Margin size={30} />
      <StyledDescription>
        <Translate id='PAGE_NOT_FOUND_DESCRIPTION_1' /> <br />{' '}
        <Translate id='PAGE_NOT_FOUND_DESCRIPTION_2' />
      </StyledDescription>
      <Margin size={40} />
      <MarginBackNotFound>
        <BaseButton
          disabled={false}
          onClick={() => history.push('/outfit')}
          type='primary'
          style={{height: 45, width: 300, borderRadius: 100}}
        >
          <Translate id='BACK_TO_INITIAL_PAGE' />
        </BaseButton>
      </MarginBackNotFound>
    </StyledNotFoundGroup>
  </StyledNotFoundContainer>
)

NotFound.propTypes = {
  history: PropTypes.string
}

NotFound.defaultProps = {
  history: undefined
}

export default NotFound
