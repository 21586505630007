import DE from './de'
import EN from './en'
import ES from './es'
import FR from './fr'
import NL from './nl'
import PT from './pt'

export const GetAvailableLanguages = () => ['pt', 'en', 'es', 'de', 'fr', 'nl']

export const Languages = [
  {code: 1, name: 'Português', value: 'PT', abrev: 'pt', file: PT},
  {code: 2, name: 'Inglês', value: 'EN', abrev: 'en', file: EN},
  {code: 5, name: 'Espanhol', value: 'ES', abrev: 'es', file: ES},
  {code: 4, name: 'Alemão', value: 'DE', abrev: 'de', file: DE},
  {code: 3, name: 'Francês', value: 'FR', abrev: 'fr', file: FR},
  {code: 6, name: 'Holandês', value: 'NL', abrev: 'nl', file: NL}
]

export const GetTranslationDescription = (code) =>
  Languages.find((x) => x.code === code)?.name
