import {ArrowDownOutlined} from '@ant-design/icons'
import styled, {keyframes} from 'styled-components'

import {
  Lightgrey,
  PageBackground,
  PrimaryColor,
  PrimaryTextColor,
  SecondaryColor,
  TertiaryColor
} from '../../shared/styles/_colors'
import {device} from '../../shared/styles/_responsive'
import {
  Assistant500,
  AssistantBold,
  Campton,
  LabelText,
  PanelTopSubTitle,
  PanelTopTitle
} from '../../shared/styles/_texts'

export const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Lightgrey};
  background-color: ${PageBackground};

  @media (max-width: 768px) {
    border-bottom: 0px solid ${Lightgrey};
  }
`

export const VideoSidePanel = styled.div`
  max-height: 890px;
  width: 100%;
  padding-top: 25px;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid ${Lightgrey};
`
export const ItemsTag = styled.div`
  font-family: ${AssistantBold};
  font-size: ${LabelText};
  color: ${SecondaryColor};
  display: inline;
  width: 100%;
`
export const NumberItemsTag = styled.div`
  font-family: ${AssistantBold};
  font-size: ${LabelText};
  display: inline;
`
export const VideoContainer = styled.video`
  width: 100%;
  height: 100%;
  margin: auto;
  max-height: 800px;
`
export const SidePanel = styled.div`
  height: 100%;
  width: 100%;
  border-top: 1px solid ${Lightgrey};
  overflow: hidden;

  @media (max-width: 768px) {
    border-bottom: 1px solid ${Lightgrey};
    padding-bottom: 30px;
  }

  @media (min-width: 768px) {
    padding-bottom: 60px;

    /*&::-webkit-scrollbar {
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background: ${PageBackground};
    }
    ::-webkit-scrollbar-thumb {
      background: ${Lightgrey};
      border-radius: 5px;
      border-right: 3px solid ${PageBackground};
      border-left: 3px solid ${PageBackground};
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${Lightgrey};
    } */
  }
`

export const RelatedProducts = styled.div`
  background-color: ${TertiaryColor};
  height: 100%;
  max-height: 550px;
  padding-top: 50px;
`

export const Title = styled.div`
  font-family: ${Campton};
  font-size: ${PanelTopTitle};
  color: ${PrimaryColor};
`
export const SubTitle = styled.div`
  font-family: ${Campton};
  font-size: ${PanelTopSubTitle};
`
export const RelatedSidePanel = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${TertiaryColor};

  @media (min-width: 768px) {
    overflow: scroll;
    max-height: 435px;

    &::-webkit-scrollbar {
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background: ${TertiaryColor};
    }
    ::-webkit-scrollbar-thumb {
      background: ${Lightgrey};
      border-radius: 5px;
      border-right: 3px solid ${TertiaryColor};
      border-left: 3px solid ${TertiaryColor};
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${Lightgrey};
    }
  }
`

export const ScrollableIcon = styled.div`
  top: 75%;
  position: absolute;
  left: 40px;
  transform-origin: 5% 50%;
  transform: rotate(-90deg) translateY(50%);
  z-index: 1;

  @media ${device.minTablet} {
    top: 50vh;
  }
`

export const UpAndDown = keyframes`
  from {
    left: -14px;
  }
  to {
    left: -20px;
  }
`

export const ArrowDown = styled(ArrowDownOutlined)`
  display: inline-block;
  height: 18px;
  transform-origin: 5% 50%;
  transform: rotate(90deg);
  left: -20px;
  position: absolute;
  animation: ${UpAndDown} 1s infinite alternate;
`

export const ScrollSticker = styled.span`
  font-family: ${Assistant500};
  font-size: ${PanelTopSubTitle};
  line-height: 2.5;
  letter-spacing: normal;
  text-align: left;
  color: ${PrimaryTextColor};
`
