import {Link} from 'react-router-dom'
import styled from 'styled-components'

import ArrowWhite from '../../../assets/dropdown_arrow_white.png'
import {
  PageBackground,
  SecondaryColor,
  PrimaryTextColor,
  PrimaryColor
} from '../../styles/_colors'
import {BodyText, LabelText, LinkText, MenuLink} from '../../styles/_texts'

export const Container = styled.div`
  height: 100vh;
  overflow-y: auto;
  position: absolute;
  top: 73px;
  background-color: ${SecondaryColor};
  left: 0;
  right: 0;
  box-shadow: 0 2px 2px rgb(0 0 0 / 10%);
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  opacity: ${({open}) => (open ? '1' : '0')};
  transform-origin: top;
  transform: ${({open}) => (open ? 'scaleY(1)' : 'scaleY(0)')};
  transition: all 0.25s ease-in-out;
  border-bottom: 4px solid ${PrimaryColor};
  overflow: hidden;
`

export const NavMenu = styled.div`
  transition: 0.25s ease-in-out all;
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
`

export const NavLogin = styled.div`
  background-color: ${PageBackground};
  padding: 1px;
  margin-bottom: 1rem;
  font-size: ${LabelText};
  color: ${PrimaryTextColor};
  text-align: center;
  text-transform: uppercase;
  transition: 0.25s ease-in-out all;
  margin: 0;
  border: 0;
  margin: 0 -15px 16px;
  padding: 17px;
`

export const NavLoginLink = styled(Link)`
  color: ${PrimaryTextColor};
  font-size: ${LabelText};
  font-weight: bold;
  font-family: 'Campton';
`

export const LangCont = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const LangForm = styled.form`
  margin-top: 0em;
`

export const LanguageSelec = styled.select`
  color: ${PrimaryTextColor};
  background: ${ArrowWhite};
  background-repeat: no-repeat;
  background-position: 99%;
  background-size: 10px;
  margin-right: 55px;
  border: none;
  float: right;
  margin-top: -24px;
  width: 42px;
  word-wrap: normal;
  font-family: 'Campton';
  font-size: ${LinkText};
  overflow: visible;
  text-transform: uppercase;
`

export const LangOption = styled.option``

export const CurrencySelec = styled(LanguageSelec)`
  margin-right: 0;
  width: 50px;
`

export const NavMenuList = styled.ul`
  display: inline-block;
  padding: 45px 25px;
  width: 100%;
  text-align: left;
  position: relative;
  margin: 0 auto;
  list-style: none;
  vertical-align: baseline;
`

export const NavMenuListItem = styled.li`
  display: inline-block;
  width: 100%;
  margin: 0 0 20px;
  opacity: ${({open}) => (open ? '1' : '0')};
  backface-visibility: hidden;
  transform: ${({open}) =>
    open ? 'translateX(0px)' : 'translateX(20px)'};
  transition: all 0.3s;

  &:first-of-type {
    transition-delay: 0.35s;
  }

  &:nth-child(2) {
    transition-delay: 0.45s;
  }
  &:nth-child(3) {
    transition-delay: 0.55s;
  }
  &:nth-child(4) {
    transition-delay: 0.65s;
  }
  &:nth-child(5) {
    transition-delay: 0.75s;
  }
  &:nth-child(6) {
    transition-delay: 0.95s;
  }
`

export const DivFlex = styled.div`
  display: flex;
`

export const NavMenuListLink = styled(Link)`
  font-size: ${MenuLink};
  font-family: 'Assistant Bold';
  line-height: normal;
  color: ${PageBackground};
  text-decoration: none;
  white-space: nowrap;
  display: block;
  padding: 0.5rem 1rem;
  padding-right: 0;
  padding-left: 0;
  font-size: 20px;

  &:hover {
    color: ${PageBackground};
  }
`

export const NavSubMenuListLink = styled(NavMenuListLink)`
  font-size: ${BodyText};
`

export const NavLastMenuListLink = styled(NavMenuListLink)`
  font-size: ${MenuLink};
  font-weight: normal;
`

export const LanguageSelector = styled(LanguageSelec)`
  margin-right: 0;
  width: 50px;
  text-align: center;
  appearance: none;
`
