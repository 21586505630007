import client from '../config/AxiosConfig'

export const DoLogin = async (data) =>
  client.post('/EmployeeAuth/login', data)

export const DoPasswordRecovery = async (data) =>
  client.post('/EmployeeAuth/password/recovery', data)

export const DoSignUp = async (data) =>
  client.post('/EmployeeAuth/signUp', data)

export const DoOnboarding = async (data) =>
  client.post('/EmployeeAuth/onboarding', data)