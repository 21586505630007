import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {
  SecondaryColor
} from '../../../shared/styles/_colors'
import {device} from '../../../shared/styles/_responsive'
import {
  AddressTextTitle,
  AssistantBold,
  BodyText,
  Campton
} from '../../../shared/styles/_texts'

const lineheight = () => {
  let line = AddressTextTitle.substring(0, 2)
  line = Number(line) + 2
  line = `${String(line)}px`
  return line
}

/*
const nameheight = () => {
  let line = AddressTextTitle.substring(0, 2)
  line = Number(line) + 2 + 10
  line = `${String(line)}px`
  return line
}
*/

export const ImagePanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: block;
  position: relative;
  border: 1px solid #dedede;
  overflow: hidden;
`

export const ImageP = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.5s ease;
`

export const FittingProductCardPanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: block;
  padding: 0;
  position: relative;

  &:hover {
    ${ImageP} {
      transform: scale(1.1);
      transition: transform 0.5s ease;
    }
  }
`

export const BottomPanel = styled.div`
  max-width: 100%;
  max-height: 100%;
  padding-top: 20px;
`

export const Name = styled.p`
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: ${AddressTextTitle};
  font-family: ${AssistantBold};
  color: ${SecondaryColor};
  line-height: ${lineheight};
  margin-bottom: 0.5em;

  @media ${device.tablet} {
    line-height: 1.6;
  }
`

export const Size = styled.span`
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: ${BodyText};
  font-family: ${Campton};
  line-height: 2.14;
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 40px;
`

export const FittingLink = styled(Link)`
  cursor: ${({needfitting}) => (!needfitting ? 'default' : 'pointer')};
  pointer-events: ${({needfitting}) => (!needfitting ? 'none' : 'auto')};
`
