import React, {useEffect, useState} from 'react'

import {connect} from 'react-redux'
import {useParams} from 'react-router'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'

import BaseLoading from '../../../shared/components/loading/BaseLoading'
import {GetSingleProduct} from '../../../infra/requests/ProductRequests'
import AppPage from './AppPage'

const PreApp = ({products, user}) => {
  const [loading, setLoading] = useState(true)
  const [product, setProduct] = useState({})
  const [productList, setProductList] = useState([])
  const {id} = useParams()
  const [pos, setPos] = useState(
    productList.indexOf((x) => x.productId === parseInt(id, 10))
  )
  const history = useHistory()
  const employeeId = user?.userId
  const info = user

  useEffect(() => {
    const productId = parseInt(id, 10)
    const GetProduct = async () => {
      const {data, success} = await GetSingleProduct(productId)

      if (success) {
        setProduct(data)
      }
    }

    if (id) {
      GetProduct()
      setProductList(
        products.filter((x) => x?.product?.productMeasure?.length !== 0)
      )
      setPos(products?.findIndex((x) => x.productId === productId))
    }

    setLoading(false)
  }, [id, products])

  const NextProduct = async () => {
    if (pos !== productList.length - 1) {
      setProduct(productList[pos + 1])
      setPos(pos + 1)
      history.push(
        `/fitting/appointment/${productList[pos + 1]?.productId}`
      )
    }
  }

  const PrevProduct = async () => {
    if (pos !== 0) {
      setProduct(productList[pos - 1])
      setPos(pos - 1)
      history.push(
        `/fitting/appointment/${productList[pos - 1]?.productId}`
      )
    }
  }

  return loading ? <BaseLoading /> : (
    <AppPage
      product={product}
      employeeId={employeeId}
      info={info}
      setProduct={setProduct}
      id={id}
      next={NextProduct}
      prev={PrevProduct}
    />
  )
}

const mapStateToProps = (state) => ({
  products: state.products,
  user: state.user
})

export default connect(mapStateToProps)(PreApp)
