import React from 'react'

import PropTypes from 'prop-types'

import {CheckboxField} from './InputStyles'
import {MaterialCheckboxLabel} from './MaterialStyles'

const CheckboxInput = ({
  input,
  label,
  disabled,
  checked,
  style,
  checkboxType,
  onClick,
  onClickLabel,
  className,
  labelType
}) => {
  const labelClick = (e) => {
    if (onClickLabel) {
      e.preventDefault()
      e.stopPropagation()
      onClickLabel()
    }
  }

  return (
    <CheckboxField
      disabled={disabled}
      style={style}
      checked={!!(input.value || checked)}
      onChange={input.onChange}
      checkboxType={checkboxType}
      onClick={onClick}
      className={className}
      labelType={labelType}
    >
      {label ? (
        <MaterialCheckboxLabel
          filled
          clickable={onClickLabel}
          onClick={labelClick}
        >
          {label}
        </MaterialCheckboxLabel>
      ) : null}
    </CheckboxField>
  )
}

CheckboxInput.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object.isRequired,
  checkboxType: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  labelType: PropTypes.string
}

CheckboxInput.defaultProps = {
  checkboxType: 'circle',
  label: '',
  onClick: () => {},
  className: '',
  labelType: 'square'
}

export default CheckboxInput
