import styled from 'styled-components'

import {
  PageBackground,
  PrimaryColor,
  PrimaryTextColor,
  SecondaryColor,
  TertiaryColor
} from '../../shared/styles/_colors'
import {device} from '../../shared/styles/_responsive'
import {
  AccountSettingsTitle,
  Assistant500,
  Assistant600,
  AssistantBold,
  Subtitle1Text
} from '../../shared/styles/_texts'

export const BottomPanel = styled.div`
  width: 100%;
  background-color: ${PageBackground};
  margin: 50px 0 50px;
`
export const TopLine = styled.div`
  font-family: ${Assistant600};
  font-size: ${Subtitle1Text};
  margin: 0 0 10px;
`

export const ContentLine = styled.div`
  font-family: ${Assistant500};
  font-size: ${Subtitle1Text};
  margin: 0 0 50px;
  color: ${PrimaryColor};
`

export const TextSpan = styled.span`
  color: ${SecondaryColor};
`

export const TabsWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  margin-top: 14px;
`

export const EachTab = styled.div`
  border: 2px solid #aeadab;
  height: 150px;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  border-right: none;
  background-color: ${TertiaryColor};
  position: relative;

  &:first-of-type {
    border-radius: 8px 0 0 8px;
  }
  &:last-of-type {
    border-radius: 0 8px 8px 0;
    border-right: 2px solid #aeadab;
  }

  ${({active}) =>
    active &&
    `&::after {
      content: '';
      position: absolute;
      bottom: -17px;
      left: 50%;
      margin-left: -11px;
      border-width: 17px 12px 0;
      border-style: solid;
      border-color: ${TertiaryColor} transparent;
      display: block;
      width: 0;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -21px;
      left: 50%;
      margin-left: -14px;
      border-width: 21px 15px 0;
      border-style: solid;
      border-color: #aeadab transparent;
      display: block;
      width: 0;
    }`}
`

export const TabText = styled.span`
  color: ${PrimaryTextColor};
  font-family: ${Assistant600};
  font-size: ${Subtitle1Text};
  line-height: 2;
  letter-spacing: 1px;
  text-transform: capitalize;

  &:hover {
    text-decoration: underline;
  }
`

export const TabImage = styled.img`
  border-width: 0;
  height: 55px;
  margin: auto;
  display: block;
  margin-top: 27px;
`

export const TabLink = styled.a`
  text-decoration: none;
  outline: none;

  &:hover {
    ${TabText} {
      text-decoration: underline;
    }
  }
`

export const SupportListWrapper = styled.div`
  width: 100%;
  min-height: 56px;
  margin-top: 45px;
  background-color: ${TertiaryColor};
  border: 2px solid #aeadab;
  border-radius: 8px;
  overflow: auto;
  display: ${({active}) =>
    active === 'account' || active === 'fitting' || active === 'orders'
      ? 'block'
      : 'none'};
`

export const HelpList = styled.ul`
  list-style-type: disc;
  margin-top: 10px;
  margin-left: 6px;

  @media ${device.tablet} {
    margin-top: 14px;
    margin-left: 0px;
    overflow: hidden;
  }
`

export const Underline = styled.div`
  height: 7px;
  width: 100%;
  opacity: 0;
  border: 1px solid ${PrimaryColor};
  border-radius: 30px;
  background-color: ${PrimaryColor};
`

export const HelpLink = styled.a`
  position: relative;
  left: -5px;
  top: 2px;
  color: ${PrimaryTextColor};
`

export const HelpItem = styled.li`
  float: center;
  margin-left: 50px;
  font-size: ${Subtitle1Text};

  margin-top: 10px;
  margin-bottom: 10px;

  @media ${device.tablet} {
    margin-left: 10px;
    line-height: 20px;
  }

  &:hover {
    ${Underline} {
      opacity: 1;
      transition: all 0.5s ease;
    }
    ${HelpLink} {
      color: ${PrimaryTextColor};
    }
  }
`

export const AnswersWrapper = styled.div`
  width: 100%;
  margin-top: 45px;
  background-color: ${TertiaryColor};
  border: 2px solid #aeadab;
  border-radius: 8px;
  padding: 36px;
`

export const QuestionTitle = styled.span`
  font-family: 'Campton';
  font-size: ${AccountSettingsTitle};
  display: block;
  margin-bottom: 20px;
`

export const AnswerText = styled.span`
  line-height: 1.2;
  font-family: ${Assistant500};
  font-size: ${Subtitle1Text};
  white-space: pre-line;
`

export const BreakLine = styled.br``

export const Divider = styled.hr``

export const Rate = styled.div`
  display: inline-block;
  text-align: center;
`

export const RateLink = styled.a``

export const ReviewImage = styled.img`
  border-width: 0;
  height: 30px;
  margin-bottom: 10px;

  &:last-of-type {
    height: 35px;
  }
`

export const ReviewText = styled.span`
  color: ${PrimaryTextColor};
  font-family: ${Assistant500};
  font-size: ${Subtitle1Text};
`

export const Message = styled.div`
  .ant-message {
    position: absolute;
    bottom: 20px !important;
    top: unset !important;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  margin: 0 0 50px;

  @media ${device.tablet} {
    display: flow;
    padding-top: 20px;
  }
`

export const EachColumn = styled.div`
  padding: 18px 18px 0;
  text-align: center;
  line-height: 1.4;
  font-size: 14px;

  @media ${device.tablet} {
    margin-bottom: 40px;
    padding: 0;
  }
`

export const TextPhone = styled.div`
  font-family: ${AssistantBold};
`

export const LinkText = styled.a`
  color: black;

  &:hover {
    color: black;
  }
`

export const TextRegion = styled.div`
  margin: 10px 0;
  font-family: ${Assistant500};
  font-size: 12px;

  @media ${device.tablet} {
    font-size: 14px;
  }
`

export const TextObs = styled.div`
  font-family: ${AssistantBold};
  font-size: 12px;
  color: black;

  @media ${device.tablet} {
    font-size: 14px;
  }
`

export const ImageFlag = styled.div`
  margin: 0 0 14px;

  @media ${device.tablet} {
    margin: 0 0 14px;
  }
`

export const StyledFlag = styled.img`
  width: 50px;
  height: auto;
`
export const RateWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
  flex-wrap: wrap;
  align-items: center;

  @media ${device.tabletS} {
    justify-content: center;
  }
`
