import React from 'react'

import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'

import {
  BalloonTip,
  BalloonTipLeft,
  BalloonTipRight
} from '../language-container/LanguageContainerStyles'
import MobileMenus from './MobileMenus'
import {
  Container,
  NavLogin,
  NavLoginLink,
  NavMenu,
  LangCont,
  LangForm,
  NavMenuListItem,
  DivFlex,
  NavMenuListLink,
  NavMenuList,
  LangOption,
  LanguageSelector
} from './MobileMenuStyles'

const MobileMenu = ({
  // primary,
  languages,
  activeLanguage,
  setActiveLanguage,
  active,
  open,
  user
}) => {
  const toggleLanguage = (e) => {
    setActiveLanguage(parseInt(e.target.value, 10))
  }

  return (
    <>
      <Container open={open}>
        <NavMenu open={open}>
          <NavLogin>
            <NavLoginLink to='/accountsettings'>
              {/* <Translate id='LOGIN' /> */}
              <Translate id='HELLO' /> {user?.fullName.split(' ', 2)[0]}
            </NavLoginLink>
            <LangCont>
              <LanguageSelector
                defaultValue={activeLanguage?.code || 2}
                active={active}
                name='language'
                id='langMobile'
                tag='select'
                onChange={(e) => toggleLanguage(e)}
              >
                {languages.map((language, index) => (
                  <LangOption
                    key={index}
                    value={language.code}
                    style={{
                      color: 'black'
                    }}
                  >
                    {language.abrev}
                  </LangOption>
                ))}
              </LanguageSelector>
            </LangCont>
          </NavLogin>
          {/* Verificar como fazer para fazer a validação do user employeeselfitting para mostrar o Fitting */}
          <NavMenuList>
            {MobileMenus.map((link, index) => (
              <NavMenuListItem key={index} open={open}>
                <DivFlex>
                  <NavMenuListLink to={link.link}>
                    {link.name}
                  </NavMenuListLink>
                </DivFlex>
              </NavMenuListItem>
            ))}
          </NavMenuList>
        </NavMenu>
      </Container>
    </>
  )
}

MobileMenu.propTypes = {
  languages: PropTypes.array.isRequired,
  activeLanguage: PropTypes.object,
  setActiveLanguage: PropTypes.func.isRequired,
  active: PropTypes.bool,
  open: PropTypes.bool.isRequired
}

MobileMenu.defaultProps = {
  activeLanguage: {},
  active: false
}

export default withLocalize(MobileMenu)
