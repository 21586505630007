import React, {useState, useEffect} from 'react'

import {Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import { connect } from 'react-redux'

import GetImage from '../../../shared/components/Image'
import {
  BottomPanel,
  FittingLink,
  FittingProductCardPanel,
  ImageP,
  ImagePanel,
  Name,
  Size
} from './FittingProductCardStyle'

const FittingProductCard = ({user, product, fitting}) => {
  const [needFitting, setNeedFitting] = useState(0)
  const [languageId, setLanguageId] = useState(1)

  useEffect(() => {
    if (user.allowEmployeeSelfFitting === true && product?.isUniqueSize === false && (product?.fitting === null || product?.fitting === undefined)) {
      setNeedFitting(1)
    }

    setLanguageId(user.languageId - 1)
  }, [setNeedFitting, product.fitting, user.languageId, user.allowEmployeeSelfFitting, product.isUniqueSize])

  return (
    <>
      <Col xs={12} md={8} lg={6}>
        <FittingLink
          to={
            needFitting ? `/fitting/appointment/${product?.productId}` : ''
          }
          needfitting={needFitting}
          product={product}
        >
          <FittingProductCardPanel>
            <ImagePanel>
              <ImageP
                src={GetImage(product?.product?.productImage[1])}
                alt={product?.product?.productTranslation[languageId]?.name}
              />
            </ImagePanel>
            <BottomPanel>
              <Name>{product?.product?.productTranslation[languageId]?.name}</Name>
              <Size style={{color: user.configuration?.layoutColors?.contentText}}>
                {product?.isUniqueSize === true ? (
                  <Translate id='SINGLE_SIZE' />
                ) : fitting?.fitting?.size ? (
                  <>
                    <Translate id='SIZE' /> {fitting?.fitting?.size?.number}
                  </>
                ) : (
                  <Translate id='NOT_FITTED' />
                )}
              </Size>
            </BottomPanel>
          </FittingProductCardPanel>
        </FittingLink>
      </Col>
    </>
  )
}

FittingProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  fitting: PropTypes.object
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps)(FittingProductCard)
