// Project Primary Colors ---------------------------------------- START
export const PageBackground = '#F8F8F8'

export const PrimaryColor = '#8ab7e9'
export const PrimaryButtonColor = '#0267B2'
// Light Blue

export const SecondaryColor = '#323e48'
// Heavy Blue

export const TertiaryColor = '#ffffff'
// Blue Middle

export const HighlightColor = '#004b84'

// export const PrimaryColor = '#3cb4e5'
// // Light Blue

// export const SecondaryColor = '#002e6d'
// // Heavy Blue

// export const TertiaryColor = '#323e48'
// //

export const Lightgrey = '#F8F8F8'

export const Grey = '#a6acb2'

// Project Primary Colors ---------------------------------------- END

// Project Buttons Colors ---------------------------------------- START
// Represents background button Colors
export const ButtonPrimaryColor = PrimaryColor
export const ButtonSecondaryColor = SecondaryColor
export const ButtonTertiaryColor = TertiaryColor
// Represents text button Colors
export const ButtonPrimaryTextColor = PrimaryColor
export const ButtonSecondaryTextColor = SecondaryColor
export const ButtonTertiaryTextColor = TertiaryColor
// Represents border button Colors
export const ButtonPrimaryBorderColor = PrimaryColor
export const ButtonSecondaryBorderColor = SecondaryColor
export const ButtonTertiaryBorderColor = TertiaryColor
// Project Buttons Colors ---------------------------------------- END

// Project Text Colors ------------------------------------------- START
export const PrimaryTextColor = '#1c1d21'
export const SecondaryTextColor = '#ffffff'
export const TertiaryTextColor = '#0267B2'
// Project Text Colors ------------------------------------------- END

export const TableTextColor = '#231F20'

// custom colors
export const UnselectedColor = '#BBBFC3'
export const BorderColor = '#C6C8CA'
export const SelectColor = '#8D99AE'
export const DocumentTextColor = '#1C1D21'

// action colors
export const SuccessColor = '#39B54A'
export const ErrorColor = '#ED1C00'
export const ErrorHoverColor = '#E37162'
export const WarningColor = '#fccd21'
export const OpaqueError = 'rgba(237, 28, 0, 0.3)'

// custom
export const PaymentAccepted = '#8CD73F'
export const NotSeenColor = '#DBE4EE'

export const ProgressBarColor = '#F2F2F2'

export const BarGraphicColors = ['#0063B8', '#89B7E8', '#C6C8CA']

export const PieGraphColors = [
  '#007DBD',
  '#004B84',
  '#CC2127',
  '#DB5228',
  '#F06C20',
  '#EE8A2C',
  '#EBA938',
  '#EAC744',
  '#231F20',
  '#323E48',
  '#EBC833',
  '#B1D1EA',
  '#DD511B',
  '#F26C01'
]
