import React from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {useSelector} from 'react-redux'

import version from '../../../infra/constants/version'
import {
  AlignLeft,
  AlignRight,
  FooterContainer,
  FooterCopyright,
  FooterP,
  FooterWrapper,
  SkyproLogo
} from './SmallFooterStyles'

const SmallFooter = ({className}) => {
  const backendVersion = useSelector((state) => state.info.backendVersion)
  const currentYear = new Date().getFullYear()
  return (
    <FooterWrapper className={className}>
      <FooterCopyright>
        <FooterContainer>
          <AlignLeft>
            <SkyproLogo />
          </AlignLeft>
          <AlignRight>
            <FooterP>
              <Translate id='COPYRIGHT' data={{date: currentYear}} />
              <div>{`${backendVersion} | ${version}`}</div>
            </FooterP>
          </AlignRight>
        </FooterContainer>
      </FooterCopyright>
    </FooterWrapper>
  )
}

SmallFooter.propTypes = {
  className: PropTypes.string
}

SmallFooter.defaultProps = {
  className: ''
}

export default SmallFooter
