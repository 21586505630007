import styled from 'styled-components'

import {ErrorColor, PrimaryTextColor} from '../../styles/_colors'
import {device} from '../../styles/_responsive'
import {
  Calibri
} from '../../styles/_texts'

export const MaterialCheckboxLabel = styled.div`
  padding-left: 14px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  transform: none;
  pointer-events: auto;
  font-size: 14px;
  color: ${({showError}) => (showError ? ErrorColor : PrimaryTextColor)};
  font-family: ${Calibri};
  width: 100%;
  z-index: 2;
`

export const MaterialErrorLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  top: ${({filled}) => (filled ? '0' : '10px')};
  left: 0;
  transition: all 0.2s ease;
  transition: color 0;
  color: #de8f8f;
  pointer-events: none;
  text-align: left;
`

export const MaterialIcon = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 18px;
  cursor: pointer;
`

export const MaterialLabel = styled.div`
  display: inline-block;
  position: absolute;
  font-weight: 400;
  font-family: 'Assistant';
  font-size: ${({filled}) => (filled ? '0px' : '15px')};
  top: 10px;
  text-align: center;
  transition: all 0.2s ease;
  transition: color 0;
  opacity: ${(p) => (p.filled ? 0.2 : 0.3)};
  pointer-events: none;
  color: ${({showError}) => (showError ? ErrorColor : PrimaryTextColor)};

  @media ${device.laptop} {
    font-size: ${({filled}) => (filled ? '14px' : '18px')};
  }

  @media ${device.tablet} {
    font-size: ${({filled}) => (filled ? '14px' : '16px')};
  }
`
