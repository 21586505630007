import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons'
import {Col, Row} from 'antd'
import styled from 'styled-components'

import {ReactComponent as SkyproLogo} from '../../../assets/my_skypro_portal_white.svg'
import {
  ErrorColor,
  PageBackground,
  PrimaryColor,
  PrimaryTextColor,
  SecondaryColor
} from '../../../shared/styles/_colors'
import {device} from '../../../shared/styles/_responsive'
import {
  MediumFontWeight,
  SemiBoldFontWeight
} from '../../../shared/styles/_texts'

export const LogoContainer = styled.figure`
  max-width: 130px;
  margin-bottom: 50px;
  position: absolute;
  top: 49px;
  left: inherit;
  right: 45px;
  z-index: 7;
`

export const LogoImg = styled(SkyproLogo)`
  width: 100%;
  display: block;
  transition: all 0.25s ease;
  max-width: 158px;
`

export const Aside = styled.aside`
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 360px;
  height: 63vh;
  border-bottom-right-radius: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: static;
  text-align: center;

  @media ${device.tablet} {
    top: 0;
    left: 0;
    vertical-align: initial;
  }
`

export const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  padding: 0;

  @media ${device.laptopS} {
    padding: 0 6.18%;
  }
`

export const AsideWelcome = styled.p`
  margin: 0;
  font-family: 'Campton';
  font-weight: bold;
  font-style: normal;
  font-size: 30px;
  line-height: normal;
  color: ${PageBackground};

  @media ${device.tablet} {
    font-size: 20px;
  }
`

export const AsideTitle = styled.h2`
  margin: 0;
  font-family: 'Campton';
  font-weight: bold;
  font-style: normal;
  font-size: 70px;
  line-height: normal;
  color: ${PageBackground};

  @media ${device.tablet} {
    margin: 20px 0 10px;
    font-size: 40px;
  }
`

export const AsideText = styled.p`
  margin: 0px;
  font-family: 'Assistant';
  font-weight: regular;
  font-size: 22px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 100%;
  color: ${PageBackground};
  text-align: center;

  @media ${device.tablet} {
    line-height: 30px;
    font-size: 16px;
  }
`

export const LoginAside = styled.aside`
  padding: 80px 6.18% 130px 4.88%;
  width: 100%;
  min-height: 32vh;

  @media ${device.tablet} {
    display: block;
    top: 0;
    left: 0;
    vertical-align: initial;
    min-height: 360px;
  }
`

export const LoginWrapper = styled.div`
  padding: 0;
  margin: auto;
  justify-content: space-between;

  @media ${device.laptopS} {
    flex-flow: column;
    align-items: center;
  }
`

export const LoginFormular = styled.div`
  position: relative;
  max-width: none;
  text-align: center;
  display: inline-block;
  width: 100%;
  font-size: 0;
  margin-top: 0em;
`

export const LoginLegend = styled.legend`
  font-family: 'Campton';
  font-weight: bold;
  font-style: normal;
  font-size: 17px;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  color: ${PrimaryTextColor};
  margin-bottom: 28px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  border: none;
  display: block;
`

export const LoginTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: transparent;
  border-spacing: 0;
`

export const TableRow = styled(Row)`
  font-size: 13px;
  margin-right: -15px;
  margin-left: -15px;
  &:before {
    display: table;
    content: ' ';
  }
  &:after {
    clear: both;
    display: table;
    content: ' ';
  }
`

export const TableCol = styled(Col)`
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
`

export const InputCont = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
`

export const Input = styled.input`
  border-radius: 36px;
  font-family: 'Assistant';
  font-weight: regular;
  font-size: 14px;
  width: 100%;
  padding: 20px 56px;
  border: 1px solid hsla(0, 0%, 71%, 0.47);
  max-width: 100%;
  color: ${PrimaryTextColor};
  outline: none;
  margin: 0;
  text-align: center;
  display: block;
  height: 34px;
  background-color: ${PageBackground};
  background-image: none;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
`

export const EmailIcon = styled.img`
  width: 21px;
  bottom: 13px;
  position: absolute;
  left: 25px;
  vertical-align: middle;
  border: 0;
`

export const PwdInput = styled(Input)`
  @media ${device.laptop} {
    margin: 20px 0px;
  }
`

export const LockIcon = styled.img`
  width: 16px;
  bottom: 10px;
  position: absolute;
  left: 25px;
  vertical-align: middle;
  border: 0;
`

export const EyeInvisible = styled(EyeInvisibleOutlined)`
  font-weight: ${MediumFontWeight};
`

export const EyeVisible = styled(EyeOutlined)`
  font-weight: ${MediumFontWeight};
`

export const LoginButton = styled.a`
  background-color: ${SecondaryColor};
  border: 1px solid ${SecondaryColor};
  width: 100%;
  min-width: 100px;
  display: inline-block;
  line-height: 40px;
  border-radius: 30px;
  font-family: 'Assistant';
  font-weight: ${SemiBoldFontWeight};
  text-align: center;
  text-transform: uppercase;
  padding: 0 40px;
  font-size: 13px;
  font-stretch: normal;
  color: ${PageBackground};
  letter-spacing: 0.8px;
  &:hover {
    text-decoration: underline;
    color: ${PageBackground};
  }
`

export const RowCheckbox = styled(Row)`
  font-size: 13px;
  padding-left: 20px;
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: -15px;
  margin-left: -15px;
`

export const RecoverSmall = styled.small`
  font-size: 85%;
`

export const RecoverPassword = styled.a`
  color: ${PrimaryTextColor};
  font-family: 'Assistant';
  font-weight: regular;
  font-size: 12px;
  text-decoration: none;
  padding-right: 14px;
  float: right;
  padding-top: 4px;
  &:hover {
    color: ${PrimaryTextColor};
  }
`

export const PasswordRecovery = styled.div`
  margin-top: 50px;
`

export const RecoveryTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: transparent;
  border-spacing: 0;
`

export const RecoveryRow = styled(Row)`
  font-size: 13px;
  margin-right: -15px;
  margin-left: -15px;
  &::after {
    clear: both;
  }
`

export const RecoveryFiller = styled.label`
  padding-left: 11px;
  position: absolute;
  left: 28px;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
`

export const FailureRecovery = styled.div`
  text-align: center;
`

export const FailureText = styled.p`
  font-size: 13px;
  margin-bottom: 0px;
  font-weight: normal;
  color: ${ErrorColor};
`

export const RecoveryBtn = styled.a`
  margin-top: 10px;
  min-width: auto;
  width: 100%;
  color: ${PrimaryColor};
  font-family: 'Assistant';
  font-weight: ${SemiBoldFontWeight};
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  &:hover {
    color: ${PrimaryTextColor};
  }
`

export const SectionWrapper = styled.section``

export const LoginLink = styled.a``

export const LoginDiv = styled.div``

export const LoginSpan = styled.span``

export const LoginParagraph = styled.p``

export const SignUpDiv = styled.div`
  width: 100%;

  @media ${device.laptopS} {
    margin-top: 50px;
  }
`

export const SignUpWrapper = styled.div`
  text-align: center;
`

export const SignUpLink = styled.a`
  color: ${PrimaryTextColor};
  font-family: 'Assistant';
  font-weight: regular;
  font-size: 17px;
  text-decoration: underline;

  &:hover {
    color: ${PrimaryTextColor};
  }
`

export const LoginContent = styled.div`
  font-style: normal;
  font-size: 15px;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  color: ${PrimaryTextColor};
  margin: 45px 0 40px;
  width: 100%;
  text-align: center;
  border: none;
  display: block;
`

export const LoginContentTitle = styled.h2`
  margin-bottom: 22px;
  font-size: 17px;
  font-weight: bold;
`

export const LoginContentSubtitle = styled.p``