import client from '../config/AxiosConfig'

export const GetPaymentMethodsByCountry = async (data) =>
  client.get(`/EmployeeCheckout/paymentMethods/${data}`)

export const GetAllEmployeeOrders = async () =>
  client.get('/EmployeeCheckout/orders')

export const GetAllDataSteps = async () =>
  client.get('/EmployeeCheckout/dataSteps')

export const GetEmployeeShipment = async () =>
  client.get(`/EmployeeCheckout/shipments`)

export const SaveAccountSettings = async (data) =>
  client.post(`/EmployeeCheckout/account/settings`, data)

export const DoCreditCheckout = async (data) =>
  client.post(`/EmployeeCheckout/checkout/credits`, data)

export const DoCheckout = async (data) =>
  client.post(`/EmployeeCheckout/checkout`, data)