import AccountSettings from '../../pages/accountSettings/AccountSettings'
import Addresses from '../../pages/addresses/Addresses'
import Checkout from '../../pages/checkout/Checkout'
import Credits from '../../pages/credits/Credits'
import AppPage from '../../pages/fitting/components/AppPage'
import PreApp from '../../pages/fitting/components/PreApp'
import Fitting from '../../pages/fitting/Fitting'
import ScheduleFitting from '../../pages/fitting/ScheduleFitting'
import Orders from '../../pages/orders/Orders'
import Outfit from '../../pages/outfit/Outfit'
import Product from '../../pages/product/ProductPage'
import Support from '../../pages/support/Support'
import BlankPage from '../../pages/tailor/BlankPage'
import ThankYou from '../../pages/thankyou/ThankYou'
import CreateAddressForm from '../../shared/components/forms/createAddress/CreateAddress'

export default [
  {
    path: '/blank-page',
    component: BlankPage,
    isAuthenticated: true,
    header: false,
    role: 1
  },
  {
    path: '/outfit',
    component: Outfit,
    isAuthenticated: true,
    header: true,
    role: 1
  },
  {
    path: '/credits',
    component: Credits,
    isAuthenticated: true,
    header: true,
    role: 1
  },
  {
    path: '/fitting',
    component: Fitting,
    isAuthenticated: true,
    header: true,
    role: 1
  },
  {
    path: '/scheduleFitting',
    component: ScheduleFitting,
    isAuthenticated: true,
    header: true,
    role: 1
  },
  {
    path: '/orders',
    component: Orders,
    isAuthenticated: true,
    header: true,
    role: 1
  },
  {
    path: '/addresses',
    component: Addresses,
    isAuthenticated: true,
    header: true,
    role: 1
  },
  {
    path: '/support',
    component: Support,
    isAuthenticated: true,
    header: true,
    role: 1
  },
  {
    path: '/accountsettings',
    component: AccountSettings,
    isAuthenticated: true,
    header: true,
    role: 1
  },
  {
    path: '/thankyou/:id',
    component: ThankYou,
    isAuthenticated: true,
    header: true,
    role: 1
  },
  {
    path: '/products/:id',
    component: Product,
    isAuthenticated: true,
    header: true,
    role: 1
  },
  {
    path: '/checkout',
    component: Checkout,
    isAuthenticated: true,
    header: true,
    role: 1
  },
  {
    path: '/addresses/new',
    component: CreateAddressForm,
    isAuthenticated: true,
    header: true,
    role: 1
  },
  {
    path: '/addresses/:id',
    component: CreateAddressForm,
    isAuthenticated: true,
    header: true,
    role: 1
  },
  {
    path: '/fitting/:id',
    component: AppPage,
    isAuthenticated: true,
    header: true,
    role: 1
  },
  {
    path: '/fitting/appointment/:id',
    component: PreApp,
    isAuthenticated: true,
    header: true,
    role: 1
  }
]
