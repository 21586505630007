import React, {useState} from 'react'

import {Checkbox} from 'antd'
import {PropTypes} from 'prop-types'
import {Translate} from 'react-localize-redux'

import {AuthTokenKey} from '../../../infra/config/LocalStorageKeys'
import {PutUserPrivacyPolicy} from '../../../infra/requests/UserRequests'
import {userDelete} from '../../../redux/data/user/UserActions'
import PrivacyPolicyEN from './privacyPolicyEN'
import {Modal, OrderBtn} from './PrivacyPolicyStyles'

const PrivacyPolicy = ({open, width, setPrivacyPolicyModal, dispatch}) => {
  const [isHover, setIsHover] = useState(false)
  const [isHoverReject, setIsHoverReject] = useState(false)
  const [checked, setChecked] = useState(false)
  const [validated, setValidated] = useState()

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const handleMouseEnterReject = () => {
    setIsHoverReject(true)
  }

  const handleMouseLeaveReject = () => {
    setIsHoverReject(false)
  }

  const btnStyleAccept = {
    transition: 'all 0.5s ease',
    color: isHover ? 'white' : '#8ab7e9',
    border: '1px solid #8ab7e9',
    backgroundColor: isHover ? '#8ab7e9' : 'transparent'
  }

  const btnStyleReject = {
    transition: 'all 0.5s ease',
    color: isHoverReject ? 'white' : '#a7a7a7',
    border: '1px solid #a7a7a7',
    backgroundColor: isHoverReject ? '#a7a7a7' : 'transparent'
  }

  const handleClose = () => {
    setPrivacyPolicyModal(false)
    localStorage.removeItem(AuthTokenKey)
    dispatch(userDelete())
  }

  const handleAccept = async () => {
    if (validated === true) {
      try {
        await PutUserPrivacyPolicy({privacyPolicy: true})

        setPrivacyPolicyModal(false)
      } catch (error) {
        console.log(error)
      }
    } else {
      setValidated(false)
    }
  }

  const onChange = (e) => {
    if (checked === true) {
      setChecked(false)
      setValidated(false)
    } else {
      setChecked(e.target.checked)
      setValidated(true)
    }
  }

  return (
    <Modal
      centered
      visible={open}
      onCancel={handleClose}
      maskClosable={false}
      title={<Translate id='PRIVACY_POLICY' />}
      width={width}
      closable={false}
      footer={
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginBottom: 20,
              textAlign: 'start'
            }}
          >
            <Checkbox
              checked={checked}
              onChange={onChange}
              style={{color: 'black'}}
            >
              <Translate id='PRIVACY_POLICY_CHECK' />
              {validated === false && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginLeft: 24,
                    color: 'red',
                    textAlign: 'start'
                  }}
                >
                  <div
                    style={{
                      fontSize: 16,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      textAlign: 'start'
                    }}
                  >
                    <Translate id='PRIVACY_POLICY_CHECK_UNVALID' />
                  </div>
                </div>
              )}
            </Checkbox>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <OrderBtn
              // disabled={!checked}
              onClick={handleClose}
              style={btnStyleReject}
              onMouseEnter={handleMouseEnterReject}
              onMouseLeave={handleMouseLeaveReject}
            >
              <Translate id='REJECT' />
            </OrderBtn>
            <OrderBtn
              // disabled={!checked}
              onClick={handleAccept}
              style={btnStyleAccept}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Translate id='ACCEPT' />
            </OrderBtn>
          </div>
        </div>
      }
    >
      <div
        style={{
          maxHeight: 255,
          overflow: 'auto',
          fontSize: 16,
          color: 'black'
        }}
      >
        {PrivacyPolicyEN}
      </div>
    </Modal>
  )
}
PrivacyPolicy.propTypes = {
  open: PropTypes.bool.isRequired,
  width: PropTypes.number
}

PrivacyPolicy.defaultProps = {
  width: 900
}

export default PrivacyPolicy
