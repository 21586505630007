import {createReducer} from '@reduxjs/toolkit'

import productSave from './ProductActions'

const initialState = []

const ProductReducer = createReducer(initialState, {
  [productSave]: (state, action) => action.payload
})

export default ProductReducer
