import {ShoppingOutlined} from '@ant-design/icons'
import styled from 'styled-components'

import {
  PageBackground
} from '../../styles/_colors'
import {MediaType, Assistant} from '../../styles/_texts'

export const IconCont = styled.div`
  color: ${PageBackground};
  padding-right: 0;
  line-height: 21px;
`

export const HeaderIcons = styled.a`
  color: ${PageBackground};
  position: relative;
  font-size: 1.1875rem;
  font-family: 'Campton';

  &:hover {
    color: ${PageBackground};
  }
`

export const BagItem = styled(ShoppingOutlined)`
  position: relative;
`

export const CartIndicator = styled.span`
  line-height: 15px;
  width: 15px;
  height: 15px;
  text-align: center;
  display: block;
  border-radius: 50%;
  position: absolute;
  right: -5px;
  bottom: -5px;
  pointer-events: none;
  font-size: ${MediaType};
  font-family: ${Assistant};
`
