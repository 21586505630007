import React, {useEffect, useState} from 'react'

import {Row, Tooltip} from 'antd'
import {PropTypes} from 'prop-types'
import {CirclePicker as Circle} from 'react-color'
import {Field, Form} from 'react-final-form'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect, useDispatch, useSelector} from 'react-redux'

import {CreateBagItem} from '../../../../infra/requests/BagRequests'
import {GetEmployeeFittingById} from '../../../../infra/requests/FittingRequests'
import {increaseQuantity} from '../../../../infra/utils/BagActions'
import {addItemToBag} from '../../../../redux/data/bag/BagActions'
import SizesSelect from '../../inputs/SizesSelect'
import FittingPendingMessage from './FittingPendingMessage'
import {
  AddToBagButton,
  ColorContainer,
  ColorSpan,
  ColorText,
  PriceContainer,
  RowContainer,
  ProductFormContainer,
  Price,
  ProductDetailsText,
  CreditsUsed,
  CreditsUsedText,
  CreditsSpan,
  PriceDiv,
  Divider,
  DescriptionContainer
} from './ProductFormStyles'

const ProductForm = ({
  user,
  translate,
  productDetails,
  colorObject,
  sizesObject
}) => {
  const [selectedColor, setSelectedColor] = useState('')
  const [colorId, setColorId] = useState()
  const [colorName, setColorName] = useState([])
  const [colorList, setColorList] = useState([])
  const [currentSize, setcurrentSize] = useState()
  const [isDisabled, setIsDisabled] = useState(false)
  const {bagItems} = useSelector((state) => state.bag)

  const dispatch = useDispatch()

  const languageId = user.languageId - 1

  const fittingPending =
    !user.allowOrdersOutsideFitting &&
    !user.fittingDone &&
    productDetails.product.productCombination.length !== 1

  const sizeList = Array.from(new Set(sizesObject)).sort(
    (a, b) => a.nameEu - b.nameEu
  )

  const bagItem = bagItems.find(
    (item) => item.productId === productDetails.productId
  )

  const availableCredits =
    productDetails.creditsAvailable + productDetails.extraCreditsAvailable

  const hasReachedCreditLimit =
    (bagItem && bagItem.units >= availableCredits) ||
    (productDetails.creditsAvailable === 0 &&
      productDetails.extraCreditsAvailable === 0)

  useEffect(() => {
    colorObject.map((color) => {
      if (!colorList.includes(color.html)) {
        setColorList([color.html])
        setSelectedColor([color.html])

        setColorName(
          colorObject.find(
            (x) => x?.html?.toLowerCase() === color.html?.toLowerCase()
          )?.colorTranslation
        )
      }
      return colorList
    })
    setColorId(
      colorObject.find(
        (x) => x?.html?.toLowerCase() === selectedColor.hex?.toLowerCase()
      )?.colorId
    )
  }, [selectedColor, colorList, colorObject])

  useEffect(() => {
    const handleRequest = async () => {
      try {
        const result = await GetEmployeeFittingById(
          productDetails?.productId
        )

        if (result.success) {
          setcurrentSize(
            result?.data?.fitting?.productMeasure?.size?.sizeId
          )

          if (sizesObject?.length === 1) {
            setIsDisabled(true)
          } else {
            if (
              result?.data?.fitting?.productMeasure?.size?.sizeId &&
              result?.data?.fittingDone
            ) {
              setIsDisabled(true)
            } else {
              setIsDisabled(false)
            }

            if (
              !result?.data?.fitting &&
              result?.data?.allowOrdersOutsideFitting === true
            ) {
              setIsDisabled(false)
            }
          }
        }
      } catch (error) {
        console.error(error)
      }
    }

    handleRequest()
  }, [])

  const onSubmit = async (values) => {
    const itemInBag = bagItems.find(
      (item) => item.productId === productDetails.productId
    )

    if (itemInBag) {
      increaseQuantity(itemInBag.bagItemId, dispatch, languageId)
    } else {
      const result = await GetEmployeeFittingById(
        productDetails?.productId
      )

      if (result.success) {
        const obj = {
          ProductId: productDetails?.productId,
          SizeId: parseInt(values.size, 10),
          ColorId: colorId,
          ProductWidthId:
            result?.data?.fitting?.productMeasure?.productWidth
              ?.productWidthId,
          FittingId: result?.data?.fitting?.fittingId,
          Units: 1,
          IsCredits: true,
          languageId: user.languageId
        }

        const {success, data} = await CreateBagItem(obj)

        if (success) {
          dispatch(addItemToBag(data))
        }
      }
    }
  }

  const initialSizeValue =
    sizeList.length === 1 ? sizeList[0].sizeId : currentSize

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        html: {selectedColor},
        size: initialSizeValue
      }}
      render={({handleSubmit, submitting, values}) => (
        <ProductFormContainer onSubmit={handleSubmit}>
          <RowContainer>
            <ColorContainer>
              <ColorText>
                <Translate id='COLOR' />{' '}
                <ColorSpan>
                  {colorName ? colorName[languageId]?.name : ''}
                </ColorSpan>
              </ColorText>
              <Circle
                name='html'
                color={selectedColor}
                colors={colorList}
                circleSize={16}
                width='100%'
                onChangeComplete={(colore) => setSelectedColor(colore)}
              />
            </ColorContainer>
            <Divider />
            <PriceContainer>
              <PriceDiv>
                <Price
                  style={{
                    color: user.configuration?.layoutColors?.contentText
                  }}
                >
                  {productDetails?.dotation < 2
                    ? `${productDetails?.dotation} ${translate(
                        'CART_CREDIT'
                      )}`
                    : `${productDetails?.dotation} ${translate(
                        'CART_CREDITS'
                      )}`}
                </Price>
              </PriceDiv>
            </PriceContainer>
            <CreditsUsed>
              <CreditsUsedText>
                <Translate id='USED_CREDITS' />{' '}
                <CreditsSpan
                  style={{
                    color: user.configuration?.layoutColors?.contentText
                  }}
                >
                  (
                  {productDetails?.dotation -
                    productDetails?.creditsAvailable}
                  /{productDetails?.dotation})
                </CreditsSpan>
              </CreditsUsedText>
            </CreditsUsed>
          </RowContainer>
          <RowContainer>
            {fittingPending ? (
              <FittingPendingMessage />
            ) : (
              sizeList.length > 1 && (
                <Field name='size' options={sizeList}>
                  {(props) => (
                    <SizesSelect
                      {...props}
                      options={sizeList}
                      dataKey='sizeId'
                      disabled={isDisabled}
                    />
                  )}
                </Field>
              )
            )}
          </RowContainer>
          {hasReachedCreditLimit ? (
            <Tooltip title='You have reached the order limit for this item'>
              <div>
                <AddToBagButton
                  color={user?.configuration?.layoutColors?.contentText}
                  hoverColor={
                    user?.configuration?.layoutColors?.contentButtonText
                  }
                  border={user?.configuration?.layoutColors?.contentText}
                  backgroundColor={
                    user?.configuration?.layoutColors?.contentText
                  }
                  type='submit'
                  disabled
                >
                  <Translate id='ADD_BAG' />
                </AddToBagButton>
              </div>
            </Tooltip>
          ) : (
            <AddToBagButton
              color={user?.configuration?.layoutColors?.contentText}
              hoverColor={
                user?.configuration?.layoutColors?.contentButtonText
              }
              border={user?.configuration?.layoutColors?.contentText}
              backgroundColor={
                user?.configuration?.layoutColors?.contentText
              }
              type='submit'
              disabled={
                submitting ||
                (!values.size && !currentSize) ||
                hasReachedCreditLimit ||
                fittingPending
              }
            >
              <Translate id='ADD_BAG' />
            </AddToBagButton>
          )}
          <DescriptionContainer>
            <Row
              style={{
                width: '100%',
                textAlign: 'left'
              }}
            >
              <ProductDetailsText>
                {
                  productDetails.product?.productTranslation[languageId]
                    ?.description
                }
              </ProductDetailsText>
            </Row>
          </DescriptionContainer>
          <RowContainer />
        </ProductFormContainer>
      )}
    />
  )
}

ProductForm.propTypes = {
  productDetails: PropTypes.object,
  colorObject: PropTypes.object,
  sizesObject: PropTypes.object,
  user: PropTypes.object
}

ProductForm.defaultProps = {
  productDetails: {},
  colorObject: {},
  sizesObject: {},
  user: undefined
}

const mapStateToProps = (state) => ({
  user: state.user
})

ProductForm.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(connect(mapStateToProps)(ProductForm))
