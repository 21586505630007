import React from 'react'

import {message} from 'antd'
import {PropTypes} from 'prop-types'

import {
  AlertContainer,
  CloseIcon,
  AlertTitle,
  AlertDescription,
  CloseRedIcon,
  SuccessGreenIcon
} from './BaseAlertStyles'

const BaseAlert = ({title, description, type}) => (
  <AlertContainer>
    <CloseIcon role='button' onClick={() => message.destroy()} />
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row'
      }}
    >
      {type === 'success' && <SuccessGreenIcon />}
      {type === 'error' && <CloseRedIcon />}
      <AlertTitle>{title}</AlertTitle>
    </div>
    <AlertDescription>{description}</AlertDescription>
  </AlertContainer>
)

BaseAlert.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error', 'warning']),
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.elementType
  ]).isRequired
}

BaseAlert.defaultProps = {
  title: 'Success',
  type: 'success'
}

export default BaseAlert
