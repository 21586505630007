import React from 'react'
import {PropTypes} from 'prop-types'
import {Row} from 'antd'
import {Translate, withLocalize} from 'react-localize-redux'

import {
  Container,
  Detail,
  DetailsHeader,
  DetailSpan,
  DetailTitle,
  Wrapper
} from './DetailsInfoStyles'

const DetailsInfo = ({user, activeLanguage, info}) => (
  <Container>
    <Wrapper>
      <DetailsHeader style={{color: user.configuration?.layoutColors?.contentText}}>
        <Translate id='DETAILS_HEADER' />
      </DetailsHeader>
      <Row>
        {info.map((object) => (
          <Detail
            key={object?.characteristicValueId}
            span={24}
            lg={12}
            xl={12}
            xxl={12}
          >
            <DetailTitle>
              {
                object?.characteristicValue?.characteristic?.characteristicTranslation?.find(
                  (x) => x.languageId === activeLanguage.code
                )?.name
              }
              <DetailSpan>
                {
                  object?.characteristicValue?.characteristic?.characteristicValue[0]?.characteristicValueTranslation?.find(
                    (x) => x.languageId === activeLanguage.code
                  )?.name
                }
              </DetailSpan>
            </DetailTitle>
          </Detail>
        ))}
      </Row>
    </Wrapper>
  </Container>
)

DetailsInfo.propTypes = {
  user: PropTypes.object
}

export default withLocalize(DetailsInfo)
