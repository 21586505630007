import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {Lightgrey, SecondaryColor} from '../../../shared/styles/_colors'
import {
  AddressSubTitle,
  AddressTextTitle,
  Assistant,
  Assistant500,
  AssistantBold,
  BodyText,
  Campton,
  OverlineText,
  PanelTopSubTitle
} from '../../../shared/styles/_texts'

const lineheight = () => {
  let line = AddressTextTitle.substring(0, 2)
  line = Number(line) + 2
  line = `${String(line)}px`
  return line
}

export const ImageP = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid ${Lightgrey};
`

export const ProductPanel = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  &:hover {
    ${ImageP} {
      opacity: 0.2;
      transition: opacity 0.5s ease;
    }
  }
`
export const CategoryTag = styled.div`
  font-family: ${Assistant500};
  letter-spacing: 1px;
  font-size: ${AddressSubTitle};
  text-transform: uppercase;
  min-height: 20.43px;
`
// falar com a rita/élio de como fazer isto um pouco mais dinâmico
export const NameTag = styled.div`
  font-family: ${Campton};
  font-size: ${AddressTextTitle};
  line-height: ${lineheight};
  color: ${SecondaryColor};
  height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  margin-bottom: 5px;
`
export const WorkgroupTag = styled.div`
  font-family: ${Assistant};
  font-size: ${PanelTopSubTitle};
  color: ${SecondaryColor};
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
`
export const ImagePanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: block;
  padding: 5px 0;
  position: relative;
  cursor: pointer;
  border: 1px solid #dedede;
`

export const ImageLink = styled(Link)``

export const PriceTag = styled.div`
  font-family: ${AssistantBold};
  font-size: ${BodyText};
  text-transform: uppercase;
  margin: 10px 5px 5px;
`
export const CreditsTag = styled.div`
  font-family: ${AssistantBold};
  font-size: ${OverlineText};
  margin-top: 10px;
`
export const CreditsWrapper = styled.div`
  display: inline-flex;
`

export const Separator = styled.div`
  border-bottom: 1px solid #dedede;
`
