import React, {useState} from 'react'

import ProductDetailsContainer from './components/ProductDetailsContainer'

const PageProduct = () => {
  const [active, setActive] = useState('product')
  return (
    <>
      <ProductDetailsContainer active={active} setActive={setActive} />
    </>
  )
}

export default PageProduct
