import {ArrowRightOutlined} from '@ant-design/icons'
import {Carousel} from 'antd'
import {Link} from 'react-scroll'
import styled from 'styled-components'

import {PrimaryTextColor, TertiaryColor} from '../../shared/styles/_colors'
import {device} from '../../shared/styles/_responsive'
import {
  PanelTopSubTitle,
  OverlineText,
  Assistant500,
  PanelTopTitle
} from '../../shared/styles/_texts'

export const ProductDetailSection = styled.section`
  @media ${device.laptop} {
    height: auto;
  }

  height: 830px;
  overflow: hidden;
`

export const ProductDetailWrapper = styled.div`
  @media ${device.tablet} {
    padding: 0;
  }

  font-size: 0;
  padding: 0;
  max-width: 100%;
  width: 100%auto;
  margin: 0 auto;
`

export const ProductImageAside = styled.div`
  @media ${device.laptopL} {
    width: 60%;
  }

  @media ${device.tablet} {
    width: 100%;
    min-height: 465px;
    height: auto;
  }

  height: 830px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: left;
  overflow: hidden;
  width: 66.66%;
  position: relative;
`

export const ProductCarousel = styled(Carousel)`
  & .slick-active,
  .slick-current {
    opacity: 1 !important;
  }

  & .slick-slide {
    opacity: 0.2;
  }
`

export const ImageWrapper = styled.div`
  height: 100%;
  min-height: 830px;
  position: relative;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  vertical-align: top;
  background-size: cover;
  will-change: transform;
  outline: none;

  @media ${device.tablet} {
    min-height: 465px;
  }
`

export const Image = styled.img`
  opacity: 1;
  max-width: 558px;
  position: absolute;
  transform: translateY(-50%, -50%);
  outline: none;
  transition: all 0.25s ease;
  width: 90%;
  pointer-events: none;
  vertical-align: middle;
  border-style: none;
`

export const ArrowNext = styled(ArrowRightOutlined)`
  position: absolute;
  right: 40px;
  top: 50%;

  @media ${device.tabletSS} {
    position: absolute;
    right: 30px;
    top: 50%;
  }
  & svg {
    width: 34px !important;
    height: 34px !important;
  }
`

export const ProductDetailAside = styled.div`
  @media ${device.laptopL} {
    padding: 50px 3.8%;
    width: 40%;
  }
  @media ${device.tablet} {
    position: relative;
    width: 100%;
    min-height: inherit;
    height: 400px;
    padding: 17px 15px;
  }
  @media ${device.tabletS} {
    padding: 17px 15px;
  }

  height: 830px;
  padding: 0;
  width: 33.33%;
  position: relative;
  background-color: ${TertiaryColor};
  z-index: 1;
  padding: 50px 6.45%;
  display: inline-block;
  vertical-align: top;
  overflow: visible;
  text-align: left;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
`

export const DetailsWrapper = styled.div`
  direction: inherit;
  position: absolute;
  overflow: visible;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 1;
`

export const DetailsViewport = styled.div`
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
`

export const DetailsContent = styled.div`
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: inline-block;
  padding: 16% 20%;
  height: 100%;
  width: 100%;
  visibility: visible;
  min-height: 0;

  @media ${device.desktop} {
    padding: 50px;
  }

  @media ${device.laptopL} {
    padding: 27% 7%;
  }

  @media ${device.laptop} {
    padding: 30% 7%;
  }

  @media ${device.tablet} {
    padding: 25px 20px;
  }
`

export const DetailsContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  overflow: visible;

  @media ${device.minTabletS} {
  }
  @media ${device.minTablet} {
    max-width: 720px;
  }

  @media ${device.laptopL} {
    margin-bottom: 0;
  }
`

export const DetailsCategory = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
  font-family: ${Assistant500};
  font-size: ${PanelTopSubTitle};
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 1.7px;
  margin-bottom: 10px;
  text-transform: uppercase;
`

export const DetailsTitle = styled.h1`
  position: relative;
  display: inline-block;
  width: 100%;
  font-family: 'Campton';
  font-weight: bold;
  font-style: normal;
  font-size: ${PanelTopTitle};
  color: ${PrimaryTextColor};
  margin-bottom: 5px;
  text-transform: uppercase;
  line-height: 40px;
`

export const FloatList = styled.ul`
  position: fixed;
  z-index: 2;
  top: 50%;
  width: 165px;
  opacity: 1;
  visibility: visible;
  transition: all 0.15s linear;

  @media ${device.tablet} {
    display: none;
  }
`

export const FloatListItem = styled.li`
  position: relative;
  display: inline-block;
  width: 100%;

  &:last-of-type() {
    margin: 0;
  }
`

export const FloatListItemText = styled.p``

export const FloatListItemLink = styled(Link)`
  position: relative;
  font-family: 'Assistant';
  font-size: ${OverlineText};
  font-weight: bold;
  line-height: normal;
  letter-spacing: 2px;
  color: ${PrimaryTextColor};
  text-transform: uppercase;
  margin-bottom: 10px;
  width: 100%;
  display: inline-block;
  transition: all 0.25s ease;
  content: '';
  background-color: ${PrimaryTextColor};
  width: 17px;
  height: 3px;
  display: inline-block;
  top: -3px;
  left: 60px;

  ${FloatListItemText} {
    position: absolute;
    left: 90px;
    width: auto;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease;
    color: ${PrimaryTextColor};
  }

  &.active {
    position: relative;
    content: '';
    background-color: ${PrimaryTextColor};
    width: 73px;
    height: 3px;
    display: inline-block;
    top: -3px;
    left: 4px;
    transition: all 0.25s ease;

    ${FloatListItemText} {
      opacity: 1;
      visibility: visible;
    }
  }
`
