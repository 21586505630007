import React from 'react'

import PropTypes from 'prop-types'

import {BurgerDiv, StyledBurger} from './BurgerStyled'

const Burger = ({collapsed, setCollapsed}) => (
  <StyledBurger
    collapsed={collapsed}
    onClick={() => setCollapsed(!collapsed)}
  >
    <BurgerDiv />
    <BurgerDiv />
    <BurgerDiv />
  </StyledBurger>
)

Burger.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired
}

export default Burger
