import React, {useState} from 'react'

import {Col, Row} from 'antd'
import {PropTypes} from 'prop-types'
import {Translate} from 'react-localize-redux'
import {useHistory} from 'react-router-dom'

import {
  AddressNameTag,
  AddressValuesSubPanel,
  AddressValuesTag,
  ButtonsPanel,
  CustomRow,
  DeleteButton,
  DeleteIcon,
  EditButton,
  EmployeeNameTag,
  Main
} from '../AddressesStyle'

const AddressMiddlePanel = ({user, item, totalItems, addressDelete}) => {
  const history = useHistory()
  const [isHover, setIsHover] = useState(false)

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const btnStyle = {
    height: '35px',
    width: '100px',
    borderRadius: '75px',
    cursor: 'pointer',
    transition: 'all 0.5s ease',
    color: isHover
      ? user.configuration?.layoutColors?.contentButtonText
      : user.configuration?.layoutColors?.contentText,
    border: `1px solid ${user.configuration?.layoutColors?.contentText}`,
    backgroundColor: isHover
      ? user.configuration?.layoutColors?.contentText
      : 'transparent'
  }

  const redirectAndEdit = () => {
    history.push(`/addresses/${item.contactDetailId}`)
  }

  return (
    <>
      <Row gutter={[0, 0]}>
        <Col xs={1} md={2} />
        <Col xs={22} md={20}>
          <Main>
            <CustomRow gutter={[0, 0]}>
              <Col xs={1} md={1} lg={2} />
              <Col xs={24} md={4} lg={4} style={{width: '100%'}}>
                {item.preferred === true ? (
                  <AddressNameTag>
                    <div>
                      {item.label}
                      <br />
                      <span style={{fontSize: '12px'}}>
                        <Translate id='PRIMARY_ADDRESS' />
                      </span>
                    </div>
                  </AddressNameTag>
                ) : (
                  <AddressNameTag>{item.label}</AddressNameTag>
                )}
              </Col>
              <Col xs={24} md={4} lg={4} style={{width: '100%'}}>
                <EmployeeNameTag>{item.name}</EmployeeNameTag>
              </Col>
              <Col xs={24} md={6} lg={6} style={{width: '100%'}}>
                <AddressValuesSubPanel>
                  <Row gutter={[0, 0]}>
                    <AddressValuesTag>
                      {item.addressLine1}
                    </AddressValuesTag>
                    <AddressValuesTag>
                      {item.addressLine2}
                    </AddressValuesTag>
                    <AddressValuesTag>
                      {item.addressLine3}
                    </AddressValuesTag>
                    <AddressValuesTag>
                      {item.streetNumber}
                    </AddressValuesTag>
                    <AddressValuesTag>
                      {`${item.zipCode} ${
                        item.region === item.city
                          ? item.region
                          : `${item.city} ${item.region}`
                      }, ${item.country.name}`}
                    </AddressValuesTag>
                    <AddressValuesTag>{item.mobile}</AddressValuesTag>
                  </Row>
                </AddressValuesSubPanel>
              </Col>
              <Col xs={1} md={0} lg={0} />
              <Col xs={1} md={0} lg={0} />
              <Col xs={22} md={8} lg={6}>
                <ButtonsPanel>
                  <EditButton
                    // disabled={totalItems <= 1}
                    onClick={redirectAndEdit}
                    type='primary'
                    style={btnStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    primary
                  >
                    <Translate id='EDIT' />
                  </EditButton>
                  &nbsp;&nbsp;&nbsp;
                  <DeleteButton
                    disabled={totalItems <= 1}
                    type='primary'
                    style={{
                      height: '35px',
                      width: '35px',
                      borderRadius: '35px',
                      cursor: 'pointer',
                      border: '1px solid #dedede'
                    }}
                    onClick={() => addressDelete(item.contactDetailId)}
                  >
                    {/* TODO: Change Address trash icon color */}
                    <DeleteIcon />
                  </DeleteButton>
                </ButtonsPanel>
              </Col>
              <Col xs={1} md={1} lg={2} />
            </CustomRow>
          </Main>
        </Col>
        <Col xs={1} md={2} />
      </Row>
    </>
  )
}

AddressMiddlePanel.propTypes = {
  totalItems: PropTypes.number,
  item: PropTypes.object.isRequired
}

AddressMiddlePanel.defaultProps = {
  totalItems: undefined
}

export default AddressMiddlePanel
