import client from '../config/AxiosConfig'

export const CreateBagItem = async (data) =>
  client.post('/EmployeeBag/addToBag', data)

export const FastCreateBagItem = async (data) =>
  client.post('/EmployeeBag/quickAddToBag', data)

export const GetEmployeeBag = async () => client.get('/EmployeeBag/bag')

export const DeleteBagItem = async (id) =>
  client.delete(`/EmployeeBag/bag/${id}`)

export const ClearShoppingBag = async () =>
  client.delete('/EmployeeBag/bag')

export const IncreaseBagItemQuantity = async (id, languageId) =>
  client.put(`/EmployeeBag/quantity/increase/${id}/${languageId}`)

export const DecreaseBagItemQuantity = async (id, languageId) =>
  client.put(`/EmployeeBag/quantity/decrease/${id}/${languageId}`)

export const AddAllItemsToBag = async (languageId) =>
  client.post(`/EmployeeBag/addAllToBag/${languageId}`)
