/* eslint-disable import/prefer-default-export */
import {Modal as AntdModal} from 'antd'
import styled from 'styled-components'

export const Modal = styled(AntdModal)`
  &&& {
    .ant-modal-content {
      border-radius: 8px;
      padding: 0px;
    }

    .ant-modal-body {
      min-height: 255px;
      padding: 20px;
    }

    .ant-modal-header {
      border-radius: 8px 8px 0px 0px;
      border-bottom: 0px solid #979797;
      padding: 20px;
      background: linear-gradient(90deg, #007dbd, #00a0e3) !important;
    }

    .ant-modal-title {
      color: white;
      font-size: 14pt;
    }

    .ant-modal-footer {
      background-color: #edeeed;
      padding: 20px;
      text-align: right;
      border-top: none;
      border-radius: 0px 0px 8px 8px;
    }
  }
`

export const OrderBtn = styled.button`
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({disabled}) => (disabled ? '0.4' : '1')};
  min-width: 100px;
  display: inline-block;
  line-height: 40px;
  border-radius: 30px;
  font-family: 'Assistant';
  text-transform: uppercase;
  padding: 0 40px;
  font-size: 13px;
  letter-spacing: 0.8px;
`
