import React, {useState, useEffect} from 'react'

import {CheckOutlined} from '@ant-design/icons'
import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Field, Form} from 'react-final-form'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'

import ArrowLeft from '../../../assets/arrow_left.svg'
import ArrowRight from '../../../assets/arrow_right.svg'
import {
  UpdateFittingAppointment,
  CreateFittingAppointment,
  GetEmployeeFittingById
} from '../../../infra/requests/FittingRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import CheckboxInput from '../../../shared/components/inputs/CheckboxInput'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import BaseSlider from '../../../shared/components/slider/BaseSlider'
import ActiveTranslation from '../../../shared/logic/ActiveTranslation'
import GetFittingImage from '../../../shared/logic/ImageFittingFunction'
import GetLayoutImage from '../../../shared/logic/ImageLayoutFunction'
import {
  PrimaryTextColor,
  SuccessColor
} from '../../../shared/styles/_colors'
import {Calibri} from '../../../shared/styles/_texts'
import {
  BodyH3,
  ContentContainer,
  Margin,
  PageForm,
  TitleH3
} from '../../../shared/styles/BasicStyles'
import {
  ButtonBox,
  FittedBox,
  HoverImageButton,
  ProductsBox,
  SwitchBox,
  SwitchImage,
  SaveButton
} from './Styles'

const AppPage = ({
  product,
  user,
  translate,
  employeeId,
  info,
  id,
  setProduct,
  activeLanguage,
  next,
  prev
}) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [declare, setDeclare] = useState(false)
  const [checked] = useState(false)
  const [list, setList] = useState([])
  const [layout, setLayout] = useState(false)
  const [selected, setSelected] = useState()
  const [isHover, setIsHover] = useState(false)

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const btnStyle = {
    transition: 'all 0.5s ease',
    color: isHover
      ? user.configuration?.layoutColors?.contentButtonText
      : user.configuration?.layoutColors?.contentText,
    border: `1px solid ${user.configuration?.layoutColors?.contentText}`,
    backgroundColor: isHover
      ? user.configuration?.layoutColors?.contentText
      : 'transparent'
  }

  useEffect(() => {
    const init = () => {
      const newList = product?.product?.productMeasure.sort((a, b) =>
        a.size.order > b.size.order ? 1 : -1
      )
      setList(newList)
      setLoading(false)
      setSelected(product?.productMeasureId)
    }
    init()
  }, [product])

  const handleSelection = (value) => {
    setDeclare(false)
    setSelected(value)
  }

  const handleNavigation = (id) => {
    if (id > 0) {
      history.push(`/fitting/appointment/${id}`)
    } else {
      return false
    }
  }

  const onSubmit = async () => {
    try {
      if (declare === false) {
        AlertService.error(
          translate('ERROR'),
          translate('CHECK_TERMS_SERVICE')
        )
      } else if (selected === 0) {
        AlertService.error(translate('ERROR'), translate('CHOOSE_SIZE'))
      } else {
        setLoading(true)

        if (product?.product?.productMeasure.length >= 1) {
          const fitting = await GetEmployeeFittingById(product?.productId)

          if (fitting.success) {
            if (fitting.data === '') {
              const response = await CreateFittingAppointment(selected)

              if (response.success) {
                setLoading(false)
              }
            } else {
              const result = await UpdateFittingAppointment(
                fitting.data?.fittingId,
                selected
              )

              if (result.success) {
                window.location.reload(false)
              }
            }
          }
        } else {
          const response = await CreateFittingAppointment(selected)

          if (response.success) {
            setLoading(false)
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  if (loading) {
    return <BaseLoading />
  }

  return (
    <div>
      <ContentContainer>
        <Form
          onSubmit={onSubmit}
          render={({handleSubmit, submitting, values}) => (
            <PageForm onSubmit={handleSubmit}>
              <Row style={{marginRight: '100px'}}>
                <Col
                  xs={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative'
                  }}
                >
                  {checked && (
                    <HoverImageButton
                      $top='7px'
                      $right='20px'
                      $color={SuccessColor}
                    >
                      <CheckOutlined />
                    </HoverImageButton>
                  )}
                  <img
                    style={{width: '80%'}}
                    src={
                      !layout
                        ? GetFittingImage(product?.product?.productImage)
                        : GetLayoutImage(product?.product?.productImage)
                    }
                    alt=''
                  />
                  <SwitchBox>
                    <SwitchImage
                      style={{transform: [{scaleX: 5}, {scaleY: 0.8}]}}
                      checked={layout}
                      onChange={setLayout}
                      checkedChildren={translate('LAYOUT_VIEW')}
                      unCheckedChildren={translate('IMAGE_VIEW')}
                    />
                  </SwitchBox>
                </Col>
                <Col
                  xs={12}
                  style={{
                    padding: '30px 0px 75px 0px',
                    margin: '30px 0'
                  }}
                >
                  <ProductsBox>
                    <ButtonBox
                      onClick={() =>
                        handleNavigation(product?.dotationProductPrevious)
                      }
                    >
                      <img
                        src={ArrowLeft}
                        style={{marginRight: 10}}
                        alt='left'
                      />
                      <BodyH3>
                        <Translate id='PREVIOUS' />
                      </BodyH3>
                    </ButtonBox>
                    <FittedBox>
                      <BodyH3>
                        <Translate id='PRODUCT' />{' '}
                        {product?.dotationProductNumber}{' '}
                        <Translate id='OF' />{' '}
                        {product?.dotationProductsTotal}
                      </BodyH3>
                      <BodyH3>
                        {product?.fittedProductsTotal}{' '}
                        <Translate id='FITTED' />
                      </BodyH3>
                    </FittedBox>
                    <ButtonBox
                      onClick={() =>
                        handleNavigation(product?.dotationProductNext)
                      }
                    >
                      <BodyH3>
                        <Translate id='NEXT' />
                      </BodyH3>
                      <img
                        src={ArrowRight}
                        style={{marginLeft: 10}}
                        alt='right'
                      />
                    </ButtonBox>
                  </ProductsBox>
                  <Margin size={29} />
                  <TitleH3 style={{textAlign: 'center'}}>
                    <ActiveTranslation
                      value={
                        product?.product?.productTranslation.find(
                          (x) => x.languageID === activeLanguage.code
                        )?.name || 'N/A'
                      }
                      tag='name'
                    />
                  </TitleH3>
                  <Margin size={29} />
                  <BodyH3
                    style={{
                      color: PrimaryTextColor,
                      textTransform: 'none',
                      fontSize: '17px',
                      fontFamily: Calibri,
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Translate id='CHOOSE_SIZE' />
                    <span
                      style={{
                        marginTop: '15px',
                        marginBottom: '20px',
                        fontFamily: Calibri,
                        lineHeight: 1,
                        fontSize: '13px'
                      }}
                    >
                      <Translate
                        id='INSTRUCTIONS'
                        options={{renderInnerHtml: true}}
                      />
                    </span>
                  </BodyH3>
                  <Margin size={5} />
                  <Field
                    name='slides'
                    render={(props) => (
                      <BaseSlider
                        slides={list}
                        selected={selected}
                        setSelected={handleSelection}
                      />
                    )}
                  />
                  <Margin size={79} />
                  <Margin size={29} />
                  <Row style={{display: 'flex', alignItems: 'center'}}>
                    <Col xs={18}>
                      <CheckboxInput
                        input={{value: declare, onChange: setDeclare}}
                        label={<Translate id='DECLARE_MEASURES' />}
                      />
                    </Col>
                    <Col xs={6}>
                      <SaveButton
                        auto
                        type='submit'
                        // disabled={submitting}
                        // loading={loading}
                        style={btnStyle}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => onSubmit()}
                      >
                        <Translate id='SAVE' />
                      </SaveButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </PageForm>
          )}
        />
      </ContentContainer>
    </div>
  )
}

AppPage.propTypes = {
  user: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(AppPage))
