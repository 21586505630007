import {createReducer} from '@reduxjs/toolkit'

import {setBackendVersion, updateInfo} from './InfoActions'

const initialState = {
  backendVersion: null
}

const InfoReducer = createReducer(initialState, {
  [updateInfo]: (state, action) => ({...state, ...action.payload}),
  [setBackendVersion]: (state, action) => {
    state.backendVersion = action.payload
  }
})

export default InfoReducer
