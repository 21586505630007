import React from 'react'

import PropTypes from 'prop-types'
import {Field, Form} from 'react-final-form'
import {Translate, withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router'

import EmailImage from '../../../../assets/email_input.png'
import LockImage from '../../../../assets/lock_input.png'
import FormValidator from '../../../../infra/services/validations/FormValidator'
import LoginTextInput from '../../inputs/LoginInput'
import {
  Container,
  TableBody,
  TableRow,
  TableData,
  Wrapper,
  CustomRow,
  CustomCol,
  InputContainer,
  EmailIcon,
  LockIcon,
  IconContainer,
  LoginButton,
  EyeVisible,
  EyeInvisible,
  RowCheckbox,
  CheckboxCol,
  RecoverPassword,
  RecoverSmall,
  RememberMeCol
} from './LoginFormStyles'

const validateLogin = FormValidator.make({
  email: 'required',
  password: 'required|min:6'
})

const LoginForm = ({
  translate,
  configuration,
  setDisplay,
  setClicked,
  isClicked,
  loginResponse
}) => (
  <Container>
    <TableBody>
      <TableRow>
        <TableData>
          <Form
            onSubmit={loginResponse}
            validate={validateLogin}
            render={({handleSubmit, submitting}) => (
              <form onSubmit={handleSubmit}>
                <Wrapper>
                  <CustomRow>
                    <CustomCol
                      lg={
                        configuration?.functionalities
                          ?.enabledOnboarding === true
                          ? 24
                          : 8
                      }
                      span={24}
                    >
                      <InputContainer>
                        <Field
                          component={LoginTextInput}
                          name='email'
                          textType='text'
                          placeholder={translate('USERNAME')}
                        />
                        <EmailIcon src={EmailImage} />
                      </InputContainer>
                    </CustomCol>
                    <CustomCol
                      lg={
                        configuration?.functionalities
                          ?.enabledOnboarding === true
                          ? 24
                          : 8
                      }
                      span={24}
                      style={{
                        margin:
                          configuration?.functionalities
                            ?.enabledOnboarding === true
                            ? '20px 0px'
                            : '0'
                      }}
                    >
                      <InputContainer>
                        <Field
                          component={LoginTextInput}
                          name='password'
                          textType={isClicked ? 'text' : 'password'}
                          placeholder={translate('PASSWORD_LOGIN')}
                        />
                        <LockIcon src={LockImage} />
                        <IconContainer onClick={() => setClicked()}>
                          {isClicked ? <EyeVisible /> : <EyeInvisible />}
                        </IconContainer>
                      </InputContainer>
                    </CustomCol>
                    <CustomCol
                      lg={
                        configuration?.functionalities
                          ?.enabledOnboarding === true
                          ? 24
                          : 8
                      }
                      span={24}
                    >
                      <LoginButton
                        type='submit'
                        disabled={submitting}
                        style={{
                          backgroundColor:
                            configuration?.layoutColors
                              ?.loginButtonBackground,
                          color:
                            configuration?.layoutColors?.loginButtonText
                        }}
                      >
                        <Translate id='LOG_IN' />
                      </LoginButton>
                    </CustomCol>
                  </CustomRow>
                </Wrapper>
                <Wrapper>
                  <RowCheckbox>
                    <CheckboxCol
                      lg={
                        configuration?.functionalities
                          ?.enabledOnboarding === true
                          ? 24
                          : 8
                      }
                    />
                    <RememberMeCol
                      lg={
                        configuration?.functionalities
                          ?.enabledOnboarding === true
                          ? 24
                          : 8
                      }
                    >
                      <RecoverSmall>
                        <RecoverPassword
                          id='recover_password'
                          onClick={setDisplay}
                        >
                          <Translate id='RECOVER_PASSWORD' />
                        </RecoverPassword>
                      </RecoverSmall>
                    </RememberMeCol>
                    <CheckboxCol lg={8} />
                  </RowCheckbox>
                </Wrapper>
              </form>
            )}
          />
        </TableData>
      </TableRow>
    </TableBody>
  </Container>
)

LoginForm.propTypes = {
  translate: PropTypes.func.isRequired,
  configuration: PropTypes.object.isRequired,
  setClicked: PropTypes.func.isRequired,
  setDisplay: PropTypes.func.isRequired,
  isClicked: PropTypes.bool.isRequired,
  loginResponse: PropTypes.func.isRequired
}

export default withRouter(withLocalize(LoginForm))
