import {
  FacebookFilled,
  InstagramOutlined,
  LinkedinFilled,
  TwitterOutlined
} from '@ant-design/icons'
import styled from 'styled-components'

import {ReactComponent as SkyproLogo} from '../../../assets/my_skypro_portal_white.svg'
import {
  PageBackground,
  PrimaryColor,
  PrimaryTextColor
} from '../../styles/_colors'
import {device} from '../../styles/_responsive'
import {AddressSubTitle, BodyText} from '../../styles/_texts'

export const FooterWrapper = styled.div`
  padding: 50px 50px;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;

  @media ${device.latop} {
    padding: 50px 20px;
    height: 100%;
  }
`

export const Element = styled.div`
  width: 33%;
  float: left;
  @media ${device.tabletS} {
    width: 100% !important;
  }
`

export const ElementLink = styled.a`
  text-transform: uppercase;
  color: ${PageBackground};
  font-family: 'Campton';

  &:hover {
    color: ${PrimaryColor};
  }
`

export const FacebookIcon = styled(FacebookFilled)``
export const InstagramIcon = styled(InstagramOutlined)``
export const TwitterIcon = styled(TwitterOutlined)``
export const LinkedinIcon = styled(LinkedinFilled)``

export const IconLink = styled.a`
  color: ${PageBackground};
  font-size: 16px;

  &:hover {
    color: ${PrimaryColor};
  }
`

export const ElementTextLink = styled.a`
  color: ${PageBackground};
  font-family: 'Assistant';

  &:hover {
    color: ${PrimaryColor};
  }
`

export const ElementFollow = styled.span`
  font-family: 'Campton';
  text-transform: uppercase;
`

export const ElementLi = styled.li`
  font-size: ${BodyText};
  margin-right: 150px;
`
export const ElementList = styled.ul`
  font-size: ${BodyText};
  list-style-type: none;
  line-height: 2;

  &:last-of-type {
    @media ${device.laptopSemi} {
      padding-left: 0;
      width: 100%;

      ${ElementLi} {
        margin-right: 0;
      }
    }
  }
`

export const IconList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  padding: 0;
`

export const Icon = styled.li`
  margin: 0 10px 0 0;
`

export const FooterCopyright = styled.div`
  background-color: ${PageBackground};
  color: ${PrimaryTextColor};
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 13px 40px;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`

export const LogoContainer = styled.div`
  max-width: 100px;
  width: 100%;
  height: 100%;

  @media ${device.mobileL} {
    margin-left: auto;
    margin-right: auto;
  }
`

export const SkyproImg = styled(SkyproLogo)`
  .cls-1 {
    fill: black;
  }
`

export const CopyrightText = styled.div`
  margin: 4px 0 0;
  font-family: 'Assistant';
  font-size: ${AddressSubTitle};
`

export const CopyrightContainer = styled.div`
  margin-left: auto;

  @media ${device.mobileL} {
    margin-left: auto;
    margin-right: auto;
  }
`
