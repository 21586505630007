import styled from 'styled-components'

import {
  PageBackground,
  PrimaryTextColor,
  ErrorColor
} from '../../styles/_colors'
import {FooterText} from '../../styles/_texts'

export const WarningBarCookie = styled.div`
  color: ${PageBackground};
  text-align: center;
  background-color: ${PrimaryTextColor};
  z-index: 99998;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  line-height: 2;
  padding-top: 12px;
  padding-bottom: 12px;
  user-select: none;
`

export const Wrapper = styled.div`
  font-size: ${FooterText};
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
`

export const PrivacyLink = styled.a`
  color: ${ErrorColor};

  &:hover {
    color: ${ErrorColor};
  }
`

export const CloseModal = styled.a`
  color: ${PageBackground};
  cursor: pointer;
`
