import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons'
import {Row, Col, Select} from 'antd'
import styled from 'styled-components'

import {
  ErrorColor,
  Grey,
  PageBackground,
  PrimaryColor,
  PrimaryTextColor,
  SecondaryColor
} from '../../../styles/_colors'
import {device} from '../../../styles/_responsive'
import {
  MediumFontWeight, 
  SemiBoldFontWeight,
  Assistant500,
  PanelTopSubTitle,
  BodyText
} from '../../../styles/_texts'

export const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: transparent;
  border-spacing: 0;
`

export const TableBody = styled.tbody``

export const TableRow = styled.tr``

export const TableData = styled.td``

export const Wrapper = styled.div``

export const CustomRow = styled(Row)`
  font-size: 13px;
  margin-right: -15px;
  margin-left: -15px;
  &:before {
    display: table;
    content: ' ';
  }
  &:after {
    clear: both;
    display: table;
    content: ' ';
  }
`

export const CustomCol = styled(Col)`
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  &:nth-of-type(2) {
    @media ${device.laptop} {
      margin: 20px 0px !important;
    }
  }
`

export const InputContainer = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
`

export const EmailIcon = styled.img`
  width: 21px;
  bottom: 13px;
  position: absolute;
  left: 25px;
  vertical-align: middle;
  border: 0;
`

export const LockIcon = styled.img`
  width: 16px;
  bottom: 10px;
  position: absolute;
  left: 25px;
  vertical-align: middle;
  border: 0;
`

export const IconContainer = styled.span`
  position: absolute;
  right: 20px;
  top: 13px;
  color: gray;
`

export const LoginButton = styled.button`
  border: none;
  width: 100%;
  min-width: 100px;
  display: inline-block;
  border-radius: 30px;
  font-family: 'Assistant';
  font-weight: ${SemiBoldFontWeight};
  text-align: center;
  text-transform: uppercase;
  padding: 10px 40px;
  font-size: 13px;
  font-stretch: normal;
  color: ${PageBackground};
  letter-spacing: 0.8px;
  user-select: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const EyeInvisible = styled(EyeInvisibleOutlined)`
  cursor: pointer;
  font-weight: ${MediumFontWeight};
`

export const EyeVisible = styled(EyeOutlined)`
  cursor: pointer;
  font-weight: ${MediumFontWeight};
`

export const RowCheckbox = styled(Row)`
  font-size: 13px;
  padding-left: 20px;
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: -15px;
  margin-left: -15px;
`

export const CheckboxCol = styled(Col)`
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  @media (min-width: 992px) {
    width: 33.33333333%;
    float: left;
  }
`

export const RememberMeCol = styled(Col)`
  font-size: 13px;
  padding-left: 20px;
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: -15px;
  margin-left: -15px;
  margin: auto;
`

export const RecoverSmall = styled.small`
  font-size: 85%;
`

export const RecoverPassword = styled.a`
  color: ${PrimaryTextColor};
  font-family: 'Assistant';
  font-weight: regular;
  font-size: 14px;
  text-decoration: none;
  padding-right: 14px;
  float: right;
  padding-top: 4px;
  text-decoration: underline;

  &:hover {
    color: ${PrimaryTextColor};
  }

  @media ${device.laptopS} {
    right: 0;
  }
`

export const ErrorSpan = styled.span`
  color: ${PrimaryColor};
  display: none;
  font-family: ${Assistant500};
  font-size: ${PanelTopSubTitle};
  line-height: 18px;
  position: absolute;
  bottom: -19px;
  right: 7px;
`

export const LabelForm = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;

  @media ${device.laptop} {
    justify-content: flex-start;
    padding-bottom: 8px;
    height: auto;
  }
`

export const LabelSpace = styled.div`
  @media ${device.laptop} {
    margin-bottom: -20px;
  }
`

export const SelectDefault = styled.select`
  border-radius: 36px;
  font-family: 'Assistant';
  font-size: 14px;
  width: 100%;
  padding: 0 10px;
  border: 1px solid hsla(0,0%,71%,0.47);
  max-width: 100%;
  color: #1c1d21;
  outline: none;
  margin: 0;
  display: block;
  height: 42px;
  background-color: #ffffff;
  background-image: none;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
`

export const StyledOption = styled(Select.Option)``

export const NormalSelect = styled(Select)`
  & .ant-select-selector {
    position: relative !important;
    border: none !important;
    border-bottom: ${({showError}) =>
      showError
        ? `1px solid ${ErrorColor}`
        : '1px solid hsla(240, 3%, 41%, 0.5)'} !important;
    padding: 10px 0 !important;
    width: 100% !important;
    text-align: left;
    z-index: 1;
    color: ${PrimaryTextColor};
    background-color: transparent !important;
    outline: none;
    font-size: ${BodyText};
  }
`

export const SecondarySelect = styled(NormalSelect)`
  & .ant-select-selector {
    padding: 5px 20px !important;
    width: 100% !important;
    border: 1px solid
      ${({showError}) => (showError ? 'red' : '#dedede')} !important;
    max-width: 100% !important;
    font: normal ${BodyText} 'Assistant' !important;
    line-height: 30px;
    outline: none;
    margin: 0;
    display: block;
    border-radius: 36px !important;
    height: auto !important;
    color: ${SecondaryColor}

    &::placeholder {
      color: ${Grey}
    }
  }
`