import React from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'

import Dislike from '../../../assets/Dontlike_faq_icon.svg'
import Help from '../../../assets/Extrahelp_faq_icon.svg'
import Like from '../../../assets/Like_faq_icon.svg'
import {
  MarkAsHelpful,
  MarkAsUnhelpful
} from '../../../infra/requests/FaqsRequests'
import {
  AnswersWrapper,
  AnswerText,
  BreakLine,
  HelpItem,
  HelpLink,
  HelpList,
  QuestionTitle,
  Rate,
  RateLink,
  ReviewImage,
  ReviewText,
  SupportListWrapper,
  Underline,
  RateWrapper
} from '../SupportStyle'

let a = []

const SupportList = ({
  user,
  active,
  show,
  toggleShow,
  toggleSubQuestions,
  showSub,
  faqs
}) => {
  const accountList = faqs.filter((object) => object.faqparentId === 1)
  const fittingList = faqs.filter((object) => object.faqparentId === 2)
  const ordersList = faqs.filter((object) => object.faqparentId === 4)
  const ordersSubList = faqs.filter(
    (object) =>
      object.faqparentId !== 1 &&
      object.faqparentId !== 2 &&
      object.faqid !== 1 &&
      object.faqid !== 2 &&
      object.faqid !== 3 &&
      object.faqid !== 4
  )

  const MarkFaqAsHelpful = async (id) => {
    const {data, success} = await MarkAsHelpful(id)

    if (success) {
      console.log(data)
    }
  }

  const MarkFaqAsUnhelpful = async (id) => {
    const {data, success} = await MarkAsUnhelpful(id)

    if (success) {
      console.log(data)
    }
  }

  const regex = /(<([^>]+)>)/gi

  const languageId = user.languageId - 1

  return (
    <>
      {active === 'account' && (
        <>
          <SupportListWrapper active={active}>
            <HelpList>
              {accountList.map((object) => (
                <HelpItem key={object.faqid}>
                  <HelpLink
                    onClick={() => toggleShow(object.faqid)}
                    dangerouslySetInnerHTML={{
                      __html: object.faqtranslation[languageId].title
                    }}
                  />
                </HelpItem>
              ))}
            </HelpList>
          </SupportListWrapper>
          {show !== '' && (
            <AnswersWrapper>
              <QuestionTitle
                dangerouslySetInnerHTML={{
                  __html: accountList.find(
                    (object) => object.faqid === show
                  ).faqtranslation[languageId].title
                }}
              />
              <AnswerText
                dangerouslySetInnerHTML={{
                  __html: accountList
                    .find((object) => object.faqid === show)
                    .faqtranslation[languageId].text.replace(regex, '')
                }}
              />
              <BreakLine />
              <BreakLine />

              <RateWrapper>
                <Rate>
                  <RateLink
                    onClick={() =>
                      MarkFaqAsHelpful(
                        accountList.find((object) => object.faqid === show)
                          .faqid
                      )
                    }
                  >
                    <ReviewImage src={Like} />
                    <BreakLine />
                    <ReviewText>
                      <Translate id='HELPFUL' />
                    </ReviewText>
                  </RateLink>
                </Rate>
                <Rate>
                  <RateLink
                    onClick={() =>
                      MarkFaqAsUnhelpful(
                        accountList.find((object) => object.faqid === show)
                          .faqid
                      )
                    }
                  >
                    <ReviewImage src={Dislike} />
                    <BreakLine />
                    <ReviewText>
                      <Translate id='NOT_HELPFUL' />
                    </ReviewText>
                  </RateLink>
                </Rate>
                <Rate>
                  <RateLink>
                    <ReviewImage src={Help} />
                    <BreakLine />
                    <ReviewText>
                      <Translate id='EXTRA_HELP' />
                    </ReviewText>
                  </RateLink>
                </Rate>
              </RateWrapper>
            </AnswersWrapper>
          )}
        </>
      )}
      {active === 'fitting' && (
        <>
          <SupportListWrapper active={active}>
            <HelpList>
              {fittingList.map((object) => (
                <HelpItem key={object.faqid}>
                  <HelpLink
                    onClick={() => {
                      toggleShow(object.faqid)
                    }}
                    dangerouslySetInnerHTML={{
                      __html: object.faqtranslation[languageId].title
                    }}
                  />
                </HelpItem>
              ))}
            </HelpList>
          </SupportListWrapper>
          {show !== '' && (
            <AnswersWrapper>
              <QuestionTitle
                dangerouslySetInnerHTML={{
                  __html: fittingList.find(
                    (object) => object.faqid === show
                  ).faqtranslation[languageId].title
                }}
              />
              <AnswerText
                dangerouslySetInnerHTML={{
                  __html: fittingList
                    .find((object) => object.faqid === show)
                    .faqtranslation[languageId].text.replace(regex, '')
                }}
              />
              <BreakLine />
              <BreakLine />
              <RateWrapper>
                <Rate>
                  <RateLink
                    onClick={() =>
                      MarkFaqAsHelpful(
                        fittingList.find((object) => object.faqid === show)
                          .faqid
                      )
                    }
                  >
                    <ReviewImage src={Like} />
                    <BreakLine />
                    <ReviewText>
                      <Translate id='HELPFUL' />
                    </ReviewText>
                  </RateLink>
                </Rate>
                <Rate>
                  <RateLink
                    onClick={() =>
                      MarkFaqAsUnhelpful(
                        fittingList.find((object) => object.faqid === show)
                          .faqid
                      )
                    }
                  >
                    <ReviewImage src={Dislike} />
                    <BreakLine />
                    <ReviewText>
                      <Translate id='NOT_HELPFUL' />
                    </ReviewText>
                  </RateLink>
                </Rate>
                <Rate>
                  <RateLink>
                    <ReviewImage src={Help} />
                    <BreakLine />
                    <ReviewText>
                      <Translate id='EXTRA_HELP' />
                    </ReviewText>
                  </RateLink>
                </Rate>
              </RateWrapper>
            </AnswersWrapper>
          )}
        </>
      )}
      {active === 'orders' && (
        <>
          <SupportListWrapper active={active}>
            <HelpList>
              {ordersList.map((object) => (
                <HelpItem key={object.faqid}>
                  <HelpLink
                    onClick={() => {
                      toggleSubQuestions('')
                      toggleShow(object?.faqid?.toString())
                      a = ordersSubList?.filter(
                        (x) => x?.faqparentId === object?.faqid
                      )
                    }}
                    dangerouslySetInnerHTML={{
                      __html: object?.faqtranslation[languageId]?.title
                    }}
                  />
                </HelpItem>
              ))}
            </HelpList>
          </SupportListWrapper>
          {a?.length !== 0 && (
            <>
              <SupportListWrapper active={active}>
                <HelpList>
                  {a.map((question) => (
                    <HelpItem key={question.faqid}>
                      <HelpLink
                        onClick={() => {
                          toggleShow(question.faqid)
                          toggleSubQuestions(question.faqid)
                        }}
                        dangerouslySetInnerHTML={{
                          __html: question.faqtranslation[languageId].title
                        }}
                      />
                      <Underline />
                    </HelpItem>
                  ))}
                </HelpList>
              </SupportListWrapper>
              {showSub !== '' && (
                <AnswersWrapper>
                  <QuestionTitle
                    dangerouslySetInnerHTML={{
                      __html: ordersSubList?.find(
                        (object) => object?.faqid === show
                      ).faqtranslation[languageId]?.title
                    }}
                  />
                  <AnswerText
                    dangerouslySetInnerHTML={{
                      __html: ordersSubList
                        ?.find((object) => object?.faqid === show)
                        ?.faqtranslation[languageId]?.text?.replace(
                          regex,
                          ''
                        )
                    }}
                  />
                  <BreakLine />
                  <BreakLine />
                  <RateWrapper>
                    <Rate>
                      <RateLink
                        onClick={() =>
                          MarkFaqAsHelpful(
                            ordersSubList?.find(
                              (object) => object?.faqid === show
                            )?.faqid
                          )
                        }
                      >
                        <ReviewImage src={Like} />
                        <BreakLine />
                        <ReviewText>
                          <Translate id='HELPFUL' />
                        </ReviewText>
                      </RateLink>
                    </Rate>
                    <Rate>
                      <RateLink
                        onClick={() =>
                          MarkFaqAsUnhelpful(
                            ordersSubList?.find(
                              (object) => object?.faqid === show
                            )?.faqid
                          )
                        }
                      >
                        <ReviewImage src={Dislike} />
                        <BreakLine />
                        <ReviewText>
                          <Translate id='NOT_HELPFUL' />
                        </ReviewText>
                      </RateLink>
                    </Rate>
                    <Rate>
                      <RateLink>
                        <ReviewImage src={Help} />
                        <BreakLine />
                        <ReviewText>
                          <Translate id='EXTRA_HELP' />
                        </ReviewText>
                      </RateLink>
                    </Rate>
                  </RateWrapper>
                </AnswersWrapper>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

SupportList.propTypes = {
  user: PropTypes.object,
  active: PropTypes.string.isRequired,
  show: PropTypes.string.isRequired,
  toggleShow: PropTypes.func.isRequired,
  toggleSubQuestions: PropTypes.func.isRequired,
  showSub: PropTypes.string.isRequired,
  faqs: PropTypes.array.isRequired
}

SupportList.defaultProps = {
  user: {}
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps)(SupportList)
