import {Row} from 'antd'
import styled from 'styled-components'

import {ReactComponent as Trash} from '../../assets/trash.svg'
import BaseButton from '../../shared/components/buttons/BaseButton'
import {
  Grey,
  PageBackground,
  PrimaryColor,
  SecondaryColor,
  TertiaryColor
} from '../../shared/styles/_colors'
import {device} from '../../shared/styles/_responsive'
import {
  AddressSubTitle,
  AddressTextTitle,
  Assistant,
  AssistantBold
} from '../../shared/styles/_texts'

// Sets line-height to +2px of chosen font-size
// Used in AddressValuesTag
const lineheight = () => {
  let line = AddressTextTitle.substring(0, 2)
  line = Number(line) + 2
  line = `${String(line)}px`
  return line
}

export const MainContainer = styled.div`
  background-color: ${PageBackground};
  height: 100%;
  width: 100%;
`

export const BottomPanel = styled.div`
  width: 100%;
  background-color: ${PageBackground};
  margin: 40px 0 40px;
`

export const AddressNameTag = styled.div`
  display: flex;
  justify-content: left;
  text-align: center;
  background-color: ${TertiaryColor};
  color: ${SecondaryColor};
  font-family: ${AssistantBold};
  font-size: ${AddressTextTitle};
  height: 100%;
  align-items: center;
  padding: 0 5px;
  width: 100%;

  @media ${device.tablet} {
    justify-content: center;
  }
`

export const PrefferedTag = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  background-color: ${TertiaryColor};
  color: ${SecondaryColor};
  font-family: ${AssistantBold};
  font-size: ${AddressSubTitle};
  height: 50%;

  @media ${device.tablet} {
    display: none;
  }
`
export const EmployeeNameTag = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  background-color: ${TertiaryColor};
  color: ${SecondaryColor};
  font-family: ${Assistant};
  font-size: ${AddressTextTitle};
  height: 100%;
  align-items: center;
  padding: 0 5px;

  @media ${device.tablet} {
    justify-content: center;
  }
`
export const AddressValuesSubPanel = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: ${TertiaryColor};
  padding: 25px 5px;
  height: 100%;
`
export const ButtonsPanel = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  background-color: ${TertiaryColor};
  color: ${PrimaryColor} !important;

  height: 100%;

  @media (max-width: 768px) {
    justify-content: center;
    height: 50%;
  }
`
export const NoAddressTag = styled.div`
  width: 100%;
  height: auto;
  align-items: center;
  text-align: center;
  padding: 30px 0;
  background-color: ${TertiaryColor};
`
export const Main = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  // border: 1px solid black;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${TertiaryColor};
`
export const AddressValuesTag = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  background-color: ${TertiaryColor};
  color: ${SecondaryColor};
  font-family: ${Assistant};
  font-size: ${AddressTextTitle};
  line-height: ${lineheight};

  @media ${device.tablet} {
    justify-content: center;
  }
`
export const StyledTrashLogo = styled.img`
  display: block;
  width: auto;
  height: auto;
  margin-left: auto;
  margin-right: auto;
`
export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: ${PageBackground};
`
export const WrapperNew = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  background-color: inherit;
`
export const WrapperBtn = styled.div`
  width: 100%;
  height: auto;
  margin-top: 10px;
  display: flex;
  background-color: inherit;
`

export const SideMain = styled.div`
  width: 100%;
  height: auto;
  margin-top: 75px;
  // border: 1px solid black;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${TertiaryColor};

  @media (max-width: 768px) {
    margin-top: 50px;
  }
`
export const AddressNameSideTag = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  background-color: ${TertiaryColor};
  color: ${SecondaryColor};
  font-family: ${AssistantBold};
  font-size: ${AddressTextTitle};
  align-items: flex-end;
  margin-top: 20px;
`
export const PrefferedSideTag = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  background-color: ${TertiaryColor};
  color: ${SecondaryColor};
  font-family: ${AssistantBold};
  font-size: ${AddressSubTitle};
  margin-bottom: 10px;
`
export const EmployeeNameSideTag = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  background-color: ${TertiaryColor};
  color: ${SecondaryColor};
  font-family: ${Assistant};
  font-size: ${AddressTextTitle};
  align-items: center;
  margin-bottom: 10px;
`
export const AddressValuesSideSubPanel = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  background-color: ${TertiaryColor};
  color: ${SecondaryColor};
  font-family: ${Assistant};
  font-size: ${AddressTextTitle};
  line-height: ${lineheight};
  margin-bottom: 20px;
`
export const SubContent = styled.div`
  background-color: ${TertiaryColor};
  padding-top: 75px;
  padding-bottom: 75px;
`
export const RowDiv = styled.div`
  width: 100%;
  padding: 5px 25px;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-top: 1rem;
`

export const CustomRow = styled(Row)`
  @media ${device.tablet} {
    flex-flow: column wrap;
    align-items: center;
    padding: 25px;
  }
`

export const EditButton = styled(BaseButton)`
  @media ${device.tablet} {
    position: relative;
  }

  button {
    background-color: ${PrimaryColor};
  }
`

export const DeleteIcon = styled(Trash)`
  path {
    fill: ${Grey} !important;
  }
`

export const DeleteButton = styled(BaseButton)`
  @media ${device.tablet} {
    position: relative;
  }

  &:hover {
    ${DeleteIcon} {
      path {
        fill: ${PageBackground} !important;
      }
    }
    background-color: ${PrimaryColor};
    transition: all 1s ease;
  }
`
