import styled from 'styled-components'

import {PageBackground} from '../../styles/_colors'

export const StyledBurger = styled.button`
  position: absolute;
  top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 3px;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 100%;
    max-width: 30px;
    height: 2px;
    background: ${PageBackground};
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({collapsed}) =>
        !collapsed ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({collapsed}) => (!collapsed ? '0' : '1')};
      transform: ${({collapsed}) =>
        !collapsed ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({collapsed}) =>
        !collapsed ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

export const BurgerDiv = styled.div``
