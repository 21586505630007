import React, {useEffect, useState} from 'react'

import {Col, message, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'

import FaqAccount from '../../assets/account_faq.svg'
import FaqFitting from '../../assets/fitting_faq.svg'
import CaFlag from '../../assets/flags/ca_flag.svg'
import PtFlag from '../../assets/flags/pt_flag.svg'
import UkFlag from '../../assets/flags/uk_flag.svg'
import UsFlag from '../../assets/flags/us_flag.svg'
import FaqOrders from '../../assets/orders_faq.svg'
import {GetAllFaqs} from '../../infra/requests/FaqsRequests'
import PageTopPanel from '../../shared/components/panels/PageTopPanel'
import SupportList from './components/SupportList'
import {
  BottomPanel,
  EachTab,
  TabImage,
  TabLink,
  TabsWrapper,
  TabText,
  TextSpan,
  TopLine,
  ContentLine,
  ContentWrapper,
  EachColumn,
  ImageFlag,
  StyledFlag,
  TextPhone,
  TextRegion,
  TextObs,
  LinkText
} from './SupportStyle'

const Support = ({translate, user}) => {
  const [active, setActive] = useState('')
  const [show, setShow] = useState('')
  const [showSub, setShowSub] = useState('')
  const [faqs, setFaqs] = useState([])

  useEffect(() => {
    const GetFaqList = async () => {
      const {data, success} = await GetAllFaqs()

      if (success) {
        setFaqs(data.items)
      }
    }
    GetFaqList()
  }, [])

  const toggleShow = (id) => {
    setShow(id)
  }

  const toggleSubQuestions = (id) => {
    setShowSub(id)
  }

  const success = () => {
    message.success({
      content: 'Teste',
      style: {
        display: 'block',
        position: 'absolute !important',
        top: '0'
      }
    })
  }

  return (
    <div>
      <PageTopPanel
        user={user}
        title={<Translate id='SUPPORT_TITLE' />}
        type='withoutadditional'
      />
      <BottomPanel>
        <Row gutter={[0, 0]}>
          <Col xs={2} md={1} lg={4} />
          <Col xs={20} md={22} lg={16}>
            <TopLine>
              <TextSpan>
                <Translate id='CUSTOMER_SUPPORT_PHONE' />
              </TextSpan>
            </TopLine>
            <ContentWrapper>
              <EachColumn>
                <a
                  href='tel:+351 308 814 002'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <ImageFlag>
                    <StyledFlag src={PtFlag} />
                  </ImageFlag>
                </a>
                <TextPhone>
                  <LinkText
                    href='tel:+351 308 814 002'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    (+351) 308 814 002
                  </LinkText>
                </TextPhone>
                <TextRegion>
                  <Translate id='CUSTOMER_SUPPORT_EUROPE' />
                </TextRegion>
                <TextObs>
                  <Translate id='CUSTOMER_SUPPORT_OBS_1' />
                  <br />
                  <Translate id='CUSTOMER_SUPPORT_0BS_2' />
                </TextObs>
              </EachColumn>

              <EachColumn>
                <a
                  href='tel:+1 646 844 8368'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <ImageFlag>
                    <StyledFlag src={UsFlag} />
                  </ImageFlag>
                </a>
                <TextPhone>
                  <LinkText
                    href='tel:+1 646 844 8368'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    (+1) 646-844-8368
                  </LinkText>
                </TextPhone>
                <TextRegion>
                  <Translate id='CUSTOMER_SUPPORT_USA' />
                </TextRegion>
                <TextObs>
                  <Translate id='CUSTOMER_SUPPORT_OBS_1' />
                  <br />
                  <Translate id='CUSTOMER_SUPPORT_0BS_2' />
                </TextObs>
              </EachColumn>

              <EachColumn>
                <a
                  href='tel:+44 20 4571 0080'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <ImageFlag>
                    <StyledFlag src={UkFlag} />
                  </ImageFlag>
                </a>
                <TextPhone>
                  <LinkText
                    href='tel:+44 20 4571 0080'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    (+44) 20 4571 0080
                  </LinkText>
                </TextPhone>
                <TextRegion>
                  <Translate id='CUSTOMER_SUPPORT_UNITED_KINGDOM' />
                </TextRegion>
                <TextObs>
                  <Translate id='CUSTOMER_SUPPORT_OBS_1' />
                  <br />
                  <Translate id='CUSTOMER_SUPPORT_0BS_2' />
                </TextObs>
              </EachColumn>

              <EachColumn>
                <a
                  href='tel:+1 647 694 5676'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <ImageFlag>
                    <StyledFlag src={CaFlag} />
                  </ImageFlag>
                </a>
                <TextPhone>
                  <LinkText
                    href='tel:+1 647 694 5676'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    (+1) 647-694-5676
                  </LinkText>
                </TextPhone>
                <TextRegion>
                  <Translate id='CUSTOMER_SUPPORT_CANADA' />
                </TextRegion>
                <TextObs>
                  <Translate id='CUSTOMER_SUPPORT_OBS_1' />
                  <br />
                  <Translate id='CUSTOMER_SUPPORT_0BS_2' />
                </TextObs>
              </EachColumn>
            </ContentWrapper>
          </Col>
          <Col xs={2} md={1} lg={4} />
        </Row>

        <Row gutter={[0, 0]}>
          <Col xs={2} md={1} lg={4} />
          <Col xs={20} md={22} lg={16}>
            <TopLine>
              <TextSpan>
                <Translate id='CUSTOMER_SUPPORT_EMAIL' />
              </TextSpan>
            </TopLine>
            <ContentLine>
              <a
                href={`mailto:${user.emailSupport}`}
                rel='noopener noreferrer'
              >
                <TextSpan
                  style={{
                    color: user.configuration?.layoutColors?.contentText
                  }}
                >
                  {user.emailSupport}
                </TextSpan>
              </a>
            </ContentLine>
          </Col>
          <Col xs={2} md={1} lg={4} />
        </Row>

        <Row gutter={[0, 0]}>
          <Col xs={2} md={1} lg={4} />
          <Col xs={20} md={22} lg={16}>
            <TopLine>
              <TextSpan>
                <Translate id='SELECT_QUESTION_TYPE' />
              </TextSpan>
            </TopLine>
          </Col>
          <Col xs={2} md={1} lg={4} />
        </Row>
        <Row gutter={[0, 0]}>
          <Col xs={2} md={1} lg={4} />
          <Col xs={20} md={22} lg={16}>
            <TabsWrapper>
              <EachTab active={active === 'account'}>
                <TabLink
                  onClick={() => {
                    setActive('account')
                    setShow('')
                    toggleSubQuestions('')
                  }}
                >
                  <TabImage src={FaqAccount} />
                  <TabText>
                    <Translate id='ACCOUNT' />
                  </TabText>
                </TabLink>
              </EachTab>
              <EachTab active={active === 'fitting'}>
                <TabLink
                  onClick={() => {
                    setActive('fitting')
                    setShow('')
                    toggleSubQuestions('')
                  }}
                >
                  <TabImage src={FaqFitting} />
                  <TabText>
                    <Translate id='FITTING_LOWERCASE' />
                  </TabText>
                </TabLink>
              </EachTab>
              <EachTab active={active === 'orders'}>
                <TabLink
                  onClick={() => {
                    setActive('orders')
                    setShow('')
                    toggleSubQuestions('')
                  }}
                >
                  <TabImage src={FaqOrders} />
                  <TabText>
                    <Translate id='ORDERS_TITLE' />
                  </TabText>
                </TabLink>
              </EachTab>
            </TabsWrapper>
            <SupportList
              active={active}
              show={show}
              toggleShow={toggleShow}
              toggleSubQuestions={toggleSubQuestions}
              showSub={showSub}
              faqs={faqs}
              success={success}
            />
          </Col>
          <Col xs={2} md={1} lg={4} />
        </Row>
      </BottomPanel>
    </div>
  )
}

Support.propTypes = {
  translate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

export default withLocalize(Support)
