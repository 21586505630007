import styled from 'styled-components'

import {
  PageBackground,
  SecondaryColor,
  Grey
} from '../../shared/styles/_colors'
import {device} from '../../shared/styles/_responsive'
import {
  AccountSettingsSubtitle,
  AccountSettingsTitle,
  Campton
} from '../../shared/styles/_texts'

export const Title = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${PageBackground};
  color: ${SecondaryColor};
  font-family: ${Campton};
  font-size: ${AccountSettingsTitle};
  margin-top: 8rem;
  margin-bottom: 2rem;
`

export const Subtitle = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: left;
  background-color: ${PageBackground};
  color: ${SecondaryColor};
  font-family: ${Campton};
  font-size: ${AccountSettingsSubtitle};
  margin-top: 1.5rem;

  &::placeholder {
    color: ${Grey};
  }
`

export const RowDiv = styled.div`
  width: 100%;
  padding: 5px 25px;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-top: 1rem;
`

export const WrapperBtn = styled.div`
  width: 100%;
  height: auto;
  margin-top: 10px;
  display: flex;
  background-color: inherit;
`

export const WrapperNew = styled.div`
  width: 100%;
  height: auto;
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
  background-color: inherit;
  gap: 10px;

  @media ${device.tabletS} {
    flex-direction: column;
  }
`

export const BirthDiv = styled.div`
  display: flex;
  margin-top: 1rem;

  @media ${device.tabletS} {
    flex-direction: column;
  }
`

export const BirthSelect = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100%;
  position: relative;

  :after {
    content: '^';
    transform: rotate(180deg);
    font-weight: 500;
    font-size: 1rem;
    top: 12px;
    right: 15px;
    position: absolute;
  }
`

export const BirthSelectLast = styled.div`
  width: 100%;
  position: relative;

  :after {
    content: '^';
    transform: rotate(180deg);
    font-weight: 500;
    font-size: 1rem;
    top: 12px;
    right: 15px;
    position: absolute;
  }
`
