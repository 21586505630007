export default [
    {
        id: 1,
        value: 'pt-PT'
    },
    {
        id: 2,
        value: 'en-GB'
    },
    {
        id: 3,
        value: 'fr-FR'
    },
    {
        id: 4,
        value: 'de-DE'
    },
    {
        id: 5,
        value: 'es-ES'
    },
    {
        id: 6,
        value: 'nl-BE'
    },
]