import React from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import {
  HeaderLinks,
  ListOption,
  HeaderLink,
  HeaderLang,
  LangLink,
  LangLinks
} from './MenuStyles'
import NavBarMenus from './NavBarMenus'

const Menu = ({user}) => (
  <>
    <HeaderLinks>
      {NavBarMenus.map((item, index) => (
        <ListOption key={index}>
          <HeaderLink
            to={item.link}
            exact={String(item.exact)}
            style={{color: user.configuration?.layoutColors?.navMenuText}}
          >
            {item.name}
          </HeaderLink>
        </ListOption>
      ))}
    </HeaderLinks>
    <HeaderLang>
      <LangLinks>
        <LangLink
          to='/accountsettings'
          style={{color: user.configuration?.layoutColors?.navMenuText}}
        >
          <Translate id='HELLO' /> {user?.fullName.split(' ', 2)[0]}
        </LangLink>
      </LangLinks>
    </HeaderLang>
  </>
)

Menu.propTypes = {
  user: PropTypes.object.isRequired
}

export default Menu
