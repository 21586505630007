import React, {useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router'
import {useHistory} from 'react-router-dom'

import BaseButton from '../../shared/components/buttons/BaseButton'
import {
  BalloonWrapper,
  BalloonDiv,
  BalloonSpan,
  BalloonTip,
  BalloonTipLeft,
  BalloonTipRight,
  Subtitle,
  Title,
  WrapperNew,
  OrderNumber,
  Description,
  SpaceButon
} from './ThankYouStyle'

const ThankYou = ({user, translate}) => {
  const history = useHistory()
  const {id} = useParams()

  const [logoutIsHover, setLogoutIsHover] = useState(false)
  const [saveIsHover, setSaveIsHover] = useState(false)

  const handleLogoutMouseEnter = () => {
    setLogoutIsHover(true)
  }

  const handleLogoutMouseLeave = () => {
    setLogoutIsHover(false)
  }

  const handleSaveMouseEnter = () => {
    setSaveIsHover(true)
  }

  const handleSaveMouseLeave = () => {
    setSaveIsHover(false)
  }

  const logoutBtnStyle = {
    padding: '0 40px',
    width: 'auto',
    margin: '0',
    height: 'auto',
    minHeight: '42px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    transition: 'all 0.5s ease',
    color: logoutIsHover
      ? user.configuration?.layoutColors?.contentButtonText
      : user.configuration?.layoutColors?.contentText,
    border: `1px solid ${user.configuration?.layoutColors?.contentText}`,
    backgroundColor: logoutIsHover
      ? user.configuration?.layoutColors?.contentText
      : 'transparent'
  }

  const saveBtnStyle = {
    padding: '0 40px',
    width: 'auto',
    margin: '0',
    height: 'auto',
    minHeight: '42px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    transition: 'all 0.5s ease',
    color: saveIsHover
      ? user.configuration?.layoutColors?.contentButtonText
      : user.configuration?.layoutColors?.contentText,
    border: `1px solid ${user.configuration?.layoutColors?.contentText}`,
    backgroundColor: saveIsHover
      ? user.configuration?.layoutColors?.contentText
      : 'transparent'
  }

  const GoToOutfit = () => {
    history.push('/outfit')
  }

  const GoToOrders = () => {
    history.push('/orders')
  }

  return (
    <div>
      <Row gutter={[0, 0]}>
        <Col xs={24}>
          <BalloonWrapper>
            <BalloonDiv
              style={{
                border: `15px solid ${user.configuration?.layoutColors?.contentText}`
              }}
            >
              <BalloonSpan
                style={{
                  color: user.configuration?.layoutColors?.contentText
                }}
              >
                <Translate id='THANK_YOU' />
              </BalloonSpan>
            </BalloonDiv>
            <BalloonTip>
              <BalloonTipLeft
                style={{
                  backgroundColor:
                    user.configuration?.layoutColors?.contentText
                }}
              />
              <BalloonTipRight
                style={{
                  backgroundColor:
                    user.configuration?.layoutColors?.contentText
                }}
              />
            </BalloonTip>
          </BalloonWrapper>
          <Title
            style={{color: user.configuration?.layoutColors?.contentText}}
          >
            {user.configuration?.b2bclientId === 12 ? (
              <Translate id='ORDER_REQUEST_WAS_RECEIVED' />
            ) : (
              <Translate id='ORDER_REQUEST_WAS_COMPLETED' />
            )}
          </Title>
          {user.configuration?.b2bclientId !== 12 ? (
            <>
              <Description>
                <Translate id='ORDER_COMPLETED_DETAILS_1' />
              </Description>
              <Description style={{marginBottom: '3rem'}}>
                <Translate id='ORDER_COMPLETED_DETAILS_2' />
              </Description>
            </>
          ) : null}
          <Subtitle>
            <Translate id='ORDER_NUMBER' />:
          </Subtitle>
          <OrderNumber
            style={{color: user.configuration?.layoutColors?.contentText}}
          >
            {id}
          </OrderNumber>
          {user.configuration?.b2bclientId === 12 ? (
            <>
              <Description>
                <Translate id='ORDER_RECEIVED_DETAILS_1' />
              </Description>
              <Description>
                <Translate id='ORDER_RECEIVED_DETAILS_2' />
              </Description>
              <Description>
                <Translate id='ORDER_RECEIVED_DETAILS_3' />
              </Description>
            </>
          ) : (
            <Description>
              <Translate id='ORDER_COMPLETED_DETAILS_3' />
            </Description>
          )}
          <WrapperNew>
            <BaseButton
              type='primary'
              style={logoutBtnStyle}
              onMouseEnter={handleLogoutMouseEnter}
              onMouseLeave={handleLogoutMouseLeave}
              onClick={() => GoToOutfit()}
            >
              <Translate id='CONTINUE_SHOPPING' />
            </BaseButton>
            <SpaceButon />
            <BaseButton
              type='primary'
              style={saveBtnStyle}
              onMouseEnter={handleSaveMouseEnter}
              onMouseLeave={handleSaveMouseLeave}
              onClick={() => GoToOrders()}
            >
              <Translate id='BUTTON_MY_ORDERS' />
            </BaseButton>
          </WrapperNew>
        </Col>
      </Row>
    </div>
  )
}

ThankYou.propTypes = {
  translate: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(ThankYou))
