import {Switch} from 'antd'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {
  Grey,
  PrimaryColor,
  PrimaryTextColor,
  SecondaryColor
} from '../../../shared/styles/_colors'
import {
  BodyText,
  BookFontWeight,
  SemiBoldFontWeight
} from '../../../shared/styles/_texts'

export const ProductDescription = styled.div`
  color: ${({$color}) => $color || PrimaryTextColor};
  font-weight: ${({$weight}) => $weight || BookFontWeight};
  font-size: ${BodyText};
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 22px;
  height: 22px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const ProductImage = styled.div`
  position: relative;
  padding: 5px;
  border: 2px solid
    ${({$selected}) => ($selected ? PrimaryColor : SecondaryColor)};
  width: 100%;
  padding-bottom: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-origin: center;
  background-size: contain;
  background-image: url('${({src}) => src}');
`

export const HoverImageButton = styled.div`
  position: absolute;
  top: ${({$top}) => $top || 'auto'};
  right: ${({$right}) => $right || 'auto'};
  left: ${({$left}) => $left || 'auto'};
  cursor: pointer;
  font-size: 12px;
  padding: 2px 5px;
  border: 1px solid ${({$color}) => $color};
  color: ${({$color}) => $color};
  border-radius: 50%;
`

export const AppointmentBox = styled.div`
  width: 100%;
  background-color: ${PrimaryColor};
  padding: 30px;
  display: flex;
  justify-content: space-between;
`

export const FloatButton = styled.div`
  text-align: right;
`

export const InputLabel = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${PrimaryTextColor};
  margin-left: 10px;
  font-weight: ${SemiBoldFontWeight};
`

export const ProductQuantity = styled.div`
  margin-top: 10px;

  ${InputLabel} {
    margin-left: 0;
  }
`

export const AdjustableBox = styled.div`
  border-left: 1px solid ${Grey};
  width: 30%;
  margin-left: 10px;
  padding-left: 10px;
`
export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
export const LetterBox = styled.div`
  background-color: ${PrimaryColor};
  border-radius: 100%;
  color: ${PrimaryTextColor};
  height: 27px;
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const MeasureBox = styled.div`
  padding: 10px;
  border: 1px solid ${Grey};
  border-radius: 4px;
  display: flex;
  align-items: center;
`
export const ProductsBox = styled.div`
  display: flex;
  justify-content: space-between;
`
export const SizeBox = styled.div`
  margin-left: 10px;
  width: 55%;
`
export const SwitchBox = styled.div`
  position: absolute;
  bottom: 120px;
`
export const SwitchImage = styled(Switch)`
  &&& {
    &.ant-switch {
      min-width: 200px;
      height: 50px;
    }

    &.ant-switch-checked .ant-switch-handle {
      left: calc(100% - 48px);
    }
  }

  .ant-switch-handle::before {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    top: -2px;
    left: -2px;
  }
`
export const ReferenceLink = styled(Link)`
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  .anticon {
    font-size: 14px;
    margin-right: 5px;
  }
  &:hover {
    text-decoration: underline;
  }
`

export const AppProductDescription = styled.div`
  color: ${({$color}) => $color || PrimaryTextColor};
  font-weight: ${({$weight}) => $weight || BookFontWeight};
  font-size: ${BodyText};
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 22px;
  height: 22px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const AppProductImage = styled.div`
  padding: 5px;
  border: 2px solid ${({$selected}) => ($selected ? PrimaryColor : Grey)};
  width: 100%;
  padding-bottom: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-origin: center;
  background-size: contain;
  background-image: url('${({src}) => src}');
`

export const AppHoverImageButton = styled.div`
  position: absolute;
  top: ${({$top}) => $top || 'auto'};
  right: ${({$right}) => $right || 'auto'};
  left: ${({$left}) => $left || 'auto'};
  cursor: pointer;
  font-size: 12px;
  padding: 2px 5px;
  border: 1px solid ${({$color}) => $color};
  color: ${({$color}) => $color};
  border-radius: 50%;
`

export const AppAppointmentBox = styled.div`
  width: 100%;
  background-color: ${PrimaryColor};
  padding: 30px;
  display: flex;
  justify-content: space-between;
`

export const AppFloatButton = styled.div`
  text-align: right;
`

export const FittedBox = styled.div`
  text-align: center;
  line-height: 2;
`

export const SaveButton = styled(Link)`
  background-color: transparent;
  min-width: 100px;
  display: inline-block;
  border-radius: 30px;
  font-family: Assistant, sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.8px;
  padding: 10px 40px;
`