import client from '../config/AxiosConfig'

export const GetAllProducts = async () =>
  client.get('/EmployeeProduct/outfits')

export const GetSingleProduct = async (id) =>
  client.get(`/EmployeeProduct/outfits/${id}`)

export const GetRelatedProducts = async (id) =>
  client.get(`/EmployeeProduct/outfits/related/${id}`)

export const GetOtherItems = async () =>
  client.get('/EmployeeProduct/outfits/other')
