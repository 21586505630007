import client from '../config/AxiosConfig'

export const GetAppointment = async (data) =>
  client.get(`/Fitting/appointments/${data}`)

export const ConcludeAppointment = async (id) =>
  client.put(`/Fitting/ConcludeAppointment/${id}`)

export const CreateFittingAppointment = async (productMeasureId) =>
  client.post(`/EmployeeFitting/appointments/${productMeasureId}`)

export const UpdateFittingAppointment = async (fittingId, productMeasureId) =>
  client.put(`/EmployeeFitting/appointments/${fittingId}/${productMeasureId}`)

export const GetAvailableFittings = async () =>
  client.get('/EmployeeFitting/fitting')

export const GetEmployeeFittings = async () =>
  client.get('/EmployeeFitting/appointments')

export const GetEmployeeFittingById = async (productId) =>
  client.get(`/EmployeeFitting/appointments/${productId}`)

export const GetAvailableSlots = async () =>
  client.get('/EmployeeFitting/fitting/slots')

export const GetSlotById = async (slotId) =>
  client.get(`/EmployeeFitting/fitting/slots/${slotId}`)

export const GetDefaultSlot = async () =>
  client.get('/EmployeeFitting/fitting/defaultSlot')

export const ScheduleFittingAppointment = async (data) =>
  client.post(`/EmployeeFitting/fitting/slots/${data}`)

export const GetLastFittingDate = async () =>
  client.get('/EmployeeFitting/fitting/lastFitting')
