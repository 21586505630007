import React, { useState } from 'react'

import PropTypes from 'prop-types'
import {Field, Form} from 'react-final-form'
import {Translate, withLocalize} from 'react-localize-redux'
import {withRouter} from 'react-router'

import FormValidator from '../../../../infra/services/validations/FormValidator'
import LoginTextInput from '../../inputs/LoginInput'
import {
  Container,
  TableBody,
  TableRow,
  TableData,
  Wrapper,
  CustomRow,
  CustomCol,
  InputContainer,
  LoginButton,
  LabelForm,
  LabelSpace
} from './SignUpFormStyles'

const validateForm = FormValidator.make({
  email: 'required'
})

const SignUpForm = ({
  translate,
  configuration,
  loginResponse
}) => {
  const domainOptions = [
    {code: 'belgiantrain.be', name: 'belgiantrain.be'},
    {code: 'sncb.be', name: 'sncb.be'},
    {code: 'rail.be', name: 'rail.be'},
    {code: 'nmbs.be', name: 'nmbs.be'},
    {code: 'wearskypro.com', name: 'wearskypro.com'}
  ]
  const [domainList] = useState(domainOptions)

  const fieldStyles = {width: '100%', height: '42px', padding: '0 10px', borderRadius: '30px', border: '1px solid hsla(0,0%,71%,0.47)', outline: 'none'}

  return (
    <Container>
      <TableBody>
        <TableRow>
          <TableData>
            <Form
              onSubmit={loginResponse}
              initialValues={{ clientId: configuration?.b2bclientId }}
              validate={validateForm}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                  <Wrapper>
                    <CustomRow>
                      <CustomCol lg={6} span={24}>
                        <LabelForm>
                          <LabelSpace>
                            <Translate id='EMAIL_LOGIN' />
                          </LabelSpace>
                        </LabelForm>
                      </CustomCol>
                      <CustomCol lg={7} span={24}>
                        <InputContainer>
                          <Field
                            component={LoginTextInput}
                            name='email'
                            textType='text'
                          />
                        </InputContainer>
                      </CustomCol>
                      <CustomCol lg={2} span={24}>
                        <LabelForm>@</LabelForm>
                      </CustomCol>
                      <CustomCol lg={6} span={24}>
                        <InputContainer>
                          <Field name="domain" component="select" defaultValue={domainOptions[0].code} style={fieldStyles}>
                            {domainList.map((elem, index) => (
                              <option key={index} value={elem.code}>{elem.name}</option>
                            ))}
                          </Field>
                        </InputContainer>
                      </CustomCol>
                      <CustomCol lg={3} span={24} />
                    </CustomRow>
                    <CustomRow>
                      <CustomCol lg={3} span={24} />
                      <CustomCol lg={18} span={24}>
                        <LoginButton type='submit' disabled={submitting} style={{backgroundColor: configuration?.layoutColors?.loginButtonBackground, color: configuration?.layoutColors?.loginButtonText, marginTop: '30px'}}>
                          <Translate id='SIGN_UP_LINK' />
                        </LoginButton>
                      </CustomCol>
                      <CustomCol lg={3} span={24} />
                    </CustomRow>
                  </Wrapper>
                </form>
              )}
            />
          </TableData>
        </TableRow>
      </TableBody>
    </Container>
  )
}

SignUpForm.propTypes = {
  translate: PropTypes.func.isRequired,
  configuration: PropTypes.object.isRequired,
  loginResponse: PropTypes.func.isRequired
}

export default withRouter(withLocalize(SignUpForm))
