import React from 'react'

import {ShoppingOutlined} from '@ant-design/icons'
import PropTypes from 'prop-types'

import {CartIndicator, HeaderIcons, IconCont} from './IconContainerStyles'

const IconContainer = ({configuration, setBagHidden, bagSize}) => (
  <IconCont>
    <HeaderIcons onClick={setBagHidden} style={{color: configuration?.layoutColors?.navMenuText}}>
      <ShoppingOutlined />
      {bagSize > 0 && <CartIndicator style={{backgroundColor: configuration?.layoutColors?.headerText, color: configuration?.layoutColors?.secondary}}>{bagSize}</CartIndicator>}
    </HeaderIcons>
  </IconCont>
)

IconContainer.propTypes = {
  setBagHidden: PropTypes.func.isRequired
}

export default IconContainer
