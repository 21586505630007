import Login from '../../pages/auth/login/Login'
import RecoverPage from '../../pages/auth/RecoverPage'

export default [
  {
    path: '/',
    component: Login,
    isAuthenticated: false,
    header: false
  },
  {
    path: '/recover',
    component: RecoverPage,
    isAuthenticated: false
  }
]
