import 'antd/dist/antd.css'
import React from 'react'

import {createBrowserHistory} from 'history'
import * as moment from 'moment-timezone'
import {PrimeReactProvider} from 'primereact/api'
import ReactDOM from 'react-dom'
import {LocalizeProvider} from 'react-localize-redux'
import {Provider} from 'react-redux'
import {BrowserRouter as Router} from 'react-router-dom'

import client from './infra/config/AxiosConfig'
import ReduxStore from './redux/ReduxConfig'
import AppStartup from './redux/thunks/AppStartup'
import ApplicationRoutes from './routes/ApplicationRoutes'
import {BasicStyles} from './shared/styles/BasicStyles'

moment.tz.setDefault('Etc/UTC')
const history = createBrowserHistory()

client.setupInterceptors(ReduxStore)
ReduxStore.dispatch(AppStartup(history))

ReactDOM.render(
  <Router>
    <PrimeReactProvider>
      <Provider store={ReduxStore}>
        <LocalizeProvider store={ReduxStore}>
          <BasicStyles />
          <ApplicationRoutes />
        </LocalizeProvider>
      </Provider>
    </PrimeReactProvider>
  </Router>,
  document.getElementById('root')
)
