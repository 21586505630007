import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {
  Grey,
  PageBackground,
  PrimaryColor,
  PrimaryTextColor,
  SecondaryColor,
  TertiaryColor
} from '../../shared/styles/_colors'
import {device} from '../../shared/styles/_responsive'
import {
  AccountSettingsSubtitle,
  AccountSettingsTitle,
  AddressTextTitle,
  Assistant,
  Assistant300,
  Assistant500,
  Assistant800,
  AssistantBold,
  BodyText,
  Campton,
  OverlineText
} from '../../shared/styles/_texts'

export const TopPanel = styled.div`
  background-color: ${PageBackground};
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Bar = styled.div`
  width: auto;
  background-color: ${TertiaryColor};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 12px 16px;
  flex-wrap: wrap;
  padding-left: 30px;
  padding-right: 30px;
  gap: 16px;
  min-width: 75%;

  @media ${device.laptopS} {
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
`
export const BarItem = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  @media ${device.laptopS} {
    width: 100%;
  }
`

export const Symbol = styled.div`
  border-radius: 50px;
  min-height: 40px;
  min-width: 40px;
  color: ${TertiaryColor};
  font-size: ${AccountSettingsSubtitle};
  font-family: ${Campton};
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Name = styled.div`
  font-family: ${Assistant800};
`
export const CodeID = styled.div`
  font-family: ${Assistant300};
  padding-left: 16px;
`
export const Tags = styled.div`
  color: ${Grey};
  font-size: ${OverlineText};
  font-family: ${AssistantBold};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 0px;
  text-transform: uppercase;
  padding-right: 10px;
`
export const Values = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${SecondaryColor};
  font-size: ${BodyText};
  font-family: ${Assistant};
  white-space: nowrap;
`
export const ValuesItems = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${PrimaryColor};
  font-size: ${BodyText};
  font-family: ${AssistantBold};
`
export const BottomPanel = styled.div`
  width: 100%;
  background-color: ${TertiaryColor};
  padding-bottom: 80px;
`
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const WrapperSymbol = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const WrapperEmployee = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding-left: 16px;
`
export const WrapperBtn = styled.div`
  width: auto;
  height: 100%;
  // padding-right: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`

export const ScheduleBtn = styled(Link)`
  background-color: transparent;
  width: auto;
  display: inline-block;
  border-radius: 30px;
  font-family: Assistant, sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.8px;
  padding: 10px 20px;
`

export const ScheduleContainer = styled.section`
  width: 100%;
  position: relative;
  background-color: ${PageBackground};
  padding-bottom: 40px;
  padding-top: 40px;
  display: block;
`

export const ScheduleWrapper = styled.div`
  text-align: center;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
`

export const EachDayDate = styled.p`
  display: block;
  width: 100%;
  font-family: ${AssistantBold};
  font-size: ${AddressTextTitle};
  color: ${PrimaryColor};
  text-align: center;
  line-height: 18px;
  margin: 0;
  padding: 0;
`

export const EachDayOpenings = styled.span`
  display: block;
  width: 100%;
  font-family: Assistant, sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  color: #b4b4b4;
`

export const SliderWrapper = styled.div`
  max-width: 900px;
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0 62px;
  height: 50px;
  border: 1px solid hsla(0, 0%, 71%, 0.47);
  border-radius: 25px;
  margin-bottom: 37px;
  background-color: #fff;

  .slick-slider {
    position: relative !important;
    max-width: 900px !important;
    width: 100% !important;
    background-color: #fff !important;
    transition: 0.4s !important;
    height: 48px !important;
    text-align: center !important;
    font-size: 0 !important;
    .slick-prev {
      left: -45px !important;
      font-size: 20px !important;
      line-height: 0 !important;
      position: absolute !important;
      display: block !important;
      width: 20px !important;
      height: 20px !important;
      padding: 0 !important;
      cursor: pointer !important;
      color: black !important;
      border: none !important;
      outline: none !important;
      background: transparent !important;
      min-width: inherit !important;
      top: 14px !important;
      transform: rotate(180deg) !important;
    }
    .slick-list {
      margin: 0 !important;
      padding: 0 !important;
      border: 0 !important;
      overflow: hidden !important;
      .slick-track {
        .slick-slide {
          width: 155px !important;
          height: 48px !important;
          background-color: transparent !important;
          padding: 7px 8px !important;
          border-radius: 25px !important;
          position: relative !important;
          display: inline-block !important;
          cursor: pointer !important;
          outline: none !important;
          transition: all 0.25s ease !important;
          border-left: 1px solid #fff !important;
          border-right: 1px solid #fff !important;
        }
        .slick-current {
          border-radius: 30px !important;
          background-color: ${PrimaryColor} !important;

          ${EachDayDate}, ${EachDayOpenings} {
            color: ${PageBackground} !important;
          }
        }
      }
    }
    .slick-next {
      right: -45px !important;
      font-size: 20px !important;
      line-height: 0 !important;
      position: absolute !important;
      display: block !important;
      width: 20px !important;
      height: 20px !important;
      padding: 0 !important;
      cursor: pointer !important;
      color: black !important;
      border: none !important;
      outline: none !important;
      background: transparent !important;
      min-width: inherit !important;
      top: 14px !important;
    }
  }
`

export const SliderDiv = styled(TopPanel)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  padding: 0;
`

export const FittingCenter = styled.ul`
  max-width: 900px;
  width: 100%;
  margin: 0 auto 50px;
  padding: 0;
`

export const FittingCenterWrapper = styled.li`
  margin-top: 0;
  max-width: 900px;
  width: 100%;
  padding: 25px 40px;
  display: inline-block;
  text-align: left;
  background-color: #fff;
  font-size: 0;
`

export const FittingHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  @media ${device.tabletS} {
    flex-direction: column;
  }
`

export const EachCenterDiv = styled.div`
  width: auto;
  font-size: 15px;
  vertical-align: middle;
  color: ${PrimaryTextColor};
  font-family: ${Assistant};
  line-height: 1.33;

  &:first-of-type {
    font-family: ${AssistantBold};
  }

  @media ${device.tabletS} {
    margin-bottom: 8px;
  }
`

export const HourWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: auto;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  vertical-align: middle;
  color: ${PrimaryTextColor};
  font-family: ${Assistant};
  line-height: 1.33;
`

export const SelectButton = styled.button`
  min-width: 100px;
  display: inline-block;
  line-height: 40px;
  border-radius: 30px;
  font-family: ${Assistant500};
  text-align: center;
  text-transform: uppercase;
  padding: 0 40px;
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.8px;
  border: 1px solid ${PrimaryColor};
  margin-top: 0;
  color: ${PrimaryColor};
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: ${PrimaryColor};
    color: ${TertiaryColor};
  }
`

export const FittingSelectionWrapper = styled.div`
  text-align: center;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
`

export const TimeSlotDiv = styled.div`
  cursor: pointer;
  padding: 20px;
  font-size: 13px;
  line-height: 1.54;
  text-align: center;
  background-color: ${({$selected}) => ($selected ? PrimaryColor : null)};
  color: ${({$selected}) => ($selected ? PageBackground : Grey)};
  font-family: ${Assistant500};

  width: auto;
`

export const DefaultSlotResume = styled.div`
  background: ${PageBackground};
  padding: 40px 0;
  min-height: 100%;
  height: 100%;
`

export const DefaultSlotWrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;
`

export const DefaultSlotResumeBox = styled.div`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  background: ${PageBackground};
`

export const DefaultSlotHeader = styled.div`
  padding: 25px 50px;
  background: ${PrimaryColor};
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media ${device.mobileL} {
    justify-content: center;
    padding: 15px 25px;
    gap: 10px;
  }
`

export const DefaultSlotHeaderSpan = styled.span`
  display: block;
  font-family: ${AssistantBold};
  color: ${PageBackground};
  text-transform: uppercase;
  font-size: ${OverlineText};
`

export const DefaultSlotId = styled(DefaultSlotHeaderSpan)`
  font-size: ${AccountSettingsTitle};
  line-height: 1.17;
`

export const RescheduleBtn = styled(ScheduleBtn)`
  border: 1px solid ${PageBackground};
  color: ${PageBackground};
`

export const DefaultSlotEmployeeRow = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  background: ${PageBackground};
  position: relative;
  padding: 30px 0px;
  font-family: ${Assistant};
  font-size: ${AddressTextTitle};
  color: ${PrimaryTextColor};
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  @media ${device.tabletS} {
    flex-direction: column;
  }
`

export const EmployeeInfo = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const InfoEmphasized = styled.span`
  font-family: ${AssistantBold};
  font-size: ${OverlineText};
  text-align: center;
  color: ${Grey};
  text-transform: uppercase;
  margin-right: 14px;
`

export const InfoText = styled.span`
  color: ${PrimaryColor};
  font-family: ${AssistantBold};
`

export const DefaultSlotDetail = styled.div`
  border-top: 1px solid ${PageBackground};
  padding: 0 0px;
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @media ${device.tabletS} {
    justify-content: flex-start;
    flex-direction: row;
    gap: 20px;
  }
`

export const DefaultSlotDetailAside = styled.aside`
  display: inline-block;
  font-size: 0;
  vertical-align: top;

  /* &:first-of-type {
    max-width: calc(50% - 1px);
    border-right: 1px solid ${Grey};
  }

  &:last-of-type {
    padding-left: 40px;
  } */
`

export const DefaultSlotFittingCenterList = styled.ul`
  list-style: none;
  padding: 0;
`

export const DefaultSlotFittingCenterElement = styled.li`
  max-width: 80%;
  font-family: ${Assistant300};
  line-height: 1.33;
  font-size: ${AddressTextTitle};
  color: ${PrimaryTextColor};
  margin-bottom: 10px;

  &:first-of-type {
    font-family: ${AssistantBold};
    line-height: 2;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const NextFittingTitle = styled.p`
  font-family: ${AssistantBold};
  font-size: ${AddressTextTitle};
  line-height: 2;
  color: ${PrimaryTextColor};
  margin-bottom: 20px;
`

export const NextFittingDateWrapper = styled.div`
  max-width: 50%;
  width: 100%;
  display: inline-block;
  vertical-align: top;
`

export const NextFittingDateTitle = styled.span`
  font-family: ${AssistantBold};
  font-size: ${OverlineText};
  color: ${Grey};
  display: block;
  text-transform: uppercase;
`

export const NextFittingDate = styled.p`
  font-family: ${AssistantBold};
  font-size: ${AccountSettingsTitle};
  line-height: 1.17;
  color: ${PrimaryColor};
`

export const NextFittingHour = styled(NextFittingDateWrapper)`
  padding-left: 25%;

  @media ${device.tabletS} {
    padding-left: 10%;
  }
`

export const WrapperSchedule = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`
