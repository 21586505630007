import React from 'react'
import PropTypes from 'prop-types'
import {ArrowNext} from '../ProductPageStyle'

const SliderArrow = ({style, onClick}) => (
  <ArrowNext
    onClick={onClick}
    style={{
      ...style
    }}
  />
)

SliderArrow.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func
}

SliderArrow.defaultProps = {
  style: {},
  onClick: () => {}
}

export default SliderArrow
