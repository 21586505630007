import React from 'react'

import PropTypes from 'prop-types'

import {ButtonText} from '../../styles/_texts'
import {StyledButton} from './ButtonsStyles'

const BaseButton = ({
  onClick,
  disabled,
  style,
  onMouseEnter,
  onMouseLeave,
  children,
  type,
  htmlType,
  primary
}) => {
  const onClickWrapper = (event) => {
    if (disabled) {
      return null
    }
    return onClick(event)
  }

  const onMouseEnterWrapper = (event) => {
    return onMouseEnter(event)
  }

  const onMouseLeaveWrapper = (event) => {
    return onMouseLeave(event)
  }

  return (
    <StyledButton
      onClick={onClickWrapper}
      style={style}
      onMouseEnter={onMouseEnterWrapper}
      onMouseLeave={onMouseLeaveWrapper}
      fontsize={ButtonText}
      disabled={disabled}
      type={type}
      htmlType={htmlType}
      primary={primary}
    >
      {children}
    </StyledButton>
  )
}

BaseButton.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  children: PropTypes.object,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  htmlType: PropTypes.string
}

BaseButton.defaultProps = {
  onClick: () => null,
  style: undefined,
  onMouseEnter: () => null,
  onMouseLeave: () => null,
  children: undefined,
  disabled: undefined,
  type: undefined,
  htmlType: 'button'
}

export default BaseButton
