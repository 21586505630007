import {Button, Col} from 'antd'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {ReactComponent as BagLogo} from '../../../assets/product_card_bag.svg'
import {
  PageBackground,
  PrimaryColor,
  SecondaryColor,
  TertiaryColor
} from '../../../shared/styles/_colors'
import {device} from '../../../shared/styles/_responsive'
import {
  AccountSettingsTitle,
  AddressSubTitle,
  AddressTextTitle,
  Assistant500,
  AssistantBold,
  BodyText,
  Campton,
  OverlineText,
  PanelTopSubTitle
} from '../../../shared/styles/_texts'

export const CreditsProductCardPanel = styled.div``

export const ImageLink = styled(Link)``

export const TopPanel = styled.div`
  width: 100%;
  border: 1px solid #dedede;
  margin: 10px 0;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  display: flex;
  min-height: 114px;
  flex-direction: column;
  justify-content: center;
`
export const Category = styled.div`
  text-transform: uppercase;
  font-family: ${Assistant500};
  font-size: ${AddressSubTitle};
  padding-left: 10%;
  padding-bottom: 5px;
`

export const ProductName = styled.div`
  text-transform: uppercase;
  font-family: ${Campton};
  font-size: ${AddressTextTitle};
  color: ${SecondaryColor};
  padding-left: 10%;
  line-height: 16px;
  padding-bottom: 4px;
  top: 29%;
  max-width: 74%;
`

export const Workgroup = styled.div`
  font-family: ${Assistant500};
  font-size: ${PanelTopSubTitle};
  color: ${SecondaryColor};
  padding-left: 10%;
`
export const BottomPanel = styled.div`
  width: 100%;
  border: 1px solid #dedede;
`
export const TagTop = styled.div`
  width: 100%;
  display: block;
  font-family: ${Campton};
  font-size: ${PanelTopSubTitle};
  text-align: center;
  padding-top: 4px;
`
export const TagBot = styled.div`
  width: 100%;
  font-family: ${AssistantBold};
  font-size: ${BodyText};
  color: ${SecondaryColor};
  display: inline-flex;
  vertical-align: bottom;
  justify-content: center;
  text-align: center;
  padding-bottom: 4px;
`
export const SubTagBot = styled.div`
  font-family: ${AssistantBold};
  font-size: ${OverlineText};
  color: ${SecondaryColor};
  justify-content: center;
  text-align: center;
  display: inline-flex;
  padding-top: 4px;
`
export const DurationTag = styled.div`
  border-top: 1px solid #dedede;
  width: 100%;
  height: 100%;
  padding-top: 8px;
  font-family: ${Campton};
  font-size: ${OverlineText};
  text-align: center;
  justify-content: center;
  padding-bottom: 4px;
`
export const DurationValue = styled.div`
  border-top: 1px solid #dedede;
  width: 100%;
  text-align: center;
  justify-content: center;
  font-family: ${AssistantBold};
  font-size: ${BodyText};
  color: ${SecondaryColor};
  padding-top: 4px;
  padding-bottom: 4px;
`
export const Wrapper = styled.div`
  width: 100%;
  border-right: 1px solid #dedede;
`
export const ImageP = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #dedede;
  cursor: pointer;
`
export const MiddlePanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: block;
  position: relative;
  background-color: ${PageBackground};
  margin: 10px 0;
`
export const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${TertiaryColor};
`

export const TopBarPanel = styled.div`
  width: 100%;
  background-color: ${TertiaryColor};
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  margin-top: 30px;
  margin-bottom: 30px;
`
export const StatusTag = styled.div`
  width: 100%;
  background-color: ${TertiaryColor};
  padding-right: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: ${Campton};
  font-size: ${OverlineText};
  color: ${SecondaryColor};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  @media (max-width: 1200px) {
    margin-bottom: 0px;
  }
`
export const ValueTag = styled.div`
  width: 100%;
  background-color: ${TertiaryColor};
  border-left: 1px solid #dedede;
  margin-top: 30px;
  padding-left: 10px;
  margin-bottom: 30px;
  font-family: ${Campton};
  font-size: ${AccountSettingsTitle};
  color: ${PrimaryColor};
  text-align: flex-start;
  display: flex;
  justify-content: flex-start;
  text-transform: uppercase;

  @media (max-width: 1200px) {
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

export const WrapperBtn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const WrapperBtnMain = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 1200px) {
    margin-bottom: 80px;
  }

  @media (max-width: 425px) {
    margin-bottom: 40px;
  }
`

export const CustomCol = styled(Col)`
  @media ${device.mobileL} {
    margin-bottom: 30px;
  }
`

export const OrderBtn = styled(Button)`
  color: ${PrimaryColor};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  padding: 8.5px 0px;
  border-radius: 30px;
  font-family: ${Assistant500};
  text-align: center;
  text-transform: uppercase;
  font-size: ${BodyText};
  letter-spacing: 0.8px;
  border: 1.5px solid ${PrimaryColor};
  cursor: pointer;
  position: absolute;
  min-width: 100px;
  width: 100%;

  &:hover {
    background-color: ${PrimaryColor};
    color: ${PageBackground};
  }

  @media ${device.mobileL} {
    padding-left: 20px;
    padding-right: 20px;
    top: 10px;
  }
`
