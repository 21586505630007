import {createAction} from '@reduxjs/toolkit'

export const bagSave = createAction('bag/save', (data) => ({
  payload: data
}))
export const bagEdit = createAction('bag/edit')
export const bagDelete = createAction('bag/delete')
export const bagUpdateTotal = createAction('bag/updateTotal')

export const addItemToBag = (item) => (dispatch, getState) => {
  const currentItems = getState().bag.bagItems
  const updatedItems = [...currentItems, item]
  dispatch(bagSave(updatedItems))
  dispatch(bagUpdateTotal())
}

export const addAllToBag = (bagItems) => (dispatch) => {
  dispatch(bagSave(bagItems))
}

export const removeItemFromBag = (itemId) => (dispatch, getState) => {
  const currentItems = getState().bag.bagItems
  const updatedItems = currentItems.filter(
    (item) => item.bagItemId !== itemId
  )

  dispatch(bagSave(updatedItems))
  dispatch(bagUpdateTotal())
}

const updateItemQuantity = (itemId, change) => (dispatch, getState) => {
  const currentItems = getState().bag.bagItems
  const item = currentItems.find((itm) => itm.bagItemId === itemId)

  if (!item) {
    console.error(`Item with id ${itemId} not found in bag`)
    return
  }

  const newQuantity = item.units + change

  if (newQuantity <= 0) {
    return dispatch(removeItemFromBag(itemId))
  }

  const updatedItem = {
    ...item,
    units: newQuantity
  }

  const updatedItems = currentItems.map((itm) =>
    itm.bagItemId === updatedItem.bagItemId ? updatedItem : itm
  )

  dispatch(bagSave(updatedItems))
  dispatch(bagUpdateTotal())
}

export const addItemQuantity = (itemId) => updateItemQuantity(itemId, 1)
export const removeItemQuantity = (itemId) =>
  updateItemQuantity(itemId, -1)
