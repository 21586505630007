import React from 'react'
import {Translate} from 'react-localize-redux'

export default [
  {
    name: <Translate id='OUTFIT' />,
    link: '/outfit',
    permissions: 1,
    exact: true
  },
  {
    name: <Translate id='CREDITS' />,
    link: '/credits',
    permissions: 1,
    exact: false
  },
  {
    name: <Translate id='FITTING' />,
    link: '/fitting',
    permissions: 1,
    exact: false
  },
  {
    name: <Translate id='MY_ORDERS' />,
    link: '/orders',
    permissions: 1,
    exact: false
  },
  {
    name: <Translate id='ADDRESSES' />,
    link: '/addresses',
    permissions: 1,
    exact: false
  },
  {
    name: <Translate id='SUPPORT' />,
    link: '/support',
    permissions: 1,
    exact: false
  }
]
