import client from '../config/AxiosConfig'

export const GetAllFaqs = async () => client.get('/EmployeeFaq/faqs')

export const UpdateHelpfulOrUnhelpful = async (id, data) =>
  client.put(`/EmployeeFaq/faqs/${id}`, data)

export const MarkAsHelpful = async (id) =>
  client.put(`/EmployeeFaq/faqs/helpful/${id}`)

export const MarkAsUnhelpful = async (id) =>
  client.put(`/EmployeeFaq/faqs/unhelpful/${id}`)
