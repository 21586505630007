import React from 'react'

import {Translate} from 'react-localize-redux'
import {useDispatch} from 'react-redux'

import {AuthTokenKey} from '../../infra/config/LocalStorageKeys'
import {userDelete} from '../../redux/data/user/UserActions'
import {BlankPageContainer, StyledLogoutButton} from './BlankPageStyles'

const BlankPage = () => {
  const dispatch = useDispatch()

  const logout = () => {
    localStorage.removeItem(AuthTokenKey)
    dispatch(userDelete())
  }

  return (
    <BlankPageContainer>
      <StyledLogoutButton onClick={logout} type='primary'>
        <Translate id='LOGOUT' />
      </StyledLogoutButton>
    </BlankPageContainer>
  )
}

export default BlankPage
